import React, { useState } from "react";
import "./Footer.scss";

import { Twitter, Facebook, YouTube } from "@material-ui/icons";
import { useTranslation } from 'react-i18next';

import Ticket from '../Ticket/Ticket'

const Footer = () => {
  const { t } = useTranslation();
  const copyYear = new Date().getFullYear()

  const [open, setOpen] = useState(false);

  return (
    <div className="h-1">

      <footer className="text-sm text-white px-4 py-2 md:px-8 bg-black w-full">
        <div className="grid md:grid-cols-2 gap-2 mb-2 py-5">
          <div className="md:w-4/5">
            <h3 className="mb-5 text-lg font-normal">{t('footer.items.about')}</h3>
            <p className="leading-normal text-gray-400">
              {t('home.description')}
            </p>
          </div>

          <div className="flex space-x-2">
            <div className="md:w-1/4">
              <h3 className="mb-5 text-sm md:text-lg font-normal">{t('footer.items.quick')}</h3>
              <ul className="text-sm text-gray-400">
                <li>
                  <a
                    className=" hover:text-gray-400"
                    href="https://www.bnr.rw"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="bnr"
                  >
                    BNR website
                  </a>
                </li>

                <li>
                  <a
                    className="hover:text-gray-400"
                    href="https://www.bnr.rw/footer/quick-links/citizen-charter/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="charter"
                  >
                    Citizen charter
                  </a>
                </li>

                <li>
                  <a
                    className="hover:text-gray-400"
                    href="https://www.youtube.com/watch?v=oW3EfpxB6mY"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="tender"
                  >
                    E-Correspondence user guide video
                  </a>
                </li>

                <li>
                  <a
                    className="hover:text-gray-400"
                    href="https://www.bnr.rw/footer/quick-links/nbr-library/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="library"
                  >
                    NBR Library
                  </a>
                </li>

                <li>
                  <a
                    className="hover:text-gray-400"
                    href="https://www.bnr.rw/footer/procurement/e-procurement-portal/?L="
                    target="_blank"
                    rel="noreferrer"
                    aria-label="library"
                  >
                    E-Procurement
                  </a>
                </li>

                <li>
                  <a
                    className="hover:text-gray-400"
                    href="https://www.bnr.rw/footer/procurement/tender-notices/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="tender"
                  >
                    Tender Notices
                  </a>
                </li>

              </ul>

            </div>

            <div className="flex-grow">
              <h3 className="mb-5 text-sm md:text-lg font-normal">{t('footer.items.get')}</h3>
              <div className="text-gray-400 grid md:grid-cols-2 gap-3">

                <div className="flex flex-col">
                  <span>info@bnr.rw</span>
                  <span>+250788199000</span>
                  <span onClick={() => setOpen(true)}>Contact Us</span>
                </div>

                <div className="flex flex-col">
                  <span>KN6 Av 4</span>
                  <span>P.O Box 531</span>
                  <span>Kigali - Rwanda</span>
                </div>

              </div>
            </div>

          </div>

        </div>

        <div className="border-b border-gray-300 mb-3"></div>

        <div className="flex flex-col md:flex-row items-center md:justify-between mb-3">

          {/* <p className=" text-gray-400">Developed by QT Global Software ltd.</p> */}

          <p className=" text-gray-400">{`Copyright ${copyYear} - All right reserved`}</p>

          <div className="flex">
            <a className="mr-4 cursor-pointer" href="https://www.youtube.com/channel/UCNL-AuMdkEhr_FnfeT0BKRQ" target="_blank" rel="noreferrer" aria-label="youtube">
              <YouTube />
            </a>

            <a className="mr-4 cursor-pointer" href="https://twitter.com/CentralBankRw/" target="_blank" rel="noreferrer" aria-label="twitter">
              <Twitter />
            </a>

            <a className="mr-4 cursor-pointer" href="https://www.facebook.com/CentralBankRw/" target="_blank" rel="noreferrer" aria-label="facebook">
              <Facebook />
            </a>
          </div>

        </div>

        <Ticket open={open} handleClose={() => setOpen(false)} />

      </footer >

    </div >

  );
};

export default Footer;
