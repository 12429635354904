import { StringSchema, object, string } from "yup";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const loginSchema = object().shape({
  type: string(),
  username: string().when('type', (value: any, schema: StringSchema<any>) => {
    if (value[0] === "email") schema = schema.email('enter a valid email').test('email-format', 'Invalid email format', (value) => {
      return emailRegex.test(value as string);
    });
    return schema.required(value === "phone" ? 'phone number is required' : 'email is required');
  }),
  password: string().required("password is required"),
});

export const OtpSchema = object().shape({
  phoneNumber: string().required("Provide your phone number"),
  otpNumber: string().required("Provide the OTP sent to your Phone or your account password"),
});
