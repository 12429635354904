import React, { FC, useState } from 'react'

import moment from 'moment'
import { ViewMany } from "../../../Reusable";
import ReactHTMLParser from 'react-html-parser'

type Props = {
    application: any,
    lang: string,
    user: any
}

const Comments: FC<Props> = ({ application, lang }) => {

    const [open, setOpen] = useState(false);    
    const [fieldProps, setFieldProps] = useState({}) as any;
    const [showIndex, setShowIndex] = useState(0);

    const handleOpen = async (values: any, props: any) => {
        setFieldProps({ ...props, values });
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const comments = JSON.parse(application?.comments);

    const message = {
        en: 'No comments initiated yet',
        fr: 'Aucun commentaire lancé pour le moment',
        rw: 'Nta bitekerezo biratangwa kur\'ubu busabe'
    }

    return (
        <div className='flex flex-col h-full'>
            <div className='flex-grow mb-3 overflow-y-auto h-1'>
                {
                    ((comments && comments?.length === 0) || !comments) ?
                        <div className='flex justify-center items-center h-full'>
                            <span className='text-lg'>{message[lang]}</span>
                        </div>
                        :
                        <>
                            {
                                comments && comments?.map((rep: any, ind: number) => {
                                    return (
                                        <div className="flex flex-col mb-5 text-gray-600 bg-gray-100 p-2 rounded" key={ind}>
                                            <div className="flex flex-col mb-3">
                                                <div className="flex justify-between items-center">
                                                    <h3 className="font-semibold text-lg">{rep.commentedByName}</h3>
                                                    <span className="text-xs">{moment(rep.commentedAt).format('DD/MM/YYYY hh:mm')}</span>
                                                </div>

                                            </div>

                                            <div className='flex flex-col'>
                                                <p className="text-sm mb-3">{ReactHTMLParser(rep?.message)}</p>
                                                {rep?.attachments.length > 0 && <span 
                                                    className='underline cursor-pointer' onClick={() => handleOpen(rep?.attachments, {
                                                        ind,
                                                        showButton: false
                                                    })}>
                                                        view attached files
                                                    </span>}
                                            </div>
                                            

                                        </div>
                                    )
                                })
                            }
                        </>


                }
            </div>

            {open && <ViewMany
                handleClose={handleClose}
                open={open}
                fieldProps={fieldProps}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
            />}
        </div>
    )
}

export default Comments
