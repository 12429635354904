import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import { Sidebar, AppLoader } from "../../Reusable";
import { AppliedServices } from './Applied Services'
import { ProfileMessage } from './Profile Message'


const Overview: FC = () => {

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const userState = useSelector((state: any) => state?.users);

  const getComponent = (user: any) => {    
    if (user?.data?.profileCompletion !== 100) {
      return <ProfileMessage user={user} lang={lang} />
    } else {
      return <AppliedServices lang={lang} />
    }
  }


  return (
    <div className="flex flex-col xl:flex-row h-full">
      <Sidebar activeField={"Overview"} />

      <div className="font-medium text-gray-600 flex-grow">
        {/* first check if the profile is well completed/full */}
        {/* if it is not full dislay this message below*/}
        {
          userState?.loading && !userState?.user ?
            <div className="flex justify-center items-center h-full">
              <AppLoader />
            </div>
            :
            <div className="h-full">
              {getComponent(userState?.user)}
            </div>
        }
      </div>

    </div>
  );
};

export default Overview;
