export const getSteps = (json: any) => {
    const { form_details, additionals, uploads, fetches } = json;
    const titleArr = [];

    if (form_details.length > 0) {
        titleArr.push("form_details")
    }

    if (additionals.length > 0) {
        titleArr.push("additionals")
    }

    if (uploads.length > 0) {
        titleArr.push("uploads")
    }

    if (fetches && fetches.length > 0) {
        titleArr.push("fetches")
    }

    titleArr.push("notification")

    return {
        titleArr
    };
}
