export enum MessageTypes {
	SetMessage = 'MessageTypes/SetMessage',
	SetError = 'MessageTypes/SetError',
}

interface SetMessage {
	type: typeof MessageTypes.SetMessage;
	payload: {
		message: string;
	};
}

interface SetError {
	type: typeof MessageTypes.SetError;
	payload: {
		error: string;
	};
}

export type MessageActionType = SetMessage | SetError;
