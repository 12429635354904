export enum ExternalTypes {
    GetRRADetails = 'ExternalTypes/GetRRADetails',
    GetRDBDetails = 'ExternalTypes/GetRDBDetails',
    GetNIDADetails = 'ExternalTypes/GetNIDADetails',
    GetRCADetails = 'ExternalTypes/GetRCADetails',
    GetCRBDetails = 'ExternalTypes/GetCRBDetails',
    ResetPayload = 'ExternalTypes/ResetPayload',
    Loading = 'ExternalTypes/Loading',
    Errors = 'ExternalTypes/Errors'
}


interface GetRRADetails {
    type: typeof ExternalTypes.GetRRADetails;
    payload: {
        RRAdetails: any;
    };
}

interface GetRDBDetails {
    type: typeof ExternalTypes.GetRDBDetails;
    payload: {
        RDBdetails: any;
    };
}

interface GetNIDADetails {
    type: typeof ExternalTypes.GetNIDADetails;
    payload: {
        NIDAdetails: any;
    };
}


interface GetRCADetails {
    type: typeof ExternalTypes.GetRCADetails;
    payload: {
        RCAdetails: any;
    };
}

interface GetCRBDetails {
    type: typeof ExternalTypes.GetRCADetails;
    payload: {
        CRBDetails: string;
    };
}

interface ResetPayload {
    type: typeof ExternalTypes.ResetPayload;
    payload: { data: any };
  }

interface Loading {
    type: typeof ExternalTypes.Loading;
    payload: {
      loading: boolean;
    };
  }
  
  interface Errors {
    type: typeof ExternalTypes.Errors;
    payload: {
      errors: any;
    };
  }

export type UserTypeAction =
| GetRRADetails
| GetRDBDetails
| GetNIDADetails
| GetRCADetails
| GetCRBDetails
| ResetPayload
| Loading
| Errors
