import { UserTypes, UserTypeAction } from "./Types";
import { UserInitialStateType } from './Interface';

const initialState: UserInitialStateType = {};

export const userReducer = (state = initialState, action: UserTypeAction) => {
  switch (action.type) {

    case UserTypes.login:
      return { ...state, loginUser: action.payload.data, loading: false };

    case UserTypes.GetOTP:
      return { ...state, OTP: action.payload.data, loading: false };

    case UserTypes.GetUserTypes:
      return { ...state, userTypes: action.payload.data, loading: false };

    case UserTypes.ForgotPassword:
      return { ...state, forgotPassword: action.payload.data, loading: false };

    case UserTypes.ResendEmail:
      return { ...state, resendEmail: action.payload.data, loading: false };

    case UserTypes.Register:
      return { ...state, registerUser: action.payload.data, loading: false };

    case UserTypes.VerifyAccount:
      return { ...state, verifyUser: action.payload.data, loading: false };

    case UserTypes.VerifyPhoneNumber:
      return { ...state, verifyUserPhone: action.payload.data, loading: false };

    case UserTypes.ResetPassword:
      return { ...state, resetPassword: action.payload.data, loading: false };

    case UserTypes.ChangePassword:
      return { ...state, changePassword: action.payload.data, loading: false };

    case UserTypes.GetLoggedInUser:
      return { ...state, user: action.payload.data, loading: false };

    case UserTypes.UploadProfilePicture:
      return { ...state, uploadPic: action.payload.data, loading: false };

    case UserTypes.RemoveProfilePicture:
      return { ...state, removePicture: action.payload.data, loading: false };

    case UserTypes.UploadPassport:
      return { ...state, passportPic: action.payload.data, loading: false };

    case UserTypes.UpdateProfile:
      return { ...state, updatedUser: action.payload.data, loading: false }

    case UserTypes.UploadDocument:
      return { ...state, uploadedPicURL: action.payload.data, loading: false };

    case UserTypes.UpdateUserProfileCompletion:
      return { ...state, user: action.payload.data, loading: false };

    case UserTypes.SearchUser:
      return { ...state, searchResults: action.payload.data, loading: false };

    case UserTypes.CompleteAccount:
      return { ...state, completeAccount: action.payload.data, loading: false };

    case UserTypes.loading:
      return { ...state, ...action?.payload?.data };

    case UserTypes.Errors:
      return { ...state, errors: action.payload.errors };

    case UserTypes.ResetPayload:
      return { ...action?.payload?.data };
    default:
      return state;
  }
};
