import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { EmailOutlined, VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { changePasswordSchema } from './schema'
import { changePasswordAction, resetStateAction } from "../../../reduxes/user";
import { SnackMessage, ButtonLoader } from '../../Reusable'
import { ErrorHandler } from 'utils';

const ChangePassword = () => {

    const dispatch = useDispatch();

    const userState = useSelector((state: any) => state?.users);

    const navigate = useNavigate()
    const [openSnack, setOpenSnack] = useState(false);

    const [show, setShow] = useState({
        old: false,
        new: false,
        confirm: false
    })

    const handleCloseSnack = () => {
        setOpenSnack(false)
    }

    const handleShow = (type: string) => {
        setShow((prev) => {
            return {
                ...prev,
                [`${type}`]: !show[`${type}`]
            }
        })
    }

    const { t } = useTranslation();

    const [snackMessage, setSnackMessage] = useState<any>(null);

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return changePasswordAction(values);
    }, {
        retry: false,
        onError: (error: any) => {
            const { message } = ErrorHandler(error, 'server');

            setOpenSnack(true);
            setSnackMessage({
                error: true,
                message
            })
        },
        onSuccess: (data) => {
            const response = data?.data;
            setOpenSnack(true);
            setSnackMessage({
                error: false,
                message: response?.message
            })


            setTimeout(() => {

                localStorage.removeItem("app_access_token")
                localStorage.removeItem("app_refresh_token")

                const { user: existing, ...rest } = userState;
                resetStateAction({ ...rest })(dispatch);

                navigate("/login");
            }, 3000)
        }
    })

    return (
        <div className="flex justify-center items-center h-full p-2">
            <div className="flex flex-col text-gray-600 w-full md:w-1/2 lg:w-1/2 xl:w-4/12">
                <div className="mb-8">
                    <p className="font-medium text-lg">
                        {t('navbar.change_password')}
                    </p>
                </div>

                <div className="p-3 bg-white text-gray-500">

                    <div className="flex justify-center p-4">
                        <EmailOutlined fontSize="large" className="textColor" />
                    </div>

                    <div className="border-t-2 mb-5"></div>

                    <Formik
                        initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: ""
                        }}
                        validationSchema={changePasswordSchema}
                        onSubmit={async (values, actions) => {
                            try {
                                await mutateAsync(values);
                            } catch (error) {
                                return error;
                            }
                        }}
                    >
                        {({ values, setFieldValue }) => {
                            return (
                                <Form autoComplete="off">
                                    <div className="flex flex-col mb-5">
                                        <label className="font-semibold mb-2">
                                            {t('reset.form.oldlabel')}
                                        </label>
                                        <div className="relative flex justify-between h-12">
                                            <Field
                                                className="w-full h-12 px-3 text-gray-500 bg-white border border-gray-300 rounded outline-none text-xs"
                                                name="oldPassword"
                                                type={show.old ? "text" : "password"}
                                                placeholder={t('reset.form.oldplaceholder')}
                                            />
                                            <span className="absolute cursor-pointer right-2 top-3" onClick={() => handleShow("old")}>
                                                {show.old ? <VisibilityOutlined className="text-xl text-gray-400" /> : <VisibilityOffOutlined className="text-xl text-gray-400" />}
                                            </span>

                                        </div>

                                        <ErrorMessage name="oldPassword" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
                                    </div>

                                    <div className="flex flex-col mb-5">
                                        <label className="font-semibold mb-2">
                                            {t('reset.form.label')}
                                        </label>
                                        <div className="relative flex justify-between h-12">
                                            <Field
                                                className="w-full h-12 px-3 text-gray-500 bg-white border border-gray-300 rounded outline-none text-xs"
                                                name="newPassword"
                                                type={show.new ? "text" : "password"}
                                                placeholder={t('reset.form.placeholder')}
                                            />
                                            <span className="absolute cursor-pointer right-2 top-3" onClick={() => handleShow("new")}>
                                                {show.new ? <VisibilityOutlined className="text-xl text-gray-400" /> : <VisibilityOffOutlined className="text-xl text-gray-400" />}
                                            </span>

                                        </div>

                                        <ErrorMessage name="newPassword" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
                                    </div>

                                    <div className="flex flex-col mb-5">
                                        <label className="font-semibold mb-2">
                                            {t('reset.form.confirmLabel')}
                                        </label>
                                        <div className="relative flex justify-between h-12">
                                            <Field
                                                className="w-full h-12 px-3 text-gray-500 bg-white border border-gray-300 rounded outline-none text-xs"
                                                name="confirmPassword"
                                                type={show.confirm ? "text" : "password"}
                                                placeholder={t('reset.form.confirmPlaceholder')}
                                            />
                                            <span className="absolute cursor-pointer right-2 top-3" onClick={() => handleShow("confirm")}>
                                                {show.confirm ? <VisibilityOutlined className="text-xl text-gray-400" /> : <VisibilityOffOutlined className="text-xl text-gray-400" />}
                                            </span>

                                        </div>

                                        <ErrorMessage name="confirmPassword" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
                                    </div>

                                    <div className="mb-5">
                                        <div className="grid md:grid-cols-2 gap-3">
                                            <button
                                                className="flex justify-center p-3 font-semibold text-white rounded shadow buttonsColor cursor-pointer"
                                                type="submit"
                                            >
                                                {isLoading ? (
                                                    <ButtonLoader />
                                                ) : (
                                                    t('reset.form.change')
                                                )}
                                            </button>
                                            <Link
                                                to="/profile"
                                                className="flex items-center justify-center p-3 text-base font-semibold text-gray-600 border border-gray-400 rounded shadow cursor-pointer"
                                            >
                                                {t('reset.form.cancel')}
                                            </Link>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>

            {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
        </div>
    )
}

export default ChangePassword
