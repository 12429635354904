import { Dispatch } from "redux";
import { NotificationTypes } from ".";
import { ApiEndpoints, RequestHandler } from "utils";

export const getUserNotificationsAction = (query: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.getUserNotifications}?${query}`,
    method: 'GET'
  })
}

export const updateNotificationStatAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: NotificationTypes.GetNotifications, payload: { data } })
}

export const updateNofiticationStatusAction = (body: any) => {

  return RequestHandler({
    url: ApiEndpoints.UpdateNoticationStatus,
    method: "POST",
    data: body
  })
}

export const updateNotificationSocketAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: NotificationTypes.SocketNotification, payload: { data } })
}

export const resetNotificationStateAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: NotificationTypes.ResetPayload, payload: { data } })
}