import React, { useState, ChangeEvent, FC } from 'react'
import { useMutation } from 'react-query';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import avatar from "../../../../assets/images/avatar.jpg";
import { UserTypes, updateProfilePictureAction } from "../../../../reduxes/user";
import { ButtonLoader } from '../../../Reusable';

import { Oval } from "react-loader-spinner";

import { addDocumentsAction } from '../../../../reduxes/uploads'
import { ErrorHandler } from 'utils';

type Props = {
  props: any;
};

const ProfilePicture: FC<Props> = ({ props }) => {

  const { lang, setOpenSnack, setSnackMessage } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showError, setShowError] = useState('');

  const userState = useSelector((state: any) => {
    const { user } = state.users;

    return { user };
  });

  const { user } = userState;

  const updateProfileMutation = useMutation((values: any) => {
    return updateProfilePictureAction(values);
  }, {
    retry: false,
    onSettled: (data, error, variables) => {
      let message = 'Profile picture uploaded successfully';
      if (error) {
        const { message: res } = ErrorHandler(error, 'server');
        message = res;
      }

      setOpenSnack(true);
      setSnackMessage({
        error: error ? true : false,
        message
      })

      if (data) {
        const { path } = variables;
        const newUser = {
          ...user,
          data: {
            ...user?.data,
            profilePicturePath: path,
            profileURL: {
              url: '',
              isLoading: false
            }
          }
        }
        dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: newUser } });

      }
    }
  })

  const { isLoading, mutateAsync } = useMutation((values: any) => {
    return addDocumentsAction(values);
  }, {
    retry: false,
    onSuccess: async (data: any) => {
      const res = data?.data;

      const values = {
        path: res?.data[0]?.url
      }

      try {
        await updateProfileMutation?.mutateAsync(values);
      } catch (error) {
        return error;
      }
    }
  })

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files!;

    const maxSize = 10;
    const accept = ['.png', '.jpeg', '.jpg', '.JPG']

    const sizeMessage = {
      en: `selected file size is larger than allowed file size, i.e: ${maxSize} mb.`,
      fr: `Le fichier sélectionnés dépasse la taille de fichier autorisée, c'est-à-dire : ${maxSize} mb.`,
      rw: `Ifoto itoranijwe ninini kuruta ubunini bwa dosiye bwemewe, ni ukuvuga: ${maxSize} mb.`
    }

    const typeMessage = {
      en: `The selected file type is not allowed, i.e: ${accept.join(", ")}`,
      fr: `Le fichier sélectionnés n'est pas autorisée, c'est-à-dire : ${accept.join(", ")}`,
      rw: `Ifoto utoranije ntabwo yemewe, ni ukuvuga: ${accept.join(", ")}`
    }

    const checkSize = Array.from(files).filter((file: any) => file.size > (maxSize * 1024 * 1024));
    const checkType = Array.from(files).filter((file: any) => {
      return !accept.filter((v: any) => v === file.type || v.split(".").pop() === file.name.split(".").pop()).length
    });

    try {

      if (checkSize.length > 0) {
        setShowError(sizeMessage[lang])
      }

      if (checkType.length > 0) {
        setShowError(typeMessage[lang])
      }

      if (checkSize?.length === 0 && checkType?.length === 0) {
        setShowError("")
        await mutateAsync(files);
      }

    } catch (error) {
      const { message } = ErrorHandler(error, 'File server');
      setShowError(message)
      return error;
    }



  };

  return (
    <div className="p-3 flex flex-col justify-center items-center mb-5">
      <div className="flex items-center space-x-4">

        <div className="w-32 h-32 rounded-full flex justify-center items-center cursor-pointer">
          {user?.data?.profileURL?.isLoading ? (
            <Oval color="#b48523" height={20} width={20} />
          ) : (
            <img
              src={user?.data?.profileURL?.url?.length === 0 ? avatar : user?.data?.profileURL?.url}
              alt="avatar"
              className="w-32 h-32 rounded-full"
            />
          )}
        </div>

        <div className="grid md:grid-cols-2 gap-2">
          <div className='custom-yellow-bg rounded flex justify-center items-center'>

            <label
              htmlFor="upload-photo"
              className=" text-white cursor-pointer flex justify-center items-center p-3 text-sm"
            >
              {
                isLoading ? <ButtonLoader /> : t('profile.fields.upload_pic')
              }

            </label>

            <input
              type="file"
              name="photo"
              id="upload-photo"
              accept="image/*"
              className="z-0 hidden opacity-0"
              onChange={(e) => handleImageChange(e)}
            />

          </div>

        </div>

      </div>

      <div className="text-red-500 mt-3">
        {showError}
      </div >

    </div>
  )
}

export default ProfilePicture
