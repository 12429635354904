export enum Routes {
  Home = "/",
  Login = "/login",
  ForgotPassword = "/forgot-password",
  Signup = "/register",
  Dashboard = "/dashboard",
  VerifyMail = "/verify-email",
  VerifyPhone = "/verify-phone",
  ResetPassword = "/reset-password",
  Overview = "/overview",
  Profile = "/profile",
  Verify= "/verify-user",
  EditProfile = "/profile/edit-profile",
  ApplyForService="/apply",
  viewApplication="/application/view/:appId",
  AllServices = "/all-services",
  draft="/drafts",
  communication="/communications",
  notifications="/notifications",
  events="/invitation-action",
  ChangePassword="/change-password",
  AddAccount="/associates",
  Associate="/associates/add"
}
