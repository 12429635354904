import React, { FC, useState } from "react";
import { useQuery } from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import { ServiceCard } from '../../../Reusable'
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables'
import { filterApplications } from '../../../../reduxes/applications'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { AppLoader, Error } from "../../../Reusable";

type Props = {
	lang: string
}

const AppliedServices: FC<Props> = ({ lang }) => {

	const location = useLocation();
	const { t } = useTranslation();

	const ref = t('table.headers.ref')
	const name = t('table.headers.name')
	const price = t('table.headers.price')
	const amount = t('table.headers.amount')
	const actions = t('table.headers.actions')

	const noData = {
		en: 'No records found',
		fr: 'Aucun enregistrement trouvé',
		rw: 'Nta makuru yabonetse'
	}

	const [filterStatus, setFilterStatus] = useState((location?.state && location?.state?.activeStatus) ? location?.state?.activeStatus : "payment-pending");
	const [start, setStart] = useState(1)
	const [length, setLength] = useState(10);
	const [data, setData] = useState<any>({
		stats: null,
		items: []
	});

	const applicationState = useSelector((state: any) => state.applications);

	const handleFilter = (name: string) => {
		switch (name) {
			case "under-review":
				setFilterStatus("under-review")
				break;

			case "approved":
				setFilterStatus("approved")
				break;

			case "rejected":
				setFilterStatus("rejected")
				break;

			case "failed":
				setFilterStatus("failed")
				break;

			case "missing-information":
				setFilterStatus("missing-information")
				break;
			default:
				setFilterStatus("payment-pending")
				break;
		}
	}

	const buttonLang = {
		en: 'Open',
		fr: 'Ouvrir',
		rw: 'Fungura'
	}

	const statusDate = {
		'payment-pending': {
			en: 'Applied at',
			fr: 'Appliqué à',
			rw: 'Yakozwe kuri'
		},
		'under-review': {
			en: 'Submitted at',
			fr: 'Soumis à',
			rw: 'Yatanzwe kuri'
		},
		'approved': {
			en: 'Approved on',
			fr: 'Approuvé le',
			rw: 'Yemejwe kuri'
		},
		'rejected': {
			en: 'Rejected on',
			fr: 'Rejeté le',
			rw: 'Yanzwe kuri'
		},
		'failed': {
			en: 'Failed on',
			fr: 'Échoué le',
			rw: 'Yapfuye kuri'
		},
		'missing-information': {
			en: 'Reviewed on',
			fr: 'Revu le',
			rw: 'Yasuzumwe kuri'
		}
	}

	const columns: any = [
		{
			name: "referenceNumber",
			label: ref,
			options: {
				filter: false,
				sort: false,
			},
			customBodyRender: (value: string) => {
				return (
					<span className='font-body'>
						{value}
					</span>
				)
			}
		},
		{
			name: "name",
			label: name,
			options: {
				filter: false,
				sort: false,
			},
			customBodyRender: (value: string) => {
				return (
					<span className='font-body'>
						{value}
					</span>
				)
			}
		},
		{
			name: "servicePrice",
			label: price,
			options: {
				filter: false,
				sort: false,
			},
			customBodyRender: (value: string) => {
				return (
					<span className='font-body'>
						{value}
					</span>
				)
			}
		},
		{
			name: "amount",
			label: amount,
			options: {
				filter: false,
				sort: false,
			},
			customBodyRender: (value: string) => {
				return (
					<span className='font-body'>
						{value}
					</span>
				)
			}
		},
		{
			name: "applied",
			label: statusDate[filterStatus][lang],
			options: {
				filter: false,
				sort: false,
			},
			customBodyRender: (value: string) => {
				return (
					<span className='font-body'>
						{value}
					</span>
				)
			}
		},
		{
			name: "id",
			label: actions,
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value: any) => {
					return (
						<div className="flex justify-center w-full font-body">
							<Link
								to={`/application/view/${value}?status=${filterStatus}`}
								className="stepsColor p-3 w-full text-center text-sm font-bold text-white rounded"
							>{buttonLang[lang]}</Link>
						</div>
					)
				}
			}
		},
	];

	const options: MUIDataTableOptions = {
		filter: false,
		print: false,
		download: false,
		search: false,
		selectableRowsHideCheckboxes: true,
		viewColumns: false,
		textLabels: {
			body: {
				noMatch: <span className="font-body font-bold">{noData[lang]}</span>,
			}
		},
		responsive: 'standard',
		enableNestedDataAccess: '.',
		elevation: 0,
		serverSide: true,
		count: applicationState?.filteredApplications?.recordsFiltered,
		rowsPerPage: length,
		rowsPerPageOptions: [7, 10, 15, 30, 50, 70, 100],
		onTableChange: async (option, tableState) => {
			if (option === "changePage") {
				const page = tableState.page + 1;
				setStart(page)
			}

			if (option === "changeRowsPerPage") {
				setLength(tableState.rowsPerPage)
			}
		}
	}

	const fetchFiltered = (props: any) => {
		const { start, length, filterStatus } = props;
		const query = `?draw=0&start=${start}&length=${length}&status=${filterStatus}`;
		return filterApplications(query)
	}

	const { isLoading, isError, error, refetch, isFetching } = useQuery(['filter applications', start, length, filterStatus], () => {
		return fetchFiltered({ start, length, filterStatus });
	},
		{
			retry: false,
			keepPreviousData: true,
			onError: (error: any) => {
				return error;
			},
			onSuccess: (data) => {
				const response = data?.data?.data;

				const newData = response?.map((el: any) => {
					let amount = 0;
					if (el?.logistics?.length > 0) {
						amount = el.logistics.reduce((acc: any, value: any) => acc + value.amount, 0);
					}

					const dataElements = {
						id: el.id,
						amount: `${String(amount)} RWF`,
						name: el?.service.jsonName[lang],
						servicePrice: `${el?.service?.price} ${el?.service?.currency}`,
						referenceNumber: el?.referenceNumber,
						applied: moment(el?.updatedAt).format("LLL")
					}

					return dataElements;
				})

				setData(() => {
					return {
						items: [...newData],
						stats: data?.data?.stats
					}
				})

			}
		}
	)

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-full">
				<AppLoader />
			</div>
		)
	}

	if (isError) {
		return (
			<div className="flex justify-center items-center h-full">
				<Error error={error} action={() => refetch()} />
			</div>
		)
	}

	return (
		<div className="flex flex-col h-full">
			<ServiceCard
				handleFilter={handleFilter}
				status={filterStatus}
				statistics={data?.stats}
			/>

			<div className="flex-grow z-0 font-body">
				{
					isFetching ?
						<div className="h-full flex justify-center items-center rounded">
							<AppLoader />
						</div>
						:
						<MUIDataTable
							title=""
							data={data?.items}
							columns={columns}
							options={options}
						/>
				}

			</div>
		</div>
	)
};

export default AppliedServices;
