import React, { useState, FC } from "react";
import { useQuery, useMutation } from 'react-query'
import { ErrorHandler } from "utils";
import { useCookies } from "react-cookie";

import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import { tableButtons, headers } from './schema'

import { AddModal } from './Modal'

import { readAssociateAction, updateAssociateAction } from '../../../reduxes/associates';

import { AppLoader, ButtonLoader, Error, SnackMessage } from '../../Reusable'
import moment from "moment";

const AddAccount: FC = () => {

  const [length, setLength] = useState(10)
  const [action, setAction] = useState<any>({
    id: null,
    type: null,
    isSubmitting: false
  })

  const [open, setOpen] = useState(false)

  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState<any>(null);

  const [data, setData] = useState<any>([]);

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const handleClose = () => setOpen(false)

  const { isLoading, isError, error, refetch } = useQuery('read associates', () => {
    return readAssociateAction();
  },
    {
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const response = data?.data;

        const newData = response?.data.map((el: any) => {
          const dataElements = {
            row: el,
            id: el?.id,
            status: el?.invitationStatus,
            associate: el?.status,
            name: el?.name,
            email: el?.email,
            createdAt: moment(el?.createdAt).format("LLL"),
            updatedAt: moment(el?.updatedAt).format("LLL")
          }

          return dataElements;
        })

        setData([...newData])
      }
    }
  )

  const updateMutation = useMutation((values: any) => {
    return updateAssociateAction(values, action.type!);
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenSnack(true);
      setSnackMessage({
          error: true,
          message
      })
    },
    onSuccess: (data: any) => {
      const response = data?.data;
      setOpenSnack(true);
      setSnackMessage({
        error: error ? true : false,
        message: response?.message
      })

      refetch()
    }
  })

  const handleBlockAssociate = async (body: any) => { 
    setAction({
      id: body?._id,
      type: (body?.invitationStatus?.toLowerCase() === 'pending' && body?.status.toLowerCase() === 'inactive') ? 'resend' : 'update',
      isSubmitting: true
    })

    const obj = {
      email: body?.email,
      status: body?.status === 'active' ? 'blocked' : 'active'
    }

    try {
      await updateMutation?.mutateAsync({ ...obj });
    } catch (error) {
      return error;
    }
  }

  const noData = {
    en: 'No records found',
    fr: 'Aucun enregistrement trouvé',
    rw: 'Nta makuru yabonetse'
  }

  const columns: any = [
    {
      name: "name",
      label: headers["name"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "email",
      label: headers["email"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "status",
      label: headers["status"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return (
            <div className='bg-gray-200 rounded-full font-body flex justify-center items-center p-2'>
              <span className='font-body w-max'>
                {value}
              </span>
            </div>
          );
        },
      }
    },
    {
      name: "associate",
      label: headers["state"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return (
            <div className='bg-gray-200 rounded-full font-body flex justify-center items-center p-2'>
              <span className='font-body w-max'>
                {value}
              </span>
            </div>
          );
        },
      }
    },
    {
      name: "createdAt",
      label: headers["created"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "updatedAt",
      label: headers["updated"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "row",
      label: headers["actions"][lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row: any) => {
          console.log(row);
          
          let btn;
          if (row?.invitationStatus?.toLowerCase() === 'pending' && row?.status.toLowerCase() === 'inactive') btn = tableButtons["pending"][lang];
          if (row?.status?.toLowerCase() === 'active') btn = tableButtons["active"][lang];
          if (row?.status?.toLowerCase() === 'block') btn = tableButtons["block"][lang];

          return (
            <div className="flex">
              <button
                type="button"
                className={`stepsColor p-3 w-full text-center text-sm font-bold text-white rounded font-body flex justify-center items-center`}
                onClick={() => {
                  handleBlockAssociate(row);
                }}
              >
                {updateMutation?.isLoading && (action?.id === row?._id && action?.isSubmitting) ? <ButtonLoader /> : btn}
              </button>
            </div>
          )
        }
      }
    },
  ];


  const options: MUIDataTableOptions = {
    filter: false,
    print: false,
    viewColumns: false,
    download: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: <span className="font-body font-bold">{noData[lang]}</span>,
      }
    },
    responsive: 'standard',
    enableNestedDataAccess: '.',
    elevation: 0,
    serverSide: true,
    count: 10,
    rowsPerPage: length,
    rowsPerPageOptions: [1, 5, 10, 15, 30, 50, 70, 100],
    onTableChange: async (option, tableState) => {
      if (option === "changePage") {
        // setStart(page)
      }

      if (option === "changeRowsPerPage") {
        setLength(tableState.rowsPerPage)
      }
    }
  }

  return (
    <div className="h-full p-2 md:flex md:justify-center font-body">
      <div className="flex flex-col text-gray-600 w-full lg:w-3/4 h-full">
        <div className="flex justify-end items-center mb-5">
          <button
            type="button"
            className="buttonsColor px-8 py-3 text-white rounded"
            onClick={() => setOpen(true)}
          >Add</button>
        </div>

        <div className="flex-grow">

          {
            isLoading ?
              <div className="h-full flex justify-center items-center">
                <AppLoader />
              </div>
              :
              isError ?
                <div className="h-full flex justify-center items-center">
                  <Error error={error} action={() => refetch()} />
                </div>
                :
                <MUIDataTable
                  title=""
                  data={data}
                  columns={columns}
                  options={options}
                />
          }

        </div>

        {open && <AddModal
          open={open}
          handleClose={handleClose}
          lang={lang}
          data={data?.map((el: any) => { return { email: el?.email, name: el?.name, canBeRemoved: false } })}
          setOpenSnack={setOpenSnack}
          setSnackMessage={setSnackMessage}
          refetch={refetch}
        />}

      </div>

      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={() => setOpenSnack(false)} successMessage={snackMessage} />}
    </div>
  )
}

export default AddAccount;
