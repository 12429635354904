import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, Backdrop, Fade, createStyles, makeStyles, Theme } from '@material-ui/core'

import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons'
import fileDownload from 'js-file-download'
import { AppLoader } from '../Loader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);

type Props = {
    handleClose: any,
    open: any,
    fieldProps: any,
    showIndex: number,
    setShowIndex: any
}


const ViewManyModal: React.FC<Props> = ({ handleClose, open, fieldProps, showIndex, setShowIndex }) => {
    const classes = useStyles();

    const { remove, showButton } = fieldProps;

    const handleNext = (key: string) => {
        if (key === "back") {
            setShowIndex(showIndex - 1)
        } else {
            setShowIndex(showIndex + 1)
        }
    }

    const handleRemove = () => {
        setShowIndex(0)
        remove(showIndex)
        handleClose()
    }

    const handleDownloadFile = (file: any) => {
        const { url, name } = file;
        fileDownload(url, name);
    }

    const reducer = useSelector((state: any) => {
        const { loading, documents } = state.documentsUploads;

        return { loading, documents };
    });

    const { loading, documents } = reducer

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={`bg-white p-3 h-3/4 md:w-3/4 rounded outline-none`}>
                    {
                        loading || !documents ?
                            <div className='flex justify-center h-full items-center'>
                                <AppLoader />
                            </div>
                            :
                            <div className='flex flex-col h-full w-full'>
                                <div className='flex justify-between items-center text-lg text-gray-600 mb-3'>
                                    <span>{documents && documents[showIndex]["name"]}</span>
                                    {
                                        showButton && <button type='button' className='px-8 py-2 buttonsColor rounded text-white' onClick={() => handleRemove()}>
                                            Remove
                                        </button>
                                    }

                                </div>

                                <div className='flex-grow flex flex-col'>

                                    <div className='flex-grow mb-3 h-1 w-full'>
                                        {
                                            documents && documents[showIndex]?.status !== 200 ?
                                                <span className='flex justify-center items-center h-full text-lg font-bold'>{documents && documents[showIndex]?.message}</span>
                                                :
                                                documents && documents[showIndex]?.type === "pdf" ?
                                                    <iframe src={documents && documents[showIndex].url} title="preview" className="object-cover w-full h-full" />
                                                    :
                                                    documents && documents[showIndex]?.type === "image" ?
                                                        <img src={documents && documents[showIndex].url} alt="preview" className="object-contain w-full h-full" />
                                                        :
                                                        <div className='flex justify-center items-center h-full'>
                                                            <button
                                                                type='button'
                                                                className='text-white buttonsColor px-5 py-3 cursor-pointer rounded'
                                                                onClick={() => handleDownloadFile(documents[showIndex])}
                                                            >
                                                                Download File
                                                            </button>
                                                        </div>

                                        }
                                    </div>


                                    <div className='flex justify-between'>
                                        <button
                                            type='button'
                                            disabled={showIndex === 0}
                                            className='buttonsColor px-4 py-3 text-white rounded cursor-pointer'
                                            onClick={() => handleNext("back")}
                                        >
                                            <ArrowBackIosOutlined />
                                        </button>

                                        <button
                                            type='button'
                                            disabled={showIndex === (documents && documents.length - 1)}
                                            className='buttonsColor px-4 py-3 text-white rounded cursor-pointer'
                                            onClick={() => handleNext("next")}
                                        >
                                            <ArrowForwardIosOutlined />
                                        </button>
                                    </div>
                                </div>
                            </div>

                    }


                </div>
            </Fade>
        </Modal>
    )
}

export default ViewManyModal;
