import * as Yup from 'yup';
import { getSteps } from './getSteps';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'

export const getInitials = (json: any, mode: string, helpers: any) => {

    const { form_details, additionals, fetches, uploads } = json;
    const { user, lang } = helpers;

    const notification = {
        phoneNumber: user?.data?.phoneNumber,
        email: user?.data?.email
    }

    const newFormDetails = form_details.map((det: any) => {

        try {
            det.show = true;

            if (det.inputType === "address") {

                const countryVal = form_details.find((el: any) => {
                    return el.name["en"].toLowerCase() === "country"
                })?.["inputValue"]

                const location = JSON.parse(user?.data.location)
                const name = det.name["en"].toLowerCase();

                if (name === "country") {
                    det.inputValue = (mode === "new" && location[name]["value"]) ? location[name]["value"] : mode === "edit" ? det.inputValue : ''
                }

                if (name === "province") {
                    det.inputValue = (mode === "new" && location[name]) ? location[name] :  mode === 'edit' ? det.inputValue : ''
                    const requiredValue = det.validation.required.value;
                    det.validation.required.value = (location["country"]["value"].toLowerCase() !== "rwanda" && mode === 'new') ? !requiredValue : requiredValue;
                    det.show = (mode === "new" && location["country"]["value"] !== "Rwanda") ? false : (mode === "edit" && countryVal.toLowerCase() !== "rwanda") ? false : true
                }

                if (name === "district") {
                    det.inputValue = (mode === "new" && location[name]) ? location[name] :  mode === 'edit' ? det.inputValue : ''
                    const requiredValue = det.validation.required.value;
                    det.validation.required.value = (location["country"]["value"].toLowerCase() !== "rwanda" && mode === 'new') ? !requiredValue : requiredValue;
                    det.show = (mode === "new" && location["country"]["value"] !== "Rwanda") ? false : (mode === "edit" && countryVal.toLowerCase() !== "rwanda") ? false : true
                }

                if (name === "sector") {
                    det.inputValue = (mode === "new" && location[name]) ? location[name] :  mode === 'edit' ? det.inputValue : ''
                    const requiredValue = det.validation.required.value;
                    det.validation.required.value = (location["country"]["value"].toLowerCase() !== "rwanda" && mode === 'new') ? !requiredValue : requiredValue;
                    det.show = (mode === "new" && location["country"]["value"] !== "Rwanda") ? false : (mode === "edit" && countryVal.toLowerCase() !== "rwanda") ? false : true
                }

                if (name === "cell") {
                    det.inputValue = (mode === "new" && location[name]) ? location[name] :  mode === 'edit' ? det.inputValue : ''
                    const requiredValue = det.validation.required.value;
                    det.validation.required.value = (location["country"]["value"].toLowerCase() !== "rwanda" && mode === 'new') ? !requiredValue : requiredValue;
                    det.show = (mode === "new" && location["country"]["value"] !== "Rwanda") ? false : (mode === "edit" && countryVal.toLowerCase() !== "rwanda") ? false : true
                }

                if (name === "village") {
                    det.inputValue = (mode === "new" && location[name]) ? location[name] :  mode === 'edit' ? det.inputValue : ''
                    const requiredValue = det.validation.required.value;
                    det.validation.required.value = (location["country"]["value"].toLowerCase() !== "rwanda" && mode === 'new') ? !requiredValue : requiredValue;
                    det.show = (mode === "new" && location["country"]["value"] !== "Rwanda") ? false : (mode === "edit" && countryVal.toLowerCase() !== "rwanda") ? false : true
                }

            }

            if (det.inputType === "decimal" && mode === "new") {
                det.validationType = "string"
                det.fetchFrom = "RWF"
            }

            if (det.name["en"].toLowerCase().includes('phone') && mode === "new") {
                det.inputValue = /^\+250/.test(user?.data?.phoneNumber) ? user?.data.phoneNumber : ""
            }

            if (det.name["en"].toLowerCase().includes('email') && mode === "new") {
                det.inputValue = user?.data.email
            }

            if (det.inputType === "username" && mode === "new") {
                det.inputValue = user?.data.name;
            }

            if (det.inputType === "tin" && user?.data.accountType === "company" && mode === "new") {
                det.inputValue = isNaN(user?.data.nationalId) ? '' : user?.data.nationalId;
            }

            return det;
        } catch (error) {
            console.log('we have a structure error', error)
            return error
        }
    })

    const newAdditionals = additionals.map((item: any) => {
        try {
            const { collection_fields } = item;
            const newFields =  collection_fields.map((field: any) => {
                try {
                    return {
                        ...field,
                        show: true,
                        isUploading: false
                    }
                } catch (error) {
                    console.log('we have an error inside collection fields', error)
                    return error;
                }

            })
            return {
                ...item,
                collection_fields: [...newFields]
            }
        } catch (error) {
            console.log('we have an error on additional level', error)
            return error;
        }
    })

    const newUploads = uploads.map((item: any) => {
        try {
            return {
                ...item,
                isUploading: false
            }
        } catch (error) {
            console.log('we have an error on upload level', error)
            return error;
        }

    })

    const newFetches = fetches.map((item: any) => {
        try {
            return item;
        } catch (error) {
            console.log('we have an error on fetches level', error)
            return error;
        }
    })

    // end of the formdata
    const formData = {
        form_details: newFormDetails,
        additionals: newAdditionals,
        uploads: newUploads,
        fetches: newFetches,
        notification
    }

    // start of the validations
    const invalidEmail = {
        en: 'Invalid Email',
        fr: 'Email Invalide',
        rw: 'Imeri ntiyemewe'
    }

    const invalidPhone = {
        en: 'Invalid phone number',
        fr: 'Numero de telephone invalide',
        rw: 'Numero ya telefoni ntago yemewe'
    }

    let validationSchema = {
        notification: Yup.object().shape({
            phoneNumber: Yup.string().test('phone', invalidPhone[lang], (val: any) => {
                const parseNumber = val && val.length > 0 && parsePhoneNumber(val);

                const num = parseNumber?.number as string
                const CD = parseNumber?.country

                return val && val.length > 0 && CD && num && isValidPhoneNumber(num, CD);
            }).required('phone number is required'),
            email: Yup.string().required('email is required').email('enter valid email')
        })
    }

    const keys = Object.keys(formData);

    for (const iterator of keys) {
        if (iterator === 'form_details') {

            validationSchema["form_details"] = Yup.array().of(
                Yup.object().shape({
                    validation: Yup.object(),
                    validationType: Yup.string(),
                    inputType: Yup.string(),
                    fetchFrom: Yup.string(),
                    inputValue: Yup["string"]()
                        .when('validationType', ([type], schema: any) => {
                            if (type !== "string") schema = Yup[type]().typeError('must be an number')
                            return schema
                        })
                        .when('validation', ([validation], schema: any) => {
                            const { required, max, min } = validation;

                            if (validation.defaultValue === 'id') {
                                const nationalID = max.length > 0 && max[0];
                                if (nationalID.value && nationalID.value.length > 0) {
                                    schema = schema.test('len', nationalID.message[lang], (val: any) => {
                                        const checkLength = val && val.toString().length === Number(nationalID.value);
                                        return (required.value && checkLength) || (!required.value && (!val || checkLength))
                                    })
                                }
                            }

                            if (validation.defaultValue === 'passport') {
                                const passMax = max.length > 0 && max[1];
                                const passMin = min.length > 0 && min[1];

                                if (passMax.value && passMax.value.length > 0) {
                                    schema = Yup.string().max(Number(passMax.value), passMax.message[lang])
                                }

                                if (passMin.value && passMin.value.length > 0) {
                                    schema = Yup.string().min(Number(passMin.value), passMin.message[lang])
                                }
                            }

                            return schema
                        })
                        .when('validation', ([validation], schema: any) => {
                            const { required, max, min } = validation;

                            if (required.value) schema = schema.required(required.message[lang])
                            if (min.value && min.value.length > 0) schema = schema.test('len', min.message[lang], (val: any) => {

                                // check the length of the input value to be less than the defined minimum value
                                const checkLength = val && val.toString().length >= Number(min.value);

                                // check if the input is required and the length value
                                // or not required and if the value is not defined or the length of the input
                                return (required.value && checkLength) || (!required.value && (!val || checkLength))
                            });
                            if (max.value && max.value.length > 0) schema = schema.test('len', max.message[lang], (val: any) => {
                                const checkLength = val && val.toString().length <= Number(max.value);

                                return (required.value && checkLength) || (!required.value && (!val || checkLength))
                            });

                            return schema;
                        })
                        .when('inputType', ([inputType], schema: any) => {
                            if (inputType === "email") schema = schema.email(invalidEmail[lang])
                            if (inputType === 'phone') schema = schema.test('phone', invalidPhone[lang], (val: any) => {
                                const parseNumber = val && val.length > 0 && parsePhoneNumber(val);

                                const num = parseNumber?.number as string
                                const CD = parseNumber?.country

                                return val && val.length > 0 && CD && num && isValidPhoneNumber(num, CD);
                            })

                            if (inputType === "file") schema = schema.notRequired();
                            if (inputType === "fetch") schema = schema.notRequired();
                            return schema;
                        }),
                    attachments: Yup.array()
                        .when('validation', ([validation], schema: any) => {
                            const { required } = validation;
                            if (required.value) schema = schema.min(1, required.message[lang]);
                            return schema;
                        })
                        .when('inputType', ([inputType], schema: any) => {
                            if (inputType === "file" || inputType === "fetch") return schema;
                            return Yup.array();
                        })
                })
            );
        }

        if (iterator === 'uploads') {
            validationSchema["uploads"] = Yup.array(Yup.object().shape({
                validation: Yup.object(),
                attachments: Yup.array()
                    .when('validation', ([validation], schema: any) => {
                        const { required } = validation;
                        if (required.value) schema = schema.min(1, required.message[lang])
                        return schema;
                    })
            }))
        }

        if (iterator === "additionals") {
            const errorMessage = {
                en: 'At least one detail is required',
                fr: 'au moins un détail est requis',
                rw: 'byibuze ongeramo inyandiko imwe'
            }
            validationSchema["additionals"] = Yup.array(Yup.object().shape({
                collection_values: Yup.array().min(1, errorMessage[lang])
            }))
        }

        if (iterator === 'fetches') {
            validationSchema["fetches"] = Yup.array(Yup.object().shape({
                validation: Yup.object(),
                attachments: Yup.array()
                    .when('validation', ([validation], schema: any) => {
                        const { required } = validation;
                        if (required.value) schema = schema.min(1, required.message[lang])
                        return schema;
                    })
            }))
        }
    }

    const { titleArr } = getSteps(formData);

    return { validationSchema, formData, titleArr }

}
