import { Dispatch } from "redux";
import axios from "axios";
import { UserTypes } from ".";
import { ApiEndpoints, ActionHandler, ErrorHandler, RequestHandler } from "../../utils";
import { LoginInformation, ResetPassword } from "./Interface";

const fileURL = process.env.REACT_APP_FILE_URL

export const registerAction = (data: LoginInformation) => {
  return RequestHandler({
    url: ApiEndpoints.register,
    method: 'POST',
    data
  })
};

export const loginAction = async (data: LoginInformation) => {
  try {
    return RequestHandler({
      url: ApiEndpoints.login,
      method: 'POST',
      data
    })
  } catch (error) {
    return error;
  }

};

export const resendEmailAction = (values: any) => {
  return RequestHandler({
    url: ApiEndpoints.resendEmail,
    method: 'POST',
    data: values
  })
};

export const forgotPasswordAction = (values: any) => {
  return RequestHandler({
    url: ApiEndpoints.forgotPass,
    method: 'POST',
    data: values
  })
};

export const getOTPAction = (data: any) => {
  return RequestHandler({
    url: ApiEndpoints.getOTP,
    method: 'POST',
    data
  })
};

export const resetPasswordAction = (values: ResetPassword) => {
  return RequestHandler({
    url: ApiEndpoints.resetPassword,
    method: 'POST',
    data: values
  })
};

export const verifyAccountAction = (token: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.verifyAccount}?token=${token}`,
    method: 'GET'
  })
};

export const getLoggedInUserAction = () => {
  return RequestHandler({
    url: ApiEndpoints.getLoggedInUser,
    method: 'GET'
  })
};

export const getUserProfilePicture = () => {
  return RequestHandler({
    url: ApiEndpoints.getLoggedInUser,
    method: 'GET'
  })
};

export const changePasswordAction = (values: any) => {
  return RequestHandler({
    url: ApiEndpoints.changePassword,
    method: 'POST',
    data: values
  })
};

export const searchUserAction = (email: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.searchUser}?email=${email}`,
    method: 'GET'
  })
}


export const editProfileAction = (data: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.updateProfile}`,
    method: 'PUT',
    data
  })
};

export const verifyPhoneNumberAction = (values: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.verifyPhoneNumber}`,
    method: 'POST',
    data: values
  })
}

export const updateUserStatsAction = (data: any) => (dispatch: Dispatch) => {
  return dispatch({ type: UserTypes.GetLoggedInUser, payload: { data } });
}

export const updateProfilePictureAction = (value: any) => {
  return RequestHandler({
    url: ApiEndpoints?.uploadPicture,
    method: 'PUT',
    data: value
  })
};

export const removeProfilePictureAction = (props: any) => async (dispatch: Dispatch) => {
  dispatch({ type: UserTypes.loading });
  const { user } = props;

  const newUser = { ...user, data: { ...user?.data, profileURL: "", profilePicturePath: "" }}
  dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: { ...newUser } } });
};

export const uploadPassport = (datavalues: any) => async (dispatch: Dispatch) => {
  dispatch({ type: UserTypes.loading });
  const res = await axios.post(`${fileURL}uploads`, datavalues, {
    headers: {
      'x-auth': '1a9b83aa-8af3-485a-a398-13cb28475684',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  const { data } = res;

  const url = data.data[0].url

  return ActionHandler(
    {
      url: ApiEndpoints.uploadPicture,
      method: "PUT",
      dispatch,
      data: { filepath: url },
    },

    (res) => {
      const { data } = res;
      dispatch({ type: UserTypes.UploadPassport, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server')
      dispatch({ type: UserTypes.UploadPassport, payload: { data } });
    }
  );
};

export const updateUserDetails = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: UserTypes.UpdateUserProfileCompletion, payload: { data } });
}

export const getUserTypesAction = (type: string) => {
  return RequestHandler({
    url:  `${ApiEndpoints.getUserTypes}?type=${type}`,
    method: 'GET'
  })
}

export const completeAccountAction = (data: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.completeAccount}`,
    method: "POST",
    data
  })
}

export const resetStateAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: UserTypes.ResetPayload, payload: { data } })
}
