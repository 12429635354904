export const ErrorHandler = (error: any, serverName: string) => {

    // if (error.response?.status === 404) {
    //     return {
    //         error: true,
    //         message: `Not Found`,
    //         data: null
    //     }
    // }

    if (error.response) {
        const { data } = error.response;
        return { 
            ...data 
        }
    }

    if (error.message.toLowerCase() === "network error") {
        return {
            error: true,
            message: `${serverName.toLowerCase()} is temporary down, please try again later.`,
            data: null
        }
    }

    if (error.code === "ECONNABORTED") {
        return {
            error: true,
            message: 'request timed out, please try again later.',
            data: null
        }
    }
};
