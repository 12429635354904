import { MessageTypes, MessageActionType, MessageInitialState } from '.';

const initialState: MessageInitialState = {};

export const messageReducer = (state = initialState, action: MessageActionType) => {
	switch (action.type) {
		case MessageTypes.SetMessage:
			return { ...state, message: action.payload.message };

		case MessageTypes.SetError:
			return { ...state, error: action.payload.error };
		default:
			return state;
	}
};
