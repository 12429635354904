import moment from 'moment'
import React, { FC, useState } from 'react'
import { Modal, Backdrop, createStyles, makeStyles, Theme } from '@material-ui/core'

import PaymentDetails from './PaymentDetails'

type Props = {
    schema: any,
    application: any,
    lang: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);


const Contacts: FC<Props> = ({ schema, lang, application }) => {

    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);

    const titles = {
        "applied": {
            en: 'Applied service',
            fr: 'Services appliqués',
            rw: 'Serivisi yatswe'
        },
        "reference": {
            en: 'Application reference number',
            fr: 'Numéro de référence de l\'application',
            rw: 'Inomero y\'ubusabe'
        },
        "status": {
            en: 'Application Status',
            fr: 'État de la candidature',
            rw: 'Aho ubusabe bugeze'
        },
        "fee": {
            en: 'Service Fee',
            fr: 'Frais de service',
            rw: 'Igiciro cya serivisi'
        },
        "amount": {
            en: 'Amount Paid',
            fr: 'Montant Paye',
            rw: 'Amafaranga yishyuwe'
        },
        "department": {
            en: 'Application department',
            fr: 'service d\'application',
            rw: 'ishami wasabye'
        },
        "date": {
            en: 'Application submitted at',
            fr: 'candidature déposée à',
            rw: 'gusaba byatanzwe kuri'
        },
        "email": {
            en: 'Applicant contact email',
            fr: 'Email de contact du demandeur',
            rw: 'Imeyiri yo kumenyesha uwasabye'
        },
        "phone": {
            en: 'Applicant contact phone',
            fr: 'numéro de téléphone du demandeur',
            rw: 'telefone yo kumenyesha uwasabye'
        },
        "by": {
            en: 'Applicant name',
            fr: 'Noms du demandeur',
            rw: "Amazina y' uwasabye"
        },
        "type": {
            en: 'Applicant type',
            fr: 'Type du demandeur',
            rw: "Icyiciro cy' uwasabye"
        }
    }

    return (
        <div className='h-full overflow-x-auto'>

            <div className="mb-5">
                <span className="font-bold text-xl">Application details</span>
                <div className="grid md:grid-cols-4 gap-3 bg-gray-200 rounded p-3 mt-2">

                    <div className="flex flex-col">
                        <span className="font-bold">{titles["reference"][lang]}</span>
                        <span className="text-sm">{application?.referenceNumber}</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-bold">{titles["applied"][lang]}</span>
                        <span className="text-sm">{application?.service?.jsonName[lang]}</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-bold">{titles["date"][lang]}</span>
                        <span className="text-sm">{moment(application?.submittedAt).format("LLL")}</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-bold">{titles["status"][lang]}</span>
                        <span className="text-sm">{application?.status}</span>
                    </div>

                </div>
            </div>

            {application.logistics.length > 0 && <div className="mb-5">
                <span className="font-bold text-xl">Payment / Transaction details</span>
                <div className="grid md:grid-cols-5 gap-3 bg-gray-200 rounded p-3 mt-2">

                    <div className="flex flex-col">
                        <span className="font-bold">Reference number</span>
                        <span className="text-sm">{application?.referenceNumber}</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-bold">Service Price</span>
                        <span className="text-sm">{`${application?.service?.price} ${application?.service?.currency}`}</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-bold">Total Amount Paid</span>
                        <span className="text-sm">{application["payment details"]["amount"]} RWF</span>
                    </div>

                    <div className="flex flex-col">
                        <span className="font-bold">Transaction Info</span>
                        <div className='flex justify-between items-center'>
                            <span className="text-sm font-bold">Status: {application["payment details"]["status"]}</span>
                            {application["payment details"]["status"] !== "Completed" && <span className="text-sm font-bold">Amount: {application["payment details"]["difference"]} RWF</span>}
                        </div>
                    </div>

                    <div className='flex justify-center items-center'>
                        <button
                            className='buttonsColor px-4 py-3 rounded text-white cursor-pointer'
                            type="button"
                            onClick={() => setOpenModal(true)}
                        >
                            View History
                        </button>
                    </div>

                </div>
            </div>}

            <div className="mb-5">
                <span className="font-bold text-xl">Applicant details</span>
                <div className="grid md:grid-cols-2 gap-3 bg-gray-200 rounded p-3 mt-2">

                    <div className="flex flex-col">
                    <span className='font-bold'>{titles["by"][lang]}</span>
                        <span className="text-sm">{application?.user?.name}</span>
                    </div>

                    <div className="flex flex-col">
                    <span className='font-bold'>{titles["type"][lang]}</span>
                        <span className="text-sm">{application?.user?.isSubscribed === 1 ? 'Associate account' : 'Parent account'}</span>
                    </div>

                    <div className='flex flex-col'>
                        <span className='font-bold'>{titles["email"][lang]}</span>
                        <span className='text-sm'>{schema?.contacts?.email}</span>
                    </div>

                    <div className='flex flex-col'>
                        <span className='font-bold'>{titles["phone"][lang]}</span>
                        <span className='text-sm'>{schema?.contacts?.phoneNumber}</span>
                    </div>


                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <PaymentDetails logistics={application?.logistics} lang={lang} />

            </Modal>

        </div>
    )
}

export default Contacts
