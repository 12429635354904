import { NotificationTypes, NotificationInitialState } from ".";

const initialState: NotificationInitialState = {};

export const notificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case NotificationTypes.GetNotifications:
            return { ...state, notifications: action.payload.data };

        case NotificationTypes.UpdateNotification:
            return { ...state, updateNotification: action.payload.data, loading: false };

        case NotificationTypes.SocketNotification:
            return { ...state, socketNotification: action.payload.data, loading: 'socket notify' }

        case NotificationTypes.ResetPayload:
            return { ...action.payload.data };

        case NotificationTypes.Loading:
            return { ...state, loading: true };

        case NotificationTypes.Errors:
            return { ...state, errors: action.payload.errors };
        default:
            return state;
    }
};
