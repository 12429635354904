import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'

import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';
import { districtsJson, sectorsJson, cellsJson, provincesJson, villagesJson } from '../../../../data'

type Props = {
  values: any;
  setFieldValue: any
}

const divColor = "h-12 px-3 mt-2 text-sm border-0 rounded outline-none w-full";

const LocationDetails: FC<Props> = ({ values, setFieldValue }) => {

  const { t } = useTranslation()

  const countryVal = values["country"]["value"]
  const provinceVal = values["province"]
  const districtVal = values["district"]
  const sectorVal = values["sector"]
  const cellVal = values["cell"]

  const [showOthers, setShowOthers] = useState(false)

  const handleCountry = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFieldValue("location.country.value", val)
  }

  useEffect(() => {
    if (countryVal?.toLowerCase() === "rwanda") setShowOthers(true)
  }, [countryVal])

  return (
    <div className="flex flex-col">
      <span className="text-lg font-bold mb-5">{t('profile.title.location')}</span>

      <div className="flex flex-col font-medium">

        <div className="flex flex-col mb-3">
          <label className="text-sm">
            {t('profile.fields.location.country')}
            <span className="text-red-500">*</span>
          </label>
          <Field
            className={`${divColor} bg-gray-200`}
            name="location.country.value"
            as="select"
            // disabled={values["country"]["disabled"]}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleCountry(e)}
          >
            <option value=""> Country </option>
            {
              countryList().getData().map((country: any, ind: number) => {
                return (
                  <option value={country.label} key={ind}> {country.label}</option>
                )
              })
            }
          </Field>

          <ErrorMessage name="location.country" render={(msg) => <span className="text-red-500">{msg}</span>} />
        </div>



        {countryVal === "Rwanda" && showOthers && <div className={`flex flex-col mb-3`}>
          <label className="text-sm">
            {t('profile.fields.location.province')}
            <span className="text-red-500">*</span>
          </label>
          <Field
            className={`${divColor} bg-gray-200`}
            name="location.province"
            as="select"
          >
            <option value=""> Province </option>
            {
              provincesJson.map((province: any, ind: number) => {
                return (
                  <option value={JSON.stringify(province)} key={ind}> {province.name}</option>
                )
              })
            }
          </Field>

          <ErrorMessage name="location.province" render={(msg) => <span className="text-red-500">{msg}</span>} />

        </div>}

        {provinceVal?.length > 0 && showOthers && <div className="flex flex-col mb-3">
          <label className="text-sm">
            {t('profile.fields.location.district')}
            <span className="text-red-500">*</span>
          </label>
          <Field
            className={`${divColor} bg-gray-200`}
            name="location.district"
            as="select"
          >
            <option value=""> District </option>
            {
              provinceVal.length > 0 && districtsJson.filter((district: any) => {
                const provinceId = provincesJson.find((item) => {
                  const parseName = JSON.parse(provinceVal);
                  return item.id === parseName.id
                })?.id
                return district.province_id === provinceId
              }).map((item: any, ind: number) => {
                return (
                  <option value={JSON.stringify(item)} key={ind}> {item.name}</option>
                )
              })
            }
          </Field>

          <ErrorMessage name="location.district" render={(msg) => <span className="text-red-500">{msg}</span>} />
        </div>}

        {districtVal?.length > 0 && showOthers && <div className="flex flex-col mb-3">
          <label className="text-sm">
            {t('profile.fields.location.sector')}
            <span className="text-red-500">*</span>
          </label>
          <Field
            className={`${divColor} bg-gray-200`}
            name="location.sector"
            as="select"
          >
            <option value=""> Sector </option>
            {
              districtVal.length > 0 && sectorsJson.filter((sector: any) => {
                const parseName = JSON.parse(districtVal);
                const districtId = districtsJson.find((item) => item.id === parseName.id)?.id
                return sector.district_id === districtId
              }).map((item: any, ind: number) => {
                return (
                  <option value={JSON.stringify(item)} key={ind}> {item.name}</option>
                )
              })
            }
          </Field>
          <ErrorMessage name="location.sector" render={(msg) => <span className="text-red-500">{msg}</span>} />
        </div>}

        {sectorVal?.length > 0 && showOthers && <div className="flex flex-col mb-3">
          <label className="text-sm">
            {t('profile.fields.location.cell')}
            <span className="text-red-500">*</span>
          </label>
          <Field
            className={`${divColor} bg-gray-200`}
            name="location.cell"
            as="select"
          >
            <option value="" disabled> Cell </option>
            {
              sectorVal.length > 0 && cellsJson.filter((cell: any) => {
                const parseName = JSON.parse(sectorVal);
                const sectorId = sectorsJson.find((item) => item.id === parseName.id)?.id
                return cell.sector_id === sectorId
              }).map((item: any, ind: number) => {
                return (
                  <option value={JSON.stringify(item)} key={ind}> {item.name}</option>
                )
              })
            }
          </Field>
          <ErrorMessage name="location.cell" render={(msg) => <span className="text-red-500">{msg}</span>} />
        </div>}

        {cellVal?.length > 0 && showOthers && <div className="flex flex-col mb-3">
          <label className="text-sm">
            {t('profile.fields.location.village')}
            <span className="text-red-500">*</span>
          </label>
          <Field
            className={`${divColor} bg-gray-200`}
            name="location.village"
            as="select"
          >
            <option value=""> Village </option>
            {
              cellVal.length > 0 && villagesJson.filter((village: any) => {
                const parseName = JSON.parse(cellVal);
                const cellId = cellsJson.find((item) => item.id === parseName.id)?.id
                return village.cell_id === cellId
              }).map((item: any, ind: number) => {
                return (
                  <option value={JSON.stringify(item)} key={ind}> {item.name}</option>
                )
              })
            }
          </Field>
          <ErrorMessage name="location.village" render={(msg) => <span className="text-red-500">{msg}</span>} />
        </div>}

      </div>
    </div>
  )
}

export default LocationDetails
