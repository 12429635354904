export enum CommunicationTypes {
  GetAllDepartments = 'CommunicationTypes/GetDepartments',
  SendCommunication = 'CommunicationTypes/SendCommunication',
  ReplyCommunication = 'CommunicationTypes/ReplyCommunication',
  UploadAttachments = 'CommunicationTypes/UploadAttachments',
  GetParents = 'CommunicationTypes/GetParents',
  OpenParent = 'CommunicationTypes/OpenParent',
  ContactUs = "CommunicationTypes/ContactUs",
  GetParentMessages = 'CommunicationTypes/GetParentMessages',
  UpdateReadStatus = 'CommunicationTypes/UpdateReadStatus',
  GetUnreadCount = 'CommunicationTypes/UnreadMessagesCount',
  SocketCommunication = 'CommunicationTypes/SocketCommunication',
  ResetPayload = "CommunicationTypes/ResetPayload",
  Loading = 'CommunicationTypes/Loading',
  Errors = 'CommunicationTypes/Errors'
}

interface GetAllDepartments {
  type: typeof CommunicationTypes.GetAllDepartments;
  payload: {
    departments: any;
  };
}

interface SendCommunication {
  type: typeof CommunicationTypes.SendCommunication;
  payload: {
    communication: any;
  };
}

interface ReplyCommunication {
  type: typeof CommunicationTypes.ReplyCommunication;
  payload: {
    replyCommunication: any;
  };
}
interface UploadAttachments {
  type: typeof CommunicationTypes.UploadAttachments;
  payload: {
    attachments: any;
  };
}
interface GetParents {
  type: typeof CommunicationTypes.GetParents;
  payload: {
    parents: any;
  };
}

interface OpenParent {
  type: typeof CommunicationTypes.OpenParent;
  payload: {
    parent: any;
  };
}
interface GetParentMessages {
  type: typeof CommunicationTypes.GetParentMessages;
  payload: {
    messages: any;
  };
}
interface UpdateReadStatus {
  type: typeof CommunicationTypes.UpdateReadStatus;
  payload: {
    message: any;
  };
}

interface GetUnreadCount {
  type: typeof CommunicationTypes.GetUnreadCount;
  payload: {
    count: any;
  };
}

interface SocketCommunication {
  type: typeof CommunicationTypes.SocketCommunication;
  payload: {
    data: {
      message: any,
      loading: string
    };
  };
}

interface ContactUs {
  type: typeof CommunicationTypes.ContactUs;
  payload: {
    contactUs: any;
  };
}

interface ResetPayload {
  type: typeof CommunicationTypes.ResetPayload;
  payload: { data: any };
}

interface Loading {
  type: typeof CommunicationTypes.Loading;
  payload: {
    loading: boolean;
  };
}

interface Errors {
  type: typeof CommunicationTypes.Errors;
  payload: {
    errors: any;
  };
}

export type UserTypeAction =
  | GetAllDepartments
  | SendCommunication
  | ReplyCommunication
  | UploadAttachments
  | GetParents
  | OpenParent
  | ContactUs
  | GetParentMessages
  | UpdateReadStatus
  | GetUnreadCount
  | SocketCommunication
  | ResetPayload
  | Loading
  | Errors

