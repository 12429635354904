import React, { useState, useRef, MouseEvent, FC } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {
  Dashboard,
  PowerSettingsNew,
  LanguageOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
  AllOutOutlined,
  AccountCircleOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  LockOpenOutlined,
  HelpOutlined,
  AddCircleOutlineOutlined,
} from "@material-ui/icons";

import { Link, useNavigate } from "react-router-dom";
import UserAvatar from "assets/images/avatar.jpg";
import { useTranslation } from "react-i18next";

import { UserTypes } from '../../../reduxes/user'

import { Oval } from "react-loader-spinner";

import { Ticket } from "../";

const IsLoggedIn:FC = () => {

  const [open, setOpen] = useState(false);
  const [drop, setDrop] = useState(true);

  const [openLang, setOpenLang] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userState = useSelector((state: any) => state?.users);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleDrop = () => {
    setDrop(!drop);
  };

  const handleOpenLang = () => {
    setOpenLang(!openLang);
  };

  const changeLanguage = (lang: string) => {
    window.location.reload();
    i18n.changeLanguage(lang);
    setDrop(!drop);
  };

  const logout = () => {
    localStorage.removeItem("app_access_token");
    localStorage.removeItem("ttlnt.refresh");
    localStorage.removeItem("app_refresh_token");

    window.location.href = "/";
  };

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const { user } = userState;

  const { isLoading, isError } = useQuery(["profile picture"], () => {
    const { data } = user;
    const fileURL = process.env.REACT_APP_FILE_URL
    const config: AxiosRequestConfig = {
      method: "get",
      url: `${fileURL}${data.profilePicturePath}`,
      headers: {
        "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
      },
      responseType: "blob",
    }

    const newUser = {
      ...user,
      data: {
        ...user?.data,
        profileURL: {
          url: '',
          isLoading: true
        }
      }
    }

    dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: newUser } });

    return axios(config);
  },
    {
      retry: false,
      enabled: user?.data?.profileURL?.url?.length === 0 && user?.data?.profilePicturePath?.length > 0 ? true : false,
      onSettled: (data, error) => {
        const fileType = data?.headers["content-type"]

        const newUser = {
          ...user,
          data: {
            ...user?.data,
            profileURL: {
              url: (error || !fileType.includes("image")) ? '' : URL.createObjectURL(data?.data),
              isLoading: false
            }
          }
        }

        dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: newUser } });

      }
    }
  );

  return (
    <>
      {
        user && !user?.error ? (
          <div className="relative w-56">
            <div className="flex justify-between items-center">
              <div
                className="flex flex-col mr-4 cursor-pointer text-sm text-gray-600"
                onClick={() => navigate("/overview")}
              >
                <div className="self-center relative">
                  <Dashboard fontSize="small" />
                </div>

                <span className="font-medium">{t("navbar.applications")}</span>
              </div>

              <div
                className="flex flex-col mr-4 cursor-pointer text-sm text-gray-600"
                onClick={() => navigate("/services")}
              >
                <div className="self-center relative">
                  <AllOutOutlined fontSize="small" />
                </div>

                <span className="font-medium">{t("navbar.services")}</span>
              </div>

              <div className="w-12 h-12 rounded-full flex justify-center items-center cursor-pointer" onClick={() => setOpen(!open)}>
                {isLoading ? (
                  <Oval color="#b48523" height={20} width={20} />
                ) :
                  (isError || user?.data?.profileURL?.url?.length === 0) ?
                    <img
                      src={UserAvatar}
                      alt="avatar"
                      className="w-12 h-12 rounded-full"
                    />
                    :
                    <img
                      src={user?.data?.profileURL?.url}
                      alt="avatar"
                      className="w-12 h-12 rounded-full"
                    />
                }
              </div>
            </div>

            {open && (
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  className={` bg-white w-56 absolute mt-5 z-10 border rounded border-gray-200`}
                >
                  {/* names */}
                  <div className=" text-gray-600 border-b-2 border-gray-200 p-2 flex flex-col">
                    <span className="text-base font-bold">
                      {user?.data?.name?.length > 0 ? user?.data?.name : "No names available"}
                    </span>
                    <span className="text-sm font-semibold">
                      {user?.data?.email}
                    </span>
                  </div>

                  {/* profile */}

                  <div
                    className="flex space-x-4 p-3 cursor-pointer hover:bg-gray-200 text-sm text-gray-600 items-center"
                    onClick={() => {
                      setOpen(false);
                      return navigate("/profile");
                    }}
                  >
                    <AccountCircleOutlined />
                    <span className="font-semibold">{t("navbar.profile")}</span>
                  </div>

                  {/* change password */}
                  <div
                    className="flex space-x-4 p-3 cursor-pointer hover:bg-gray-200 text-sm text-gray-600 items-center"
                    onClick={() => {
                      setOpen(false);
                      return navigate("/change-password");
                    }}
                  >
                    <LockOpenOutlined />
                    <span className="font-semibold">
                      {t("navbar.change_password")}
                    </span>
                  </div>

                  {/* add account */}
                  {user?.data?.isFullyVerified && user?.data?.addAccount && (
                    <div
                      className="flex space-x-4 p-3 cursor-pointer hover:bg-gray-200 text-sm text-gray-600 items-center"
                      onClick={() => {
                        setOpen(false);
                        return navigate("/associates");
                      }}
                    >
                      <AddCircleOutlineOutlined />
                      <span className="font-semibold">
                        {t("navbar.add_account")}
                      </span>
                    </div>
                  )}

                  {/* support */}
                  <div
                    className="flex space-x-4 p-3 cursor-pointer hover:bg-gray-200 text-sm text-gray-600 items-center"
                    onClick={() => {
                      setOpen(false);
                      setOpenTicket(true);
                    }}
                  >
                    <HelpOutlined />
                    <span className="font-semibold">{t("navbar.ticket")}</span>
                  </div>

                  <div className="flex flex-col cursor-pointer text-sm text-gray-600">
                    <div
                      className="flex space-x-4 cursor-pointer hover:bg-gray-200 p-3 items-center"
                      onClick={() => {
                        // setOpen(false)
                        return handleOpenLang();
                      }}
                    >
                      <LanguageOutlined />

                      <div className="flex-grow flex justify-between items-center">
                        <span className="font-semibold">
                          {t("navbar.change_language")}
                        </span>
                        <span className="ml-1">
                          {openLang ? (
                            <KeyboardArrowUpOutlined />
                          ) : (
                            <KeyboardArrowRightOutlined />
                          )}
                        </span>
                      </div>
                    </div>

                    {/* support */}

                    <div className={`w-full ${openLang ? "show" : "hidden"}`}>
                      <span
                        className="block px-12 py-2 text-gray-600 cursor-pointer hover:bg-gray-200"
                        onClick={() => changeLanguage("en")}
                      >
                        English
                      </span>
                      <span
                        className="block px-12 py-2 text-gray-600 cursor-pointer hover:bg-gray-200"
                        onClick={() => changeLanguage("fr")}
                      >
                        Français
                      </span>
                      <span
                        className="block px-12 py-2 text-gray-600 cursor-pointer hover:bg-gray-200"
                        onClick={() => changeLanguage("rw")}
                      >
                        Kinyarwanda
                      </span>
                    </div>
                  </div>

                  <div
                    className="flex space-x-4 p-3 cursor-pointer hover:bg-gray-200 text-sm text-gray-600 items-center"
                    onClick={() => logout()}
                  >
                    <PowerSettingsNew />
                    <span className="font-semibold">{t("navbar.logout")}</span>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
        ) : (
          <div className="grid md:grid-cols-4 gap-5">
            <button
              type="button"
              className="text-gray-800 rounded border border-black flex justify-center items-center cursor-pointer font-medium py-2 px-8"
              onClick={() => setOpenTicket(true)}
            >
              {t("navbar.ticket")}
            </button>

            <Link
              to="/login"
              className=" text-gray-800 rounded border border-black flex justify-center items-center cursor-pointer font-medium py-2 px-8"
            >
              <span>{t("navbar.sign_in")}</span>
            </Link>

            <Link
              to="/register"
              className="flex items-center justify-center text-white rounded buttonsColor py-2 px-8 font-medium"
            >
              <span>{t("navbar.new_user")}</span>
            </Link>

            <div
              className=" flex justify-between cursor-pointer items-center font-medium w-full relative"
              onClick={() => handleDrop()}
            >
              <span className="">{t("navbar.language")}</span>

              {drop ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}

              <div
                className={`absolute bg-white rounded shadow-xl z-10 w-full top-12 ${drop && "hidden"
                  }`}
              >
                <span
                  className="block px-4 py-2 text-gray-600 cursor-pointer hover:bg-gray-400 hover:text-white"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </span>
                <span
                  className="block px-4 py-2 text-gray-600 cursor-pointer hover:bg-gray-400 hover:text-white"
                  onClick={() => changeLanguage("fr")}
                >
                  Français
                </span>
                <span
                  className="block px-4 py-2 text-gray-600 cursor-pointer hover:bg-gray-400 hover:text-white"
                  onClick={() => changeLanguage("rw")}
                >
                  Kinyarwanda
                </span>
              </div>
            </div>
          </div>
        )}

      <Ticket open={openTicket} handleClose={() => setOpenTicket(false)} />
    </>
  );
};

export default IsLoggedIn;
