import React, { FC } from 'react'
import { getFormElement } from '../../Utils/FormElements';

type Props = {
    schema: any,
    lang: string,
    values: any,
    formik: any,
    user: any
}


const FormDetails: FC<Props> = ({ schema, lang, values, formik, user }) => {
    const formValues = values["form_details"];
    return (
        <div className={`grid md:grid-cols-2 gap-3`}>
            {
                schema.map((key: any, ind: any) => {
                    const show = formValues[ind]["show"];
                    return (
                        <div key={ind} className={`${show ? '' : 'hidden'}`}>
                            {
                                getFormElement({
                                    schema: key,
                                    index: ind,
                                    inModal: false,
                                    formik,
                                }, lang, "form_details", values["form_details"], user)
                            }
                        </div>
                    )
                })
            }
        </div >
    )
}

export default FormDetails
