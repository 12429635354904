import React, { FC } from "react";
import { getFormElement } from "../../Utils";

type Props = {
  schema: any;
  values: any;
  lang: string;
  formik: any;
  user: any;
};

const Uploads: FC<Props> = ({ schema, values, lang, formik, user }) => {
  return (
    <div className="grid gap-3 xl:grid-cols-3">
      {schema.map((key: any, ind: any) =>
        getFormElement(
          {
            schema: key,
            index: ind,
            inModal: false,
            formik,
          },
          lang,
          "uploads",
          values["uploads"],
          user
        )
      )}
    </div>
  );
};

export default Uploads;
