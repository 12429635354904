export enum GeneralTypes {
	GetGeneralFiles = "GeneralTypes/GetGeneralFiles",
	ResetPayload = 'GeneralTypes/ResetPayload',
	Loading = "GeneralTypes/Loading",
	Errors = "GeneralTypes/Errors",
}

interface GetGeneralFiles {
	type: typeof GeneralTypes.GetGeneralFiles,
	payload: {
		data: any
	}
}

interface ResetPayload {
	type: typeof GeneralTypes.ResetPayload;
	payload: { data: any };
  }

interface Loading {
	type: typeof GeneralTypes.Loading;
	payload: {
		loading: boolean;
	};
}

interface Errors {
	type: typeof GeneralTypes.Errors;
	payload: {
	  errors: any;
	};
}

export type GeneralTypeAction =
	| GetGeneralFiles
	| ResetPayload
	| Loading
	| Errors
