import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@material-ui/core'
import { ButtonLoader, SnackMessage } from '../../Reusable'

import { cancelApplication } from '../../../reduxes/applications'

type Props = {
  handleClose: any;
  lang: string;
  open: boolean;
  mode: any;
  refetch: any
}

const modalProps = {
  resume: {
    title: {
      en: 'Resume your application',
      fr: 'Continuer ton application',
      rw: 'Gukomeza ubusabe bwawe'
    },
    question: {
      en: 'Are you sure you want to continue your application?',
      fr: 'Êtes-vous sûr de vouloir poursuivre votre application?',
      rw: 'Urumva ushaka gukomeza ubusabe bwawe?'
    }
  },
  delete: {
    title: {
      en: 'Delete your application',
      fr: 'Supprimer votre candidature',
      rw: 'Gusiba ubusabe bwawe'
    },
    question: {
      en: 'Are you sure you want to delete your application?',
      fr: 'Êtes-vous sûr de vouloir supprimer votre application?',
      rw: 'Urumva ushaka gusiba ubusabe bwawe?'
    }
  },
  buttons: {
    cancel: {
      en: "Cancel",
      fr: "Annuler",
      rw: "Hagarika"
    },
    resume: {
      en: "Continue",
      fr: "Continuer",
      rw: "Komeza"
    },
    delete: {
      en: "Delete",
      fr: "Supprimer",
      rw: "Siba"
    }
  }
}

const DialogModal: FC<Props> = ({ handleClose, open, lang, mode, refetch }) => {

  const navigate = useNavigate();

  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState<any>(null)

  const handleAction = (action: string) => {
    const { row } = mode;
    if (action === "resume") {
      navigate(`/apply?mode=edit&&id=${row.id}`)
    }

    if (action === "delete") {
      return handleDelete(row.id);
    }
  }

  const { isLoading, mutateAsync } = useMutation((values: any) => {
    return cancelApplication(values?.id);
  }, {
    retry: false,
    onSettled: (data: any, error: any) => {

      setOpenSnack(true)
      setSnackMessage({
        error: error ? true : false,
        message: error ? error?.response?.data?.message : 'service deleted successfully'
      })

      if (data) {
        setTimeout(() => {
          handleClose();
          refetch();
        }, 1500)
      }
    }
  })

  const handleDelete = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch (error) {
      return error;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='font-body'
    >
      <DialogTitle id="alert-dialog-title">
        {mode.type === "delete" ? modalProps["delete"]["title"][lang] : modalProps["resume"]["title"][lang]}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {mode.type === "delete" ? modalProps["delete"]["question"][lang] : modalProps["resume"]["question"][lang]}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <button
          className='border border-gray-200 px-6 py-2 rounded'
          onClick={() => handleClose()}
        >
          {modalProps["buttons"]["cancel"][lang]}
        </button>

        {
          mode.type === "resume" ?
            <button
              className={`stepsColor px-8 py-2 rounded text-white`}
              onClick={() => handleAction("resume")}
            >
              {modalProps["buttons"]["resume"][lang]}
            </button>
            :

            <button
              className={`bg-red-600 px-8 py-2 rounded text-white flex justify-center items-center`}
              onClick={() => handleAction("delete")}
            >
              {
                isLoading ? < ButtonLoader /> : modalProps["buttons"]["delete"][lang]
              }
            </button>
        }

      </DialogActions>

      {openSnack && <SnackMessage
        open={openSnack}
        handleCloseSnack={() => setOpenSnack(false)}
        successMessage={snackMessage}
      />}
    </Dialog>
  )
}

export default DialogModal;
