import axios from 'axios';

export const baseURL = process.env.REACT_APP_BACKEND_URL
// export const baseUrl = process.env.REACT_APP_LOCAL_URL
// export const baseUrl = process.env.REACT_APP_BNR_BACKEND_URL

const axiosInstance = axios.create({
	baseURL,
	headers: {
		"x-auth": process.env.REACT_APP_X_AUTH,
		"Content-Type": "application/json",
	}
	// timeout: 60000
});

// add token to the request
axiosInstance.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem('app_access_token');
		if (accessToken) {
			config.headers["Authorization"] = `Bearer ${accessToken}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// response interceptor to refresh token on receiving token expired
axiosInstance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (error) => {
		const originalRequest = error.config;
		let refreshToken = localStorage.getItem("app_refresh_token")

		if (refreshToken && error?.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				const refresh = await axios.get(`${baseURL}/frontend/users/ping`, {
					headers: {
						'x-auth': "367e8e44-1b4d-4f6f-83bc-dd5b8d773407",
						'Authorization': `Bearer ${refreshToken}`
					}
				})

				localStorage.setItem("app_access_token", refresh.data.data.token)
				localStorage.setItem("app_refresh_token", refresh.data.data.refreshToken)
				return axiosInstance(originalRequest)

			} catch (error: any) {
				localStorage.removeItem("app_access_token")
				localStorage.removeItem("app_refresh_token")
				return window.location.href = "/"
			}
		}
		return Promise.reject(error)
	}
);

export default axiosInstance;
