import React, { FC, useState } from 'react'
import Modal from './Modal';
import { EditOutlined, DeleteOutlined } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { FieldArray, ErrorMessage } from 'formik';

type Props = {
    schema: any,
    values: any,
    lang: string,
    user: any
}


const Additionals: FC<Props> = ({ schema, values, lang, user }) => {
    const additionalKeys = Object.keys(schema);

    const [open, setOpen] = useState(false);
    const [modalProps, setModalProps] = useState({
        title: "",
        state: ""
    });
    const [openedData, setOpenedData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [modalElements, setModalElements] = useState([])

    const handleOpen = (data: any) => {
        setModalProps({
            title: data.title,
            state: "add"
        })
        setModalElements(data)
        setOpen(true)
        setOpenedData(null)
    }

    const handleClose = () => {
        setSubmitted(false)
        setOpen(false)
    };

    const handleViewData = (data: any) => {
        setModalProps({
            title: "Edit a member",
            state: "edit"
        })
        setModalElements(data.elements)
        setOpenedData(data)
        setOpen(true)
    }

    const noMember = {
        en: 'No details added yet',
        fr: 'Pas encore de détails ajoutés',
        rw: 'Nta makuru arongerwamo'
    }

    return (
        <div className={`grid gap-4 ${additionalKeys.length === 2 ? 'md:grid-cols-2' : 'md:grid-cols-3'} h-full`}>
            {
                schema.map((key: any, index: any) => {
                    return (
                        <FieldArray
                            key={index}
                            name={`additionals[${index}].collection_values`}
                            render={({ push, remove, replace }) => {
                                const formValues = values["additionals"][index]["collection_values"]

                                const modalElements = {
                                    push,
                                    remove,
                                    replace,
                                    elements: key["collection_fields"],
                                    index,
                                    lang,
                                    valueName: formValues,
                                    submitted,
                                    setSubmitted,
                                    title: {
                                        en: `ADD ${key["collection_name"]["en"]} details`,
                                        fr: `Ajoute ${key["collection_name"]["fr"]} détails`,
                                        rw: `Ongeraho ${key["collection_name"]["rw"]}`
                                    }
                                }

                                return (
                                    <div className='flex flex-col'>
                                        <div className="flex items-center justify-between mb-4 text-gray-600">

                                            <Tooltip placement='bottom' title={
                                                <p className='text-sm'>
                                                    {
                                                        key.collection_description[lang]
                                                    }
                                                </p>
                                            }>
                                                <span className='cursor-pointer'>
                                                    <span className="font-medium text-gray-600 capitalize">{key.collection_name[lang]}</span>
                                                </span>
                                            </Tooltip>

                                            <span
                                                className='cursor-pointer buttonsColor text-white px-8 py-2 rounded flex justify-center items-center'
                                                onClick={() => handleOpen(modalElements)}
                                            >
                                                Add Item
                                            </span>

                                        </div>
                                        <div className='rounded border p-2 mb-2 overflow-x-auto text-gray-600 flex-grow'>
                                            {
                                                formValues.length === 0 ?
                                                    <span className='flex justify-center items-center h-full text-lg'>{noMember[lang]}</span>
                                                    :
                                                    <div>
                                                        {
                                                            formValues.map((member: any, ind: number) => {
                                                                return (
                                                                    <div className="flex items-center justify-between mb-4 bg-gray-100 p-2 rounded" key={ind}>
                                                                        <span className='text-lg'>{` Item ${ind + 1}`}</span>
                                                                        <div className="flex justify-between item-center">
                                                                            <span className='cursor-pointer' onClick={() => handleViewData({
                                                                                member,
                                                                                index: ind,
                                                                                elements: modalElements
                                                                            })}>
                                                                                <EditOutlined fontSize='small' className='text-purple-500' />
                                                                            </span>

                                                                            <span className='cursor-pointer' onClick={() => remove(ind)}>
                                                                                <DeleteOutlined fontSize='small' className='text-red-500' />
                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                )
                                                            })
                                                        }
                                                    </div>
                                            }

                                        </div>

                                        <ErrorMessage name={`additionals[${index}].collection_values`} render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                                    </div>
                                )
                            }}
                        />

                    )
                })
            }

            {
                open && <Modal
                    open={open}
                    handleClose={handleClose}
                    modalProps={modalProps}
                    modalElements={modalElements}
                    openedData={openedData}
                    user={user}
                    formValues={values["additionals"]}
                />
            }

        </div >
    )
}

export default Additionals;
