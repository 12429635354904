import React, { FC, useState } from 'react'
import { Field, ErrorMessage } from 'formik'

type Props = {
    props: any
}

const typeMessage = {
    message: {
        en: 'Choose the identification type',
        fr: 'Choisissez le type d\'identifiant',
        rw: 'Hitamo ubwoko bwo gukoresha'
    },
    type: {
        id: {
            en: 'National ID',
            fr: 'Carte d\'identité',
            rw: 'Numero y\'irangamuntu'
        },
        passport: {
            en: 'Passport',
            fr: 'Passeport',
            rw: 'Pasiporo'
        }
    },
    switch: {
        id: {
            en: 'Click here to use national ID',
            fr: 'Clique ici pour utilise la carte d\'identite',
            rw: 'Kanda hano ukoreshe irangamuntu'
        },
        passport: {
            en: 'Click here to use passport',
            fr: 'Clique ici pour utilise le passeport',
            rw: 'Kanda hano ukoreshe pasiporo'
        }
    }

}

const IdentificationType: FC<Props> = ({ props }) => {

    const { name, validation, id } = props["schema"]
    const { schemaName, lang, index, inModal, formik } = props
    const { setFieldValue } = formik;
    props.hide = false

    const [selected, setSelected] = useState("id");

    const handleSelect = () => {
        const fieldName = inModal ? `${id}["validation"]["defaultValue"]` : `${schemaName}[${index}]["validation"]["defaultValue"]`

        if (selected === 'id') {
            setSelected("passport")
            setFieldValue(fieldName, 'passport')
        } else {
            setSelected('id')
            setFieldValue(fieldName, 'id')
        }
    }

    return (
        <div className='flex flex-col text-gray-600'>
            <div className='flex justify-between'>
                {name[lang] && <label htmlFor={name[lang]}>{name[lang]}
                    {
                        validation.required.value && <span className="text-red-500">*</span>
                    }
                </label>}

                <span className='cursor-pointer' onClick={() => handleSelect()}>
                    {selected === "id" ? typeMessage["switch"]["passport"][lang] : typeMessage["switch"]["id"][lang]}
                </span>
            </div>

            <div className='mb-2'>
                {
                    selected === "id" && (
                        <div className='flex h-10 mt-2 text-sm'>
                            <Field
                                className="w-full px-3 text-gray-500 border rounded-l outline-none"
                                type="text"
                                name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                                placeholder={typeMessage["type"]["id"][lang]}
                            />

                            {/* <button type="button" className='buttonsColor px-5 py-2 text-white rounded-r'>
                                Fetch
                            </button> */}
                        </div>
                    )
                }

                {
                    selected === 'passport' &&
                    <div className='grid grid-cols-1 gap-2'>
                        <div>
                            <Field
                                className="w-full h-10 px-3 mt-2 text-xs text-gray-500 border rounded outline-none"
                                type="text"
                                name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                                placeholder={typeMessage["type"]["passport"][lang]}
                            />
                        </div>

                        {/* <div className="flex mt-2 border rounded-t rounded-b h-10 w-full pl-3 mb-2">
                            <div className="flex-grow self-center w-1">
                                <div className="flex items-center justify-start ">
                                    <span
                                        className="mr-4 cursor-pointer hidden md:block"
                                    >
                                        <Tooltip disableFocusListener disableTouchListener title={
                                            <p className='text-sm'>{description[lang]}</p>
                                        }>
                                            <InfoOutlined
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </span>

                                    <span className="cursor-pointer">
                                        {
                                            `${formValues[inModal ? `${name[lang]}` : `${index}`] && formValues[inModal ? `${name[lang]}` : `${index}`]["attachments"].length} added files`
                                        }
                                    </span>

                                </div>
                            </div>

                            <div className='flex justify-center items-center h-full'>


                                <label
                                    htmlFor={name[lang]} className="px-5 py-2 text-white mr-2 cursor-pointer custom-yellow-bg"
                                >
                                    {false ?
                                        <Loader type="Puff" color="#ffff" height={20} width={20} />
                                        :
                                        <CloudUploadOutlined />
                                    }

                                    <input
                                        className="z-0 hidden w-full h-10 px-3 mt-2 text-xs text-gray-500 bg-white border-0 rounded outline-none opacity-0"
                                        type="file"
                                        id={name[lang]}
                                        multiple
                                        accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/vnd.ms-powerpoint, text/plain "
                                        name={inModal ? `${name[lang]}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                                        // onChange={(e) => handleUploading(e, push)}
                                    />
                                </label>


                                <button
                                    disabled={formValues[inModal ? `${name[lang]}` : `${index}`] && formValues[inModal ? `${name[lang]}` : `${index}`]["attachments"].length === 0 ? true : false}
                                    className="px-5 py-2 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg"
                                    // onClick={() => handleOpen({
                                    //     remove,
                                    //     replace,
                                    //     index,
                                    //     showButton: true
                                    // })}
                                    type="button"
                                >
                                    <VisibilityOutlined />
                                </button>

                            </div>


                        </div> */}

                    </div>
                }
            </div>

            <ErrorMessage name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`} render={msg => <div style={{ color: 'red' }}>{msg}</div >} />

        </div>
    )
}

export default IdentificationType
