import React, { FC, Suspense } from "react";
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux'
import { Routes, Route } from "react-router-dom";
import { AppLoader, AuthLayout, Layout, ServicesLayout } from "../Reusable";
import { authorizedRoutes } from './authorizedRoute';
import { unauthorizedRoutes } from './unauthorizedRoute';
import { Home } from '../Home'
import { NotFound } from '../NotFound'

import { readGeneralFilesAction, GeneralTypes } from '../../reduxes/general'
import { ErrorHandler } from "utils";


const Routings: FC = () => {

  const dispatch = useDispatch();

  useQuery('read general files', () => {
    return readGeneralFilesAction();
  }, {
    retry: false,
    keepPreviousData: true,
    onSettled: (data, error) => {
      let response;
      if (error) {
        const res = ErrorHandler(error, 'server');
        response = res;
      }

      if (data) {
        const res = data?.data;
        response = res;
      }

      dispatch({ type: GeneralTypes.GetGeneralFiles, payload: { data: response } });

    }
  })

  return (
    <Suspense fallback={
      <div className="h-screen flex justify-center items-center mainBackground">
        <AppLoader />
      </div>
    }>
      <Routes>

        {/* homepage */}
        <Route path="/" element={
          <AuthLayout>
            <Home />
          </AuthLayout>
        } />

        {/* service page */}
        <Route path="/services" element={<ServicesLayout />} />

        {/* unauthorized routes */}
        {unauthorizedRoutes.map((route, index) => {
          return (
            <Route path={route.path} key={index} element={
              <AuthLayout>
                {route.component}
              </AuthLayout>
            } />
          )
        })}

        {/* authorized routes */}
        {authorizedRoutes.map((route, index) => (
          <Route
            path={route.path}
            key={index}
            element={
              <Layout>
                {route.component}
              </Layout>
            }
          >
          </Route>
        ))}

        {/* error page */}
        <Route path="*" element={<NotFound />} />

      </Routes>
    </Suspense>

  );
};

export default Routings;
