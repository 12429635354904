import React, { FC, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Modal, Backdrop, Fade, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Formik, Form, ErrorMessage, FieldArray } from 'formik'

import { validationSchema, tableButtons, addValidationSchema } from '../schema'

import { useTranslation } from "react-i18next";
import { CloseOutlined } from '@material-ui/icons';

import { searchUserAction } from '../../../../reduxes/user'
import { createAssociateAction } from '../../../../reduxes/associates'
import { ButtonLoader } from 'components/Reusable'
import { ErrorHandler } from 'utils'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);

type Props = {
    handleClose: any,
    open: any,
    lang: string,
    data: any[],
    setOpenSnack: any,
    setSnackMessage: any,
    refetch: any
}

const AddModal: FC<Props> = ({ handleClose, open, lang, data, setOpenSnack, setSnackMessage, refetch }) => {

    const classes = useStyles()

    const { t } = useTranslation();

    const [searching, setSearching] = useState('')

    const [inputValues, setInputValues] = useState({
        name: '',
        email: '',
        helpers: null
    })

    const handleInputValues = (e: any, type: string) => {
        const val = e.target.value;

        setInputValues((prev) => {
            return {
                ...prev,
                [type]: val
            }
        })
    }

    const handleAddValues = async (helpers: any) => {
        const { setFieldError, values } = helpers;
        try {
            const res = await addValidationSchema.validateSync({
                email: inputValues?.email,
                name: inputValues?.name,
                accounts: values["accounts"]
            }, { abortEarly: false })
            const { email } = res;
            setInputValues((prev) => {
                return {
                    ...prev,
                    helpers
                }
            })
            setSearching(email);
        } catch (error: any) {
            error?.inner.forEach((el: any) => {
                setFieldError(el?.path, el?.message?.length > 0 ? el?.message : '');
            })
        }
    }

    const searchEmailQuery = useQuery(['search associate email', searching], () => {
        return searchUserAction(searching);
    },
        {
            retry: false,
            enabled: searching?.length > 0,
            keepPreviousData: true,
            onError: (error: any) => {
                const { message } = ErrorHandler(error, 'server');

                const { setFieldError } = inputValues?.helpers as any;
                setFieldError('email', message)
                setSearching('')
            },
            onSuccess: (data) => {
                const { setFieldError, push } = inputValues?.helpers as any;
                setFieldError('email', '')
                setFieldError('name', '')

                push({
                    email: inputValues?.email,
                    name: inputValues?.name,
                    canBeRemoved: true
                })

                setSearching('');

                setInputValues((prev) => {
                    return {
                        ...prev,
                        email: '',
                        name: ''
                    }
                })
            }
        },
    )

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return createAssociateAction(values);
    }, {
        retry: false,
        onError: (error: any) => {
            const { message } = ErrorHandler(error, 'server');
            setOpenSnack(true);
            setSnackMessage({
                error: true,
                message
            })
        },
        onSuccess: (data: any) => {
            const response = data?.data;
            setOpenSnack(true);
            setSnackMessage({
                error: false,
                message: response?.message
            })
            handleClose();
            refetch();
        }
    })

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className='bg-white w-11/12 lg:w-1/2 font-body text-gray-600 h-4/5 overflow-x-auto flex flex-col'>
                    <Formik
                        initialValues={{
                            accounts: data
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {

                            try {
                                const newValues = values?.accounts?.filter((el) => el?.canBeRemoved);
                                if (newValues?.length === 0) actions.setFieldError('accounts', 'No new member is added');
                                else await mutateAsync({ accounts: [...newValues] });
                            } catch (error) {
                                return error;
                            }
                        }}
                    >
                        {({ values, errors, setFieldError }) => {
                            return (
                                <Form className="flex flex-col p-2 flex-grow">
                                    <FieldArray
                                        name="accounts"
                                        render={({ push, remove }) => {
                                            return (
                                                <div className="flex flex-col flex-grow">
                                                    <div className='flex flex-col mb-3'>

                                                        <div className='flex flex-col'>
                                                            <div className="flex flex-col mb-2">
                                                                <label htmlFor="name" className='font-bold'>
                                                                    {t('add account.fields.name.label')}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    value={inputValues?.name}
                                                                    className="px-3 h-12 text-gray-500 bg-white border border-gray-300 rounded outline-none text-base w-full"
                                                                    id="name"
                                                                    name="name"
                                                                    placeholder={t('add account.fields.name.placeholder') as string}
                                                                    onChange={(e) => handleInputValues(e, 'name')}
                                                                />
                                                                {errors?.["name"] ? <span className='text-red-500'>{errors?.["name"]}</span> : null}
                                                            </div>

                                                            <div className="flex flex-col mb-2">
                                                                <label htmlFor="email" className='font-bold'>
                                                                    {t('add account.fields.email.label')}
                                                                </label>
                                                                <input
                                                                    type='email'
                                                                    value={inputValues?.email}
                                                                    className="px-3 h-12 text-gray-500 bg-white border border-gray-300 rounded outline-none text-base w-full"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder={t('add account.fields.email.placeholder') as string}
                                                                    onChange={(e) => handleInputValues(e, 'email')}
                                                                />
                                                                {errors?.["email"] ? <span className='text-red-500'>{errors?.["email"]}</span> : null}
                                                            </div>
                                                        </div>

                                                        <div className='flex justify-center items-center w-full'>
                                                            <button
                                                                type="button"
                                                                className="buttonsColor px-8 py-2 rounded text-white w-full md:w-1/4 flex justify-center items-center"
                                                                onClick={() => {
                                                                    handleAddValues({
                                                                        setFieldError,
                                                                        push,
                                                                        values
                                                                    })
                                                                }}
                                                            >
                                                                {
                                                                    searchEmailQuery?.isLoading ? <ButtonLoader /> : tableButtons["add"][lang]
                                                                }

                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col mb-3 flex-grow">
                                                        <span className="mb-2 font-bold">{t('add account.fields.accounts.label')}</span>
                                                        <div className="border border-gray-200 rounded overflow-x-auto mb-3 flex-grow">
                                                            {
                                                                values["accounts"]?.length === 0 ?
                                                                    <div className="flex justify-center items-center h-full">{t('add account.fields.accounts.empty')}</div>
                                                                    :
                                                                    <div className='grid md:grid-cols-3 gap-3 p-2'>
                                                                        {
                                                                            values["accounts"]?.map((el: any, ind: number) => {
                                                                                return (
                                                                                    <div className='bg-gray-300 flex justify-between items-center p-2 rounded' key={ind}>
                                                                                        <div className='flex flex-col'>
                                                                                            <span className='text-sm font-bold'>{el?.name}</span>
                                                                                            <span className='text-xs'>{el?.email}</span>
                                                                                        </div>


                                                                                        {el?.canBeRemoved && <button
                                                                                            className='buttonsColor rounded-full text-white text-sm'
                                                                                            onClick={() => remove(ind)}
                                                                                        >
                                                                                            <CloseOutlined fontSize='small' />
                                                                                        </button>}
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>

                                                        <ErrorMessage name="accounts" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />

                                                        <div className='flex justify-center items-center w-full space-x-2 mt-3'>
                                                            <button
                                                                type="submit"
                                                                className="buttonsColor px-8 py-2 rounded text-white w-full flex justify-center items-center"
                                                            >
                                                                {
                                                                    isLoading ? <ButtonLoader /> : tableButtons["invite"][lang]
                                                                }
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="border border-gray-400 px-8 py-2 rounded text-gray-600 w-full"
                                                                onClick={() => handleClose()}
                                                            >
                                                                {tableButtons["close"][lang]}
                                                            </button>

                                                        </div>
                                                    </div>



                                                </div>

                                            )
                                        }}
                                    />

                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </Fade>
        </Modal>
    )
}

export default AddModal
