import { Dispatch } from "redux";
import { CommunicationTypes } from ".";
import { ApiEndpoints, ActionHandler, ErrorHandler, RequestHandler } from "utils";

export const getAllDepartmentsAction = () => {

    return RequestHandler({
        url: ApiEndpoints.getAllDepartmentServices,
        method: 'GET'
    })

}

export const sendCommunicationAction = (messageBody: any) => {
    return RequestHandler({
        url:  `${ApiEndpoints.SendCommunication}?operator=applicant`,
        method: 'POST',
        data: messageBody
    })
}


export const replyCommunicationAction = (messageBody: any, id: string) => {

    return RequestHandler({
        url:  `${ApiEndpoints.replyCommunication}/${id}?operator=applicant`,
        method: 'POST',
        data: messageBody
    })

}

export const getAllParentsAction = (query: string) => {
    return RequestHandler({
        url: `${ApiEndpoints.GetAllParents}?${query}&operator=applicant`,
        method: 'GET'
    })
}

export const openParentAction = (id: string) => async (dispatch: Dispatch) => {
    const data = { id }
    dispatch({ type: CommunicationTypes.OpenParent, payload: { data } });
}

export const updateParentsAction = (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: CommunicationTypes.GetParents, payload: { data } })
}

export const getParentMessagesAction = (props: any) => {
    const { query, id } = props;
    return RequestHandler({
        url: `${ApiEndpoints.GetParentMessages}/${id}?${query}`,
        method: 'GET'
    })
}

export const updateParentMessagesAction = (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: CommunicationTypes.GetParentMessages, payload: { data } })
}

export const updateReadStatus = (id: string) => async (dispatch: Dispatch) => {
    dispatch({ type: CommunicationTypes.Loading });
    return ActionHandler(
        { url: `${ApiEndpoints.UpdateReadStatus}/${id}?operator=applicant`, method: "PUT", dispatch },
        (res) => {
            const { data } = res;
            dispatch({ type: CommunicationTypes.UpdateReadStatus, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: CommunicationTypes.UpdateReadStatus, payload: { data } });
        }
    )
}

export const getUnreadCountMessages = () => async (dispatch: Dispatch) => {
    dispatch({ type: CommunicationTypes.Loading });
    return ActionHandler(
        { url: `${ApiEndpoints.GetUnreadCount}?operator=applicant`, method: "GET", dispatch },
        (res) => {
            const { data } = res;
            dispatch({ type: CommunicationTypes.GetUnreadCount, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: CommunicationTypes.GetUnreadCount, payload: { data } });
        }
    )
}

export const contactUsAction = (values: any) => {
    return RequestHandler({
        url: ApiEndpoints.contactUs,
        method: 'POST',
        data: values
    })
}

export const updateSocketStateAction = (data: any, type: string) => (dispatch: Dispatch) => {
    dispatch({ type: CommunicationTypes.SocketCommunication, payload: { data: {
        message: { ...data },
        loading: type
    } } })
}

export const resetStateAction = (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: CommunicationTypes.ResetPayload, payload: { data } })
}
