import React, { FC } from 'react'
import { ErrorMessage } from 'formik';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type Props = {
    props: any
}

const PhoneField: FC<Props> = ({ props }) => {
    const { name, description, validation, id } = props["schema"]
    const { schemaName, lang, index, inModal, formik, formValues } = props

    const { setFieldValue } = formik;

    return (
        <div className='flex flex-col text-gray-600 text-sm' key={index}>
            <label htmlFor={name[lang]} className="mb-2">{name[lang]}
                {
                    validation.required.value && <span className="text-red-500">*</span>
                }
            </label>

            <PhoneInput
                inputStyle={{
                    backgroundColor: 'rgba(229, 231, 235, 1)',
                }}
                country="rw"
                value={formValues[inModal ? `${id}` : index]["inputValue"]}
                searchPlaceholder="search"
                enableSearch
                // disableDropdown={!inModal}
                // onlyCountries={!inModal ? ["rw"] : []}
                placeholder={description[lang]}
                onChange={(val) => setFieldValue(inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`, `+${val}`, false)}
            />

            <ErrorMessage name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`} render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
        </div>
    )
}

export default PhoneField
