import { Routes } from "utils";

import {
  Overview,
  ProfileDetails,
  ApplyForService,
  Draft,
  Communication,
  ChangePassword,
  AddAccount,
  ViewSingleApplication,
  Notifications
} from "../index";

export const authorizedRoutes = [
  {
    component: <Overview />,
    path: Routes.Overview,
  },
  {
    component: <ProfileDetails />,
    path: Routes.Profile,
  },
  {
    component: <ChangePassword />,
    path: Routes.ChangePassword,
  },
  {
    component: <AddAccount />,
    path: Routes.AddAccount,
  },
  {
    component: <ApplyForService />,
    path: Routes.ApplyForService,
  },
  {
    component: <ViewSingleApplication />,
    path: Routes.viewApplication,
  },
  {
    component: <Draft />,
    path: Routes.draft,
  },
  {
    component: <Communication />,
    path: Routes.communication,
  },
  {
    component: <Notifications />,
    path: Routes.notifications,
  }
];
