import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query'
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useCookies } from "react-cookie";
import { getServiceById } from "../../../reduxes/service";
import { getApplicationByID } from '../../../reduxes/applications'

import RenderForm from "./RenderForm";
import { AppLoader, Error } from '../../Reusable'

import { getInitials } from "./Utils";

import { useTranslation } from 'react-i18next';

const ServiceApply = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const id: string = searchParams.get('id') as string
    const mode: string = searchParams.get('mode') as string

    const [cookies] = useCookies(["i18next"]);

    const { i18next: lang } = cookies;

    const [getDetails, setGetDetails] = useState<any>({
        name: null,
        details: null
    })

    const [action, setAction] = useState({
        triggered: false,
        action: ''
    });

    const handleServiceType = (userType: string, serviceType: string) => {
        const main = {
            en: `You are not allowed to apply for this service, this is a ${serviceType} service and your account type is ${userType}.`,
            fr: `Vous n'êtes pas autorisé à demander ce service, ce service est destiné aux ${serviceType} et votre type de compte est ${userType}`,
            rw: `Ntimwemerewe gusaba iyi serivisi, iyi serivisi yagenewe ama ${serviceType} kandi konti yanyu ibaruye nka ${userType}`,
        }

        return main[lang]
    }

    const handleApplyLastStep = (previousService: string) => {
        setSearchParams({ mode, id: previousService });
    }

    const fetchApplicationForm = async (id: string) => {
        if (mode === "new") {
            return await getServiceById(id);
        }

        if (mode === "edit") {
            return await getApplicationByID(id)
        }
    }

    const user = useSelector((state: any) => state.users?.user);

    useEffect(() => {
        if (user && user?.data?.profileCompletion !== 100) {
            return navigate('/overview')
        }
    }, [user, navigate])

    const { isLoading, isError, data, refetch, error } = useQuery<any>(['service', id], () => {
        return fetchApplicationForm(id)
    }, {
        retry: false,
        // keepPreviousData: true,
        enabled: ((mode === 'new' || mode === 'edit') && (user && !user?.error)),
        onSuccess: (data) => {
            const response = data?.data;

            const jsonDetails = mode === 'new' ? response?.data?.jsonServiceJSON : JSON.parse(response?.data?.applicationJSON);
            const name = mode === "new" ? response?.data?.jsonName : response?.data?.service?.jsonName;

            const values = getInitials(jsonDetails, mode === 'new' ? 'new' : 'edit', {
                user,
                lang
            })

            setGetDetails(() => {
                return {
                    name,
                    details: { ...values },
                    response
                }
            })

        }
    })

    if (isLoading || (!getDetails?.details || !getDetails?.name || !getDetails?.response)) {
        return (
            <div className="flex items-center justify-center mainBackground h-full">
                <AppLoader />
            </div>
        );
    }

    if (isError) {
        return (
            <div className="flex items-center justify-center h-full mainBackground">
                <Error error={error} action={() => refetch()} />
            </div>
        );
    }

    const response = data?.data;

    if (mode === "new" && !response?.data?.apply) {
        return (
            <div className="flex justify-center items-center h-full text-lg">
                <div className="flex flex-col p-3 rounded">
                    <span className="text-gray-600 text-lg tracking-wide mb-5 font-bold">{t('service.application.message.body')}</span>

                    <div className="flex justify-center items-center">
                        <button
                            className="bg-red-800 px-8 py-2 buttonsColor text-white rounded w-full lg:w-max"
                            onClick={() => handleApplyLastStep(response?.data?.previous)}
                        >
                            {t('service.application.message.button')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    // check if the user account type match with the service type
    const serviceType = response?.data?.service?.serviceType || response?.data?.serviceType;
    const accountType = user?.data?.accountType;
    const applyServiceType = ((accountType !== serviceType) && serviceType?.toLowerCase() !== 'both') ? true : false; 

    if (applyServiceType) {
        const message = handleServiceType(accountType, serviceType);
        return (
            <div className="flex justify-center items-center h-full text-lg">
                <div className="flex flex-col p-3 rounded">
                    <span className="text-gray-600 text-lg tracking-wide mb-5 font-bold">{message}</span>

                    <div className="flex justify-center items-center">
                        <button
                            className="bg-red-800 px-8 py-2 buttonsColor text-white rounded w-full lg:w-max"
                            onClick={() => navigate("/services")}
                        >
                            {lang === 'en' ? 'Go Back to all service' : lang === 'fr' ? 'Retour à tous les services' : 'Subira ahari serivisi zose'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col h-full">

            <div className="flex justify-center items-center text-xl mb-5">
                <span className="font-medium capitalize">
                    {getDetails?.name?.[lang]}
                </span>
            </div>

            <RenderForm
                formDetails={getDetails?.details}
                response={getDetails?.response}
                lang={lang}
                mode={mode!}
                setAction={setAction}
                action={action}
            />

        </div>
    );
};

export default ServiceApply;
