import React, { FC } from 'react'
import { ErrorHandler } from 'utils';

type Props = {
  action: any;
  error: any
}

const Error: FC<Props> = ({ error, action }) => {

  const handleRetry = () => action();

  const { message } = ErrorHandler(error, 'server');

  return (
    <div className='flex flex-col'>
      <span className='text-lg text-gray-600 mb-5 font-bold'>{message}</span>
      <div className='flex justify-center items-center'>
        <button 
          className='buttonsColor text-white rounded py-3 px-8'
          onClick={() => handleRetry()}
        >Retry</button>
      </div>
    </div>
  )
}

export default Error
