import { legacy_createStore as createStore, applyMiddleware, Store, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { RootReducer } from './RootReducer';

const middleware = [thunk];

// Check the environment variable to determine whether to enable the DevTools or not
const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'false';

// Use the compose function from redux-devtools-extension conditionally
const composeEnhancers: StoreEnhancer = enableDevTools ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

export const store: Store = createStore(
    RootReducer, 
    {}, 
    composeEnhancers
);

export type RootState = ReturnType<typeof RootReducer>;
