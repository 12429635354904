export const getViewSteps = (json: any) => {
    const { form_details, uploads, fetches, additionals } = json;

    const viewSteps = [];

    if (form_details && form_details.length > 0) {
        viewSteps.push("Form Details")
    }

    if (additionals && additionals.length > 0) {
        viewSteps.push("Additionals")
    }

    if (uploads && uploads.length > 0) {
        viewSteps.push("Uploads")
    }

    if (fetches && fetches.length > 0) {
        viewSteps.push("Fetches")
    }

    return viewSteps;

}