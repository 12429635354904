import React, { useState } from 'react'

import { useQuery } from 'react-query'

import { useSelector } from 'react-redux'

import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import { filterApplications } from '../../../reduxes/applications';
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";
import moment from "moment";

import DialogModal from './DialogModal';

import { AppLoader, Error, Sidebar } from '../../Reusable'

const Draft = () => {
  const { t } = useTranslation();

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const ref = t('table.headers.ref')
  const name = t('table.headers.name')
  const amount = t('table.headers.amount')
  const actions = t('table.headers.actions')

  const [start, setStart] = useState(1)
  const [length, setLength] = useState(10);

  const [data, setData] = useState<any>([])

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<any>({
    type: "",
    row: {}
  });

  const handleClickOpen = (type: any) => {
    setMode({
      type: type.name,
      row: type.row
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const noData = {
    en: 'No records found',
    fr: 'Aucun enregistrement trouvé',
    rw: 'Nta makuru yabonetse'
  }

  const dateMessage = {
    en: 'Drafted on',
    fr: 'Rédigé le',
    rw: 'Yabitswe kuri'
  }

  const columns: any = [
    {
      name: "referenceNumber",
      label: ref,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "name",
      label: name,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "amount",
      label: amount,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "applied",
      label: dateMessage[lang],
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string) => {
          return <span className="font-body">{value}</span>;
        },
      }
    },
    {
      name: "row",
      label: actions,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row: any) => {
          return (
            <div className="grid md:grid-cols-2 gap-2">
              <button
                type="button"
                className="stepsColor p-3 w-full text-center text-sm font-bold text-white rounded font-body"
                onClick={() => handleClickOpen({
                  name: "resume",
                  row
                })}
              >
                {lang === "en" ? "Resume" : lang === "fr" ? "Reprendre" : "Komeza"}
              </button>

              <button
                type="button"
                className="bg-red-600 p-3 w-full text-center text-sm font-bold text-white rounded font-body"
                onClick={() => handleClickOpen({
                  name: "delete",
                  row
                })}
              >
                {lang === "en" ? "Delete" : lang === "fr" ? "Supprimer" : "Siba"}
              </button>
            </div>
          )
        }
      }
    },
  ];


  const reducer = useSelector((state: any) => {
    return {
      applicationState: state.applications,
    }
  })

  const { applicationState } = reducer

  const fetchFiltered = (props: any) => {
    const { start, length } = props;
    const query = `?draw=0&start=${start}&length=${length}&status=draft`;
    return filterApplications(query)
  }

  const { isLoading, isError, error, refetch } = useQuery(['filter draft applications', start, length], () => {
    return fetchFiltered({ start, length });
  },
    {
      retry: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        const response = data?.data?.data;

        const newData = response?.map((el: any) => {
          const dataElements = {
            row: el,
            id: el.id,
            amount: `${String(el?.amountPaid)} RWF`,
            name: el?.service.jsonName[lang],
            servicePrice: `${el?.service?.price} ${el?.service?.currency}`,
            referenceNumber: el?.referenceNumber,
            applied: moment(el?.updatedAt).format("LLL")
          }

          return dataElements;
        })

        setData([...newData])

      }
    }
  )

  const options: MUIDataTableOptions = {
    filter: false,
    print: false,
    viewColumns: false,
    download: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: <span className="font-body font-bold">{noData[lang]}</span>,
      }
    },
    responsive: 'standard',
    enableNestedDataAccess: '.',
    elevation: 0,
    serverSide: true,
    count: applicationState?.filteredApplications?.recordsFiltered,
    rowsPerPage: length,
    rowsPerPageOptions: [1, 5, 10, 15, 30, 50, 70, 100],
    onTableChange: async (option, tableState) => {
      if (option === "changePage") {
        const page = tableState.page;
        setStart(page)
      }

      if (option === "changeRowsPerPage") {
        setLength(tableState.rowsPerPage)
      }
    }
  }

  return (
    <div className="flex flex-col xl:flex-row h-full">
      <Sidebar activeField={"draft services"} />

      {
        (isLoading) ?
          <div className="flex justify-center items-center h-full w-full">
            <AppLoader />
          </div>
          :
          isError ?
            <div className="flex justify-center items-center h-full w-full">
              <Error error={error} action={() => refetch()}/>
            </div>
            :
            <div className="w-full h-full z-0">
              <MUIDataTable
                title=""
                data={data}
                columns={columns}
                options={options}
              />
            </div>
      }

      {open && <DialogModal
        open={open}
        handleClose={handleClose}
        refetch={refetch}
        mode={mode}
        lang={lang}
      />}

    </div >
  )
}

export default Draft;
