import React, { FC } from "react";
import { useTranslation } from 'react-i18next';

type Props = {
    handleFilter: any,
    status: string,
    statistics: any
}

const ServiceCard: FC<Props> = ({ handleFilter, status, statistics }) => {

    const { t } = useTranslation();

    const statuses = [
        {
            name: t(`table.statuses.pending`),
            count: statistics?.countPendingPayment,
            status: 'payment-pending'
        },
        {
            name: t(`table.statuses.under`),
            count: statistics?.countUnderReview,
            status: 'under-review'
        },
        {
            name: t(`table.statuses.approved`),
            count: statistics?.countApproved,
            status: 'approved'
        },
        {
            name: t(`table.statuses.rejected`),
            count: statistics?.countDenied,
            status: 'rejected'
        },
        {
            name: t(`table.statuses.missing`),
            count: statistics?.countMissing,
            status: 'missing-information'
        },
        {
            name: t(`table.statuses.failed`),
            count: statistics?.countFailed,
            status: 'failed'
        }
    ]

    return (
        <div className="mb-2">
            {
                <div className="grid grid-cols-2 gap-3 md:grid-cols-3 xl:grid-cols-6">
                    {
                        statuses.map((stat: any, ind: number) => {
                            return (
                                <div className={`p-3 bg-gray-200 rounded cursor-pointer ${status === stat.status ? 'stepsColor text-white' : ""}`} onClick={() => handleFilter(stat.status)} key={ind}>
                                    <span className={`flex justify-center mb-2 text-xl ${status === stat.status ? 'text-white' : ''}`}>{stat.count}</span>
                                    <p className="font-body text-sm text-center">{stat.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
};

export default ServiceCard;
