import { Dispatch } from "redux";
import { ExternalTypes } from ".";
import { ApiEndpoints, ActionHandler, ErrorHandler, RequestHandler } from "utils";


// rdb, nida, rca, crb

export const getRDBdetails = (values: any) => (dispatch: Dispatch) => {
    dispatch({ type: ExternalTypes.Loading, payload: { data: { loading: true, type: 'get rdb' } } });
    return ActionHandler(
        { url: ApiEndpoints.getRDBdetails, method: "POST", dispatch, data: values },

        (res) => {
            const { data } = res;
            dispatch({ type: ExternalTypes.GetRDBDetails, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: ExternalTypes.GetRDBDetails, payload: { data } });
        }
    )
}

export const getRRAdetails = (values: any) => (dispatch: Dispatch) => {
    dispatch({ type: ExternalTypes.Loading, payload: { data: { loading: true, type: 'get rra' } } });
    return ActionHandler(
        { url: ApiEndpoints.getRRAdetails, method: "POST", dispatch, data: values },

        (res) => {
            const { data } = res;
            dispatch({ type: ExternalTypes.GetRRADetails, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: ExternalTypes.GetRRADetails, payload: { data } });
        }
    )
}

export const getNIDAdetails = (values: any) => (dispatch: Dispatch) => {
    dispatch({ type: ExternalTypes.Loading, payload: { data: { loading: true, type: 'get nida' } } });
    return ActionHandler(
        { url: ApiEndpoints.getNidaDetails, method: "POST", dispatch, data: values },

        (res) => {
            const { data } = res;
            dispatch({ type: ExternalTypes.GetNIDADetails, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: ExternalTypes.GetNIDADetails, payload: { data } });
        }
    )
}

export const getCRBDetails = (values: any) => async (dispatch: Dispatch) => {
    dispatch({ type: ExternalTypes.Loading, payload: { data: { loading: true, type: 'get crb' } } });

    return ActionHandler(
        { url: ApiEndpoints.getCRBdetails, method: "POST", dispatch, data: values },

        (res) => {
            const { data } = res;
            dispatch({ type: ExternalTypes.GetCRBDetails, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: ExternalTypes.GetCRBDetails, payload: { data } });
        }
    )
}

export const getRCADetails = (values: any) => async (dispatch: Dispatch) => {
    dispatch({ type: ExternalTypes.Loading, payload: { data: { loading: true, type: 'get rca' } } });

    return ActionHandler(
        { url: ApiEndpoints.getRCAdetails, method: "POST", dispatch, data: values },

        (res) => {
            const { data } = res;
            dispatch({ type: ExternalTypes.GetRCADetails, payload: { data } });
        },
        (error) => {
            const data = ErrorHandler(error, 'Server')
            dispatch({ type: ExternalTypes.GetRCADetails, payload: { data } });
        }
    )
}

export const fetchExternalDetailsAction = (values: any, type: string) => {
    let url;
    if (type === 'nida') url = ApiEndpoints.getNidaDetails;
    if (type === 'rra') url = ApiEndpoints.getRRAdetails;
    if (type === 'rdb') url = ApiEndpoints.getRDBdetails;
    if (type === 'crb') url = ApiEndpoints.getCRBdetails;
    if (type === 'rca') url = ApiEndpoints.getRCAdetails;

    return RequestHandler({
        url,
        method: 'POST',
        data: values
    })
}

export const resetStateAction = (data: any) => (dispatch: Dispatch) => {
    dispatch({ type: ExternalTypes.ResetPayload, payload: { data } })
}

