import React, { FC } from 'react'
import { Modal } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getFormElement } from '../../Utils/FormElements';
import { initForm } from '../../Utils';

type Props = {
    open: boolean,
    handleClose: any,
    modalProps: any,
    modalElements: any,
    openedData: any,
    user: any,
    formValues: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minHeight: "50vh"
        },
    }),
);

const AddModal: FC<Props> = ({
    handleClose,
    open,
    modalProps,
    modalElements,
    openedData,
    user,
    formValues
}) => {
    const classes = useStyles();

    const { elements, lang, push, replace, submitted, setSubmitted } = modalElements;

    const { _validationSchema, _formData } = initForm(elements, lang);

    const closeBtn = {
        en: "CLOSE",
        fr: "FERMER",
        rw: "FUNGA"
    }

    const addBtn = {
        en: "SAVE",
        fr: "AJOUTER",
        rw: "ONGERAMO"
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
        // onClose={handleClose}
        // closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //     timeout: 500,
        // }}
        >
            <div className="w-11/12 p-4 bg-white rounded outline-none md:h-3/4 md:w-3/5 h-3/4 flex flex-col">

                <Formik
                    initialValues={openedData ? openedData["member"] : { ..._formData }}
                    validationSchema={Yup.object().shape({
                        ..._validationSchema
                    })}
                    onSubmit={(values, actions) => {
                        setSubmitted(true);
                        if (modalProps.state === "add") {
                            push(values)
                        } else {
                            replace(openedData.index, values)
                        }
                        handleClose()
                    }}
                    enableReinitialize
                >
                    {({ values, setFieldValue, setFieldError, setTouched, setFieldTouched, validateField }) => {
                        return (
                            <Form className='flex flex-col h-full overflow-x-auto font-body'>

                                <div className="flex justify-center text-gray-600 mb-5">
                                    <p className="text-lg font-semibold uppercase">{modalProps.title[lang]}</p>
                                </div>

                                <div className='flex-grow'>
                                    <div className="grid gap-3 mb-5 lg:grid-cols-2">
                                        {
                                            elements.map((key: any, ind: number) => {
                                                const show = values[key.id]["show"]
                                                return (
                                                    <div key={ind} className={`${show ? '' : 'hidden'}`}>
                                                        {
                                                            getFormElement({
                                                                schema: key,
                                                                index: ind,
                                                                inModal: true,
                                                                formik: {
                                                                    setFieldError,
                                                                    setFieldValue,
                                                                    setTouched,
                                                                    setFieldTouched,
                                                                    validateField
                                                                },
                                                                submitted,
                                                                setSubmitted
                                                            }, lang, "additionals", { modalValues: values, formValues }, user)
                                                        }
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </div>
                                </div>

                                <div className='grid md:grid-cols-2 gap-2'>
                                    <button
                                        type="submit"
                                        className={`w-full p-2 text-white rounded buttonsColor focus:outline-none ${modalProps.state === 'view' ? 'hidden' : 'block'}`}
                                    >
                                        {addBtn[lang]}
                                    </button>

                                    <button
                                        type="button"
                                        className={`w-full p-2 text-gray-600 rounded border border-gray-300 focus:outline-none`}
                                        onClick={() => handleClose()}
                                    >
                                        {closeBtn[lang]}
                                    </button>
                                </div>


                            </Form>
                        )
                    }}

                </Formik>


            </div>
        </Modal >


    )
}

export default AddModal;
