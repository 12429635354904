import React, { useState, FC } from 'react'
import { ViewManyModal } from '../../../../Reusable'
import { useDispatch } from 'react-redux'
import { getManyDocuments } from 'reduxes/uploads'

import { ViewLocationField, ViewFileField, ViewSelectField, ViewFetchField, ViewNormalField } from '../ViewFields/ViewFields'


type Props = {
    member: any,
    lang: string
}


const ViewMember: FC<Props> = ({ member, lang }) => {

    const dispatch = useDispatch()

    const keys = Object.keys(member)

    const [openAttach, setOpenAttach] = useState(false)
    const [fieldProps, setFieldProps] = useState({}) as any;
    const [showIndex, setShowIndex] = useState(0);

    const handleOpenAttach = async (values: any, props: any) => {
        setFieldProps(props);
        setOpenAttach(true)
        await getManyDocuments(values)(dispatch);
    }

    const handleCloseAttach = () => {
        setShowIndex(0)
        setOpenAttach(false)
    }

    return (
        <div className='w-11/12 bg-white rounded outline-none md:p-4 md:w-9/12 md:h-3/4 text-gray-600 text-sm overflow-x-auto'>
            <div className='grid grid-cols-2 gap-3'>
                {
                    keys?.map((key: any, ind: number) => {
                        const countryValue = ''
                        return (
                            <div className='flex flex-col' key={ind}>
                                {
                                    member[key]["inputType"] === "fetch" ?
                                        <ViewFetchField item={member[key]} lang={lang} countryVal={countryValue} />
                                        :
                                        member[key]["inputType"] === "file" ?
                                            <ViewFileField item={member[key]} lang={lang} handleOpen={handleOpenAttach} index={ind} />
                                            :
                                            member[key]["inputType"] === "select"
                                                ?
                                                <ViewSelectField item={member[key]} lang={lang} />
                                                :
                                                member[key]["inputType"] === "address" ?
                                                    <ViewLocationField item={member[key]} lang={lang} countryVal={countryValue} />
                                                    :
                                                    <ViewNormalField item={member[key]} lang={lang} countryVal={countryValue} />
                                }

                            </div>
                        )
                    })
                }

            </div>

            <ViewManyModal
                handleClose={handleCloseAttach}
                open={openAttach}
                fieldProps={fieldProps}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
            />

        </div >

    )
}

export default ViewMember
