import { ExternalInitialState, ExternalTypes } from ".";

const initialState: ExternalInitialState = {};

export const externalsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ExternalTypes.GetRRADetails:
            return { ...state, rraDetails: action.payload.data, loading: false };

        case ExternalTypes.GetRDBDetails:
            return { ...state, rdbDetails: action.payload.data, loading: false };

        case ExternalTypes.GetNIDADetails:
            return { ...state, nidaDetails: action.payload.data, loading: false };

        case ExternalTypes.GetCRBDetails:
            return { ...state, crbDetails: action.payload.data, loading: false };

        case ExternalTypes.GetRCADetails:
            return { ...state, rcaDetails: action.payload.data, loading: false };

        case ExternalTypes.ResetPayload:
            return { ...action.payload.data };

        case ExternalTypes.Loading:
            return { ...state, ...action?.payload?.data };

        case ExternalTypes.Errors:
            return { ...state, errors: action.payload.errors };
        default:
            return state;
    }
};
