import { EventTypes, EventInitialState } from ".";

const initialState: EventInitialState = {};

export const eventReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case EventTypes.ActOnEvent:
            return { ...state, eventAction: action.payload.data, loading: false };

        case EventTypes.Loading:
            return { ...state, loading: true };

        case EventTypes.Errors:
            return { ...state, errors: action.payload.errors };
        default:
            return state;
    }
};
