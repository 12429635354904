import { ServicesTypeAction, ServiceTypes, ServicesInitialState } from '.';

const initialState: ServicesInitialState = {};

export const serviceReducer = (state = initialState, action: ServicesTypeAction) => {
	switch (action.type) {
		case ServiceTypes.GetAllDepartmentServices:
			return { ...state, DepartmentServices: action.payload.data, loading: false };

		case ServiceTypes.GetAllDepartmentServicesTable:
			return { ...state, ServicesTable: action.payload.data, loading: false };

		case ServiceTypes.GetService:
			return { ...state, service: action.payload.data, loading: false };

		case ServiceTypes.CheckServiceApply:
			return { ...state, checkService: action.payload.data, loading: false };

		case ServiceTypes.GetServiceError:
			return { ...state, error: action.payload.error, loading: false };

		case ServiceTypes.GetAllService:
			return { ...state, AllServices: action.payload.data, loading: false };

		case ServiceTypes.GetAllServiceError:
			return { ...state, error: action.payload.error, loading: false };

		case ServiceTypes.SearchService:
			return { ...state, searchData: action.payload.data, loading: false };

		case ServiceTypes.ResetPayload:
			return { ...action.payload.data };

		case ServiceTypes.SearchServiceError:
			return { ...state, error: action.payload.error, loading: false };

		case ServiceTypes.Loading:
			return { ...state, loading: true };

		case ServiceTypes.Errors:
			return { ...state, errors: action.payload.errors };
		default:
			return state;
	}
};
