import { ApiEndpoints, RequestHandler } from "../../utils";

export const createAssociateAction = (data: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.CreateAssociates}?operator=applicant`,
    method: 'POST',
    data
  })
}

export const readAssociateAction = () => {
  return RequestHandler({
    url: `${ApiEndpoints.ReadAssociates}?operator=applicant`,
    method: 'GET'
  })
}

export const updateAssociateAction = (data: any, type: string) => {

  return RequestHandler({
    url: `${type === 'update' ? ApiEndpoints.UpdateAssociate : ApiEndpoints.resendAssociateEmail}?operator=applicant`,
    method: `${type === 'update' ? 'PUT' : 'POST'}`,
    data
  })
}

export const deleteAssociateAction = (id: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.UpdateAssociate}/${id}?operator=applicant`,
    method: 'DELETE'
  })
}

