import React, { FC } from 'react'
import { IconButton, Snackbar } from '@material-ui/core';

import CloseIcon from "@material-ui/icons/Close";

type Props = {
    open: boolean,
    handleCloseSnack: any,
    successMessage: {
        error: boolean,
        message: string
    }
}

const SnackMessage: FC<Props> = ({ open, handleCloseSnack, successMessage }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleCloseSnack}
            ContentProps={{
                "aria-describedby": "message-id",
            }}
            action={[
                <IconButton
                    key="close"
                    color="inherit"
                    onClick={handleCloseSnack}
                >
                    <CloseIcon aria-label="Close" />
                </IconButton>,
            ]}
        >
            <span className={`w-max p-3 rounded text-white ${successMessage?.error ? 'bg-red-500' : 'bg-green-700'}`}>{successMessage?.message}</span>
        </Snackbar>

    );
}

export default SnackMessage
