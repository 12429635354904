import * as Yup from 'yup';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{14,}$/g;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const initialValues = {
    email: "",
    phoneNumber: "",
    password: "",
    accountType: "company",
    userType: "",
    nationality: "",
}

export const validationSchema = Yup.object({
    accountType: Yup.string().required("Account type is required"),
    userType: Yup.string().when("accountType", (value: any, schema: any) => {
        let errorMessage;
        if (value[0] === "company") errorMessage = 'Institution type is required';
        else errorMessage = 'Individual type is required'
        schema = schema.required(errorMessage)
        return schema;
    }),
    nationality: Yup.string().when("accountType", (value: any, schema: any) => {
        let errorMessage;
        if (value[0] === "company") errorMessage = 'Institution nationality is required';
        else errorMessage = 'Individual nationality is required';
        schema = schema.required(errorMessage);
        return schema;
    }),
    email: Yup.string().email("Invalid email address").test('email-format', 'Invalid email format', (value) => {
        return emailRegex.test(value as string);
    }).required("email is required"),
    phoneNumber: Yup.string().test('phone-format', 'Invalid phone number', (value) => {
        const phoneNumber = parsePhoneNumber(value as string);
        const num = phoneNumber?.number as string
        const CD = phoneNumber?.country
        return isValidPhoneNumber(num, CD);
    }).required("phone number is required"),
    password: Yup.string().matches(passwordRegex, 'Password must be at least 14 character long and contain at least one uppercase, lowercase, numeric and a special character').required("password is required")
});

export const nationalities = [
    {
        en: "Rwandan",
        fr: "Rwandais",
        rw: "Umunyarwanda",
        for: "individual"
    },
    {
        en: "Foreigner",
        fr: "Étranger",
        rw: "Umunyamahanga",
        for: "individual"
    },
    {
        en: "National",
        fr: "Nationale",
        rw: "Imbere mu gihugu",
        for: "company"
    },
    {
        en: "International",
        fr: "Internationale",
        rw: "hanze y'igihugu",
        for: "company"
    }
]
