import React, { FC, useState, useRef, UIEvent } from 'react'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux';

import { VisibilityOutlined } from '@material-ui/icons'
import { AppLoader, Error, ViewMany } from '../../../Reusable'
import moment from 'moment'

import ReactHTMLParser from 'react-html-parser'
import { RotatingLines } from 'react-loader-spinner'
import { getParentMessagesAction } from 'reduxes/communications';


type Props = {
    parentId: any
}

const MessagesBox: FC<Props> = ({ parentId }) => {

    const bottomRef = useRef<any>(null)

    const queryClient = useQueryClient();

    const [open, setOpen] = useState(false);
    const [fieldProps, setFieldProps] = useState({}) as any;
    const [showIndex, setShowIndex] = useState(0);

    const userState = useSelector((state: any) => state?.users);

    const handleOpenManyFiles = async (files: any, props: any) => {
        setOpen(true)
        setShowIndex(0);
        setFieldProps(props);
    }

    const handleClose = () => setOpen(false)

    const handleScroll = (e: UIEvent<HTMLUListElement>, helpers: any) => {
        let topScroll = e.currentTarget.scrollTop
        let heightScroll = e.currentTarget.scrollHeight
        let clientHeight = e.currentTarget.clientHeight + 1;

        const { fetchNextPage, hasNextPage } = helpers;

        if (((heightScroll - (topScroll * -1)) === clientHeight) && hasNextPage) {
            fetchNextPage();
        }
    }


    const { isLoading, isError, data: results, fetchNextPage, error, refetch, isFetching, hasNextPage } = useInfiniteQuery(['parent messages', parentId], ({ pageParam = 1 }) => {
        const query = `operator=applicant&start=${pageParam}&length=${10}`
        return getParentMessagesAction({
            id: parentId,
            query
        });
    }, {
        select: (data: any) => {
            const combinedPages = data?.pages.reduce((acc: any, page: any) => {
                return [...acc, ...page?.data?.data?.data]
            }, [])

            const meta = data?.pages[0]?.data?.data?.meta;

            return ({
                pages: [...combinedPages],
                pageParams: [meta]
            })
        },
        getNextPageParam: (lastpage, _pages) => {
            const { page, totalPages } = lastpage?.data?.data?.meta;

            if (page < totalPages) {
                return page + 1
            } else {
                return undefined
            }
        },
        onSuccess: () => queryClient.invalidateQueries(['all parents'])
    })

    return (
        <div className='flex-grow mb-3 h-96'>
            {
                isLoading ?
                    <div className='flex justify-center items-center h-full'>
                        <AppLoader />
                    </div>
                    :
                    (isError) ?
                        <div className='flex justify-center items-center h-full'>
                            <Error error={error} action={() => refetch()} />
                        </div>
                        :
                        <div className='flex flex-col h-full'>
                            {(isFetching && hasNextPage) && <span className='flex justify-center items-center'>
                                <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="20"
                                    visible={true}
                                />
                            </span>}

                            <ul className={`overflow-y-auto flex flex-col-reverse h-full`} ref={bottomRef} onScroll={(e) => handleScroll(e, {
                                fetchNextPage,
                                hasNextPage
                            })}>
                                {
                                    results?.pages?.map((mess: any, ind: any) => {
                                        const isInitiator = mess?.from["id"] === userState?.user?.data.id;
                                        const attachments = mess?.attachments

                                        let recipientName;

                                        if ((mess?.from?.role === "super-admin" || mess?.from?.role === 'other' ) || !mess?.from?.role) {
                                            recipientName = mess?.from?.name;
                                        } 
                                        else {
                                            recipientName = mess?.from?.division ? JSON.parse(mess?.from?.division?.name)["en"] : JSON.parse(mess?.from?.department?.name)["en"]
                                        }

                                        const initiatorName = userState?.user?.data?.name ? userState?.user?.data?.name : userState?.user?.data?.email;

                                        return (
                                            <li className={`flex ${isInitiator ? 'justify-end' : 'justify-start'}`} key={ind}>
                                                <div className='flex flex-col w-full lg:w-3/5 mb-3'>
                                                    <div className={`flex flex-col text-base text-gray-600 rounded-lg mb-2 p-3 ${isInitiator ? 'bg-gray-100' : 'bg-gray-300'}`}>

                                                        <div className=" leading-tight tracking-wide mb-3">
                                                            {ReactHTMLParser(mess.message)}
                                                        </div>

                                                        <div className="flex justify-between items-center text-sm">
                                                            <span className='font-bold'>{
                                                                isInitiator ? initiatorName : recipientName
                                                            }</span>
                                                            {
                                                                attachments.length > 0 && (
                                                                    <div className='flex justify-between w-36 lg:w-40 items-center'>
                                                                        <span className='font-bold'>
                                                                            {attachments.length} attached files
                                                                        </span>

                                                                        <span className='cursor-pointer font-bold' onClick={() => handleOpenManyFiles(attachments, {
                                                                            showButton: false,
                                                                            values: attachments
                                                                        })}>
                                                                            <VisibilityOutlined fontSize='small' />
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>

                                                    </div>

                                                    <span className="text-xs font-medium flex justify-end">{moment(mess.createdAt).fromNow()}</span>
                                                </div>

                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

            }

            {open && <ViewMany
                handleClose={handleClose}
                open={open}
                fieldProps={fieldProps}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
            />}


        </div>
    )
}

export default MessagesBox
