import { ApplicationsTypes, ApplicationInitialState } from ".";

const initialState: ApplicationInitialState = {};

export const applicationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ApplicationsTypes.ApplyService:
      return { ...state, createApplication: action.payload.data, loading: false };

    case ApplicationsTypes.GetAllUserApplications:
      return { ...state, statistics: action.payload.data, loading: false };

    case ApplicationsTypes.FilteredApplication:
      return { ...state, filteredApplications: action.payload.data, loading: false };

    case ApplicationsTypes.GetApplicationStats:
      return { ...state, getStats: action.payload.data, loading: false };

    case ApplicationsTypes.GetApplication:
      return { ...state, appliedApp: action.payload.data, loading: false };

    case ApplicationsTypes.UpdateApplication:
      return { ...state, updateApplication: action.payload.data, loading: false };

    case ApplicationsTypes.UploadDoc:
      return { ...state, uploadedDoc: action.payload.data, loading: false };

    case ApplicationsTypes.GetUploadedFile:
      return { ...state, uploadedFile: action.payload, loading: false };

    case ApplicationsTypes.CancelApplication:
      return { ...state, CanceledApplication: action.payload.data, loading: false };

    case ApplicationsTypes.ResetPayload:
      return { ...action.payload.data };

    case ApplicationsTypes.Loading:
      return { ...state, loading: true };

    case ApplicationsTypes.Errors:
      return { ...state, errors: action.payload.errors };
    default:
      return state;
  }
};
