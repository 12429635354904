import React, { FC, useCallback, useEffect, useState } from "react";
import { useMutation } from 'react-query'

import axios from "axios";

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../../assets/images/avatar.jpg";

import { Formik, Form } from 'formik';

import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';

import { Tooltip, Modal, Backdrop, Fade, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Verified, CloseOutlined } from '@mui/icons-material'

import { ProfilePicture, LocationDetails, ProfileFields } from './Fields'
import { AppLoader, ButtonLoader, SnackMessage } from '../../Reusable'

import { editProfileAction, resetStateAction } from '../../../reduxes/user'
import { ErrorHandler } from "utils";

const cancelBtn = "font-semibold text-gray-600 rounded shadow border border-gray-400 px-6 py-2"

type Props = {
  open: boolean,
  handleClose: any,
  data: any,
  lang: string
}

const ViewParentModal: FC<Props> = ({ open, handleClose, data, lang }) => {

  const parent = data?.parentAccount;

  const [picture, setPicture] = useState(avatar);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%'
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
      },
    }),
  );

  const classes = useStyles();

  const handleProfilePicture = useCallback(async () => {
    if (parent?.profilePicturePath) {

      const fileURL = process.env.REACT_APP_FILE_URL;

      const config = {
        method: "GET",
        url: `${fileURL}${parent?.profilePicturePath}`,
        headers: {
          "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
        },
        responseType: "blob",
      }

      try {
        const resp = await axios(config as any);
        const url = URL.createObjectURL(resp.data)
        setPicture(url);
      } catch (error) {
        setPicture(avatar);
      }

    }
  }, [parent])

  useEffect(() => {
    handleProfilePicture();
  }, [handleProfilePicture])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={`w-11/12 bg-white rounded p-2 md:w-3/5 xl:w-1/3 h-max font-body`}>
          <div className="flex justify-between items-center">
            <span className="font-bold text-ld">{`${lang === "en" ? "Parent Account Details" : lang === "fr" ? "Détails du compte parent" : "Ibisobanuro bya konti nyamukuru"}`}</span>
            <button type="button" onClick={() => handleClose()} className="custom-yellow-bg text-white rounded-full p-2">
              <CloseOutlined />
            </button>
          </div>

          <div className="flex justify-center items-center">

            <img
              src={picture}
              alt="profile-pic"
              className="rounded-full w-32 h-32"
            />

          </div>

          <div className="flex flex-col mb-1">
            <span className="font-bold mb-2">{`${lang === "en" ? "Name" : lang === "fr" ? "Noms" : "Amazina"}`}</span>
            <span className="h-max bg-gray-200 p-2 rounded">{parent?.name}</span>
          </div>

          <div className="grid md:grid-cols-2 md:gap-2 mb-2">
            <div className="flex flex-col mb-3">
              <span className="font-bold mb-2">{`${lang === "en" ? "Nationality" : lang === "fr" ? "Nationalité" : "Ubwenegihugu"}`}</span>
              <span className="h-max bg-gray-200 p-2 rounded">{parent?.nationality}</span>
            </div>

            <div className="flex flex-col">
              <span className="font-bold mb-2">{`${lang === "en" ? "Registration number" : lang === "fr" ? "Numéro d'enregistrement" : "Inomero yo kwiyandikisha"}`}</span>
              <span className="h-max bg-gray-200 p-2 rounded">{parent?.nationalId || 'N/A'}</span>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-2 mb-1">
            <div className="flex flex-col mb-3">
              <span className="font-bold mb-2">{`${lang === "en" ? "Account type" : lang === "fr" ? "Type de compte" : "Ubwoko bwa konti"}`}</span>
              <span className="h-max bg-gray-200 p-2 rounded">{parent?.accountType === 'company' ? 'Institution' : 'Individual'}</span>
            </div>

            <div className="flex flex-col">
              <span className="font-bold mb-2">{`${lang === "en" ? "User type" : lang === "fr" ? "Type d'utilisateur" : "Ubwoko bw' ukoresha"}`}</span>
              <span className="h-max bg-gray-200 p-2 rounded">{parent?.userType[lang]}</span>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-2 mb-1">
            <div className="flex flex-col mb-3">
              <span className="font-bold mb-2">{`${lang === "en" ? "Email" : lang === "fr" ? "Email" : "Imeri"}`}</span>
              <span className="h-max bg-gray-200 p-2 rounded">{parent?.email}</span>
            </div>

            <div className="flex flex-col">
              <span className="font-bold mb-2">{`${lang === "en" ? "Phone number" : lang === "fr" ? "Numéro de telephone" : "Numero ya telefone"}`}</span>
              <span className="h-max bg-gray-200 p-2 rounded">{parent?.phoneNumber}</span>
            </div>
          </div>


        </div>
      </Fade>
    </Modal>
  )
}

const ProfileDetails: FC = () => {

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState<{ error: boolean, message: string }>({ error: true, message: '' } as any)
  const [initialValues, setInitialValues] = useState({
    isBuilding: true,
    profileCompletion: 0,
    profile: {},
    location: {}
  });
  const [openModal, setOpenModal] = useState(false)

  const userState = useSelector((state: any) => state.users);

  const handleEditable = () => navigate("/overview");

  const handleCloseSnack = () => setOpenSnack(false)

  const handleMessage = (parent: any) => {
    const account = parent?.parentAccount?.name;

    const message = {
      en: `NB: This is an associated account to ${account}, if you want to view the details of the parent account click`,
      fr: `NB: Il s'agit d'un compte d'associé a ${account}, si vous souhaitez afficher les détails du compte parent, cliquez`,
      rw: `NB: Iyi ni konte ikomatanije kuri ${account}, niba ushaka kureba ibisobanuro bya konte yayikoze kanda`
    }
    return message[lang];
  }

  const profileMessage = {
    completion: {
      en: "Profile completion",
      fr: "Achèvement du profil",
      rw: "Ikigero cy'umwirondoro"
    },
    all: {
      en: 'All fields with a red asterisk',
      fr: 'Toutes les entrées avec un astérisque rouge',
      rw: 'Ahari inyenyeri zitukura hose'
    },
    mandatory: {
      en: 'are mandatory to complete your profile',
      fr: 'sont obligatoires pour compléter votre profil',
      rw: 'zirakenewe kugira ngo umwirondoro wawe wuzure'
    }
  }

  useEffect(() => {
    if (userState?.user) {
      const { data } = userState?.user;

      const userTypesOptions = data?.userTypes?.filter((el: any) => {
        return el?.value?.for.toLowerCase() === 'both' || el?.value?.for.toLowerCase() === data?.accountType
      }).map((option: any) => {
        const obj = {
          value: option.id,
          label: option.value[lang],
          shouldVerify: option.value.shouldVerify
        }
        return obj;
      })

      const initialValues = {
        profile: {
          profileCompletion: data?.profileCompletion || '',
          accountType: data?.accountType || '',
          isRegistered: data?.isRegistered,
          isEmailVerified: data?.isEmailVerified,
          isPhoneVerified: data?.isPhoneVerified,
          isSubscribed: data?.isSubscribed,
          userType: {
            options: userTypesOptions,
            type: data?.userType || '',
            isChanged: false
          },
          name: data?.name || '',
          email: data?.email || '',
          phoneNumber: data?.phoneNumber || '',
          nationalId: data?.nationalId || '',
          nationality: {
            value: data?.nationality || '',
            isChanged: false,
          },
          reverifyAccount: false,
          verifyWith: data?.verifyWith?.value,
          verifyOptions: data?.verifyWith?.options,
          parentAccount: data?.parentAccount
        },
        location: {
          country: data?.location ? { ...JSON.parse(data?.location)["country"], disabled: ((data?.accountType === "individual" && data?.nationality === "rwandan") || (data?.accountType.toLowerCase() === "company" && data?.nationality === "national")) } : {
            value: (data?.accountType === "individual" && data?.nationality === "rwandan") || (data?.accountType.toLowerCase() === "company" && data?.nationality === "national") ? "Rwanda" : '',
            disabled: ((data?.accountType === "individual" && data?.nationality === "rwandan") || (data?.accountType === "company" && data?.nationality === "national"))
          },
          province: data?.location ? JSON.parse(data?.location)["province"] : '',
          district: data?.location ? JSON.parse(data?.location)["district"] : '',
          sector: data?.location ? JSON.parse(data?.location)["sector"] : '',
          cell: data?.location ? JSON.parse(data?.location)["cell"] : '',
          village: data?.location ? JSON.parse(data?.location)["village"] : ''
        }
      };

      const individualInitials = {
        ...initialValues.profile,
        passport: {
          number: data?.passportNo || '',
          file: {
            name: '',
            url: data?.passportFilePath || ''
          }
        }
      }
      const companyInitials = {
        ...initialValues.profile,
      }

      setInitialValues((prev: any) => {
        if (data?.accountType === "individual") return {
          ...initialValues,
          profile: { ...individualInitials },
          isBuilding: false
        }
        if (data?.accountType === "company") return {
          ...initialValues,
          profile: { ...companyInitials },
          isBuilding: false
        }
        return prev;
      })
    }
  }, [userState, lang])


  const { isLoading, mutateAsync } = useMutation((values: any) => {
    return editProfileAction(values);
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: (data: any) => {
      const { user: existing, ...rest } = userState;
      resetStateAction({ ...rest, loading: true })(dispatch);
      navigate('/overview');
    }
  })

  if (initialValues.isBuilding) {
    return (
      <div className="flex justify-center items-center h-full">
        <AppLoader />
      </div>
    )
  }

  return (
    <div className="w-full flex justify-center h-full">
      <div className="w-full xl:w-3/4 flex flex-col h-full">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {

            const { profileCompletion, profile, reverifyAccount, userType, verifyOptions, verifyWith, parentAccount, passport, nationality, ...rest } = values["profile"] as any;

            try {
              const obj = {
                location: (values["location"]["country"]["value"]?.length > 0) ? JSON.stringify(values["location"]) : null,
                ...rest,
                userType: values["profile"]["userType"]["type"],
                nationality: values["profile"]["nationality"]["value"],
                passportNo: values["profile"]?.["passport"]?.["number"],
                passportFilePath: values["profile"]?.["passport"]?.["file"]?.["url"]
              }
              await mutateAsync({ ...obj });
            } catch (error) {
              return error;
            }
          }}
        >
          {({ setFieldValue, values, setFieldError, setFieldTouched }) => {
            return (
              <Form className="flex flex-col h-full">
                <div className="flex flex-col md:flex-row md:justify-between mb-5 text-lg font-medium text-gray-500 md:space-x-2 items-center">
                  {
                    values["profile"]["profileCompletion"] !== 100 ?
                      <div className={`flex flex-col font-bold`}>
                        <span>{profileMessage.completion[lang]}: <strong className="text-xl">{`${values["profile"]["profileCompletion"]}%`}</strong></span>
                        <span className={`font-bold hidden md:block`}>{profileMessage.all[lang]} <span className="text-red-500">*</span> {profileMessage.mandatory[lang]}</span>
                      </div>
                      :
                      <Tooltip placement="bottom" title={<p className="text-sm">Verified</p>}>
                        <Verified className="text-green-300 cursor-pointer" fontSize="large" />
                      </Tooltip>

                  }

                  <button
                    className={cancelBtn}
                    onClick={() => handleEditable()}
                  >
                    {t('profile.fields.cancel')}
                  </button>
                </div>


                {values["profile"]["isSubscribed"] === 1 && <div className="mb-3">
                  <span className="font-semibold text-base">{handleMessage(values?.profile)}
                    <button
                      type="button"
                      className="underline text-blue-500 ml-1"
                      onClick={() => {
                        setOpenModal(true)
                      }}
                    >{lang === 'en' ? 'here' : lang === "fr" ? 'ici' : 'hano'}</button>
                    .
                  </span>
                </div>}

                <div className="rounded bg-white flex-grow flex flex-col text-gray-600">
                  <div className="flex justify-center items-center">
                    <ProfilePicture
                      props={{
                        lang,
                        setOpenSnack,
                        setSnackMessage
                      }}
                    />
                  </div>
                  <div className="flex-grow grid xl:grid-cols-2 gap-2 p-3">
                    <ProfileFields
                      values={values["profile"]}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      lang={lang}
                      setOpenSnack={setOpenSnack}
                      setSnackMessage={setSnackMessage}
                    />
                    <LocationDetails values={values["location"]} setFieldValue={setFieldValue} />
                  </div>
                  {<div className="flex justify-center items-center m-4">
                    <button
                      className="flex justify-center items-center buttonsColor w-full lg:w-1/3 px-4 py-2 rounded focus:outline-none outline-none text-white text-lg"
                      type="submit"
                    >
                      {
                        isLoading ? <ButtonLoader /> : t('profile.fields.save')
                      }

                    </button>
                  </div>}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      <ViewParentModal open={openModal} handleClose={() => setOpenModal(false)} data={initialValues?.profile} lang={lang} />
      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
    </div>
  );
};

export default ProfileDetails;
