import React, { ChangeEvent, FC, useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next';

import { ThreeDots } from 'react-loader-spinner'

import { Tooltip } from '@material-ui/core'
import { CloseOutlined, AttachFileOutlined } from '@material-ui/icons'
import { ButtonLoader } from '../../../Reusable'

import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { replyCommunicationAction } from '../../../../reduxes/communications'
import { addDocumentsAction } from '../../../../reduxes/uploads';
import { SnackMessage } from '../../../Reusable'
import { ErrorHandler } from 'utils/ErrorHandler';

import { SocketState } from "../../../../context";

type Props = {
  parentId: any,
  lang: string
}

const ReplyBox: FC<Props> = ({ parentId, lang }) => {

  const queryClient = useQueryClient();

  const { socket } = SocketState() as any;

  const [helperFunc, setHelperFunc] = useState<any>(null)
  const [fileError, setFileError] = useState('');

  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>(null)

  const { t } = useTranslation();

  const addDocumentMutation = useMutation((values: any) => {
    return addDocumentsAction(values);
  }, {
    retry: false
  })

  const handleAttachments = async (e: ChangeEvent<HTMLInputElement>, helpers: any) => {
    const target = e.target as HTMLInputElement;
    const files: any = target.files!

    const { push } = helpers;

    const maxSize = 100;
    const accept = [
      '.png',
      '.jpeg',
      '.jpg',
      '.pdf',
      '.doc',
      '.docx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'text/plain'
    ];

    const sizeMessage = {
      en: `Allowed file size is ${maxSize} mb`,
      fr: `La taille de fichier autorisée est ${maxSize} mb`,
      rw: `Ingano ya dosiye yemewe ni ${maxSize} mb`
    }

    const typeMessage = {
      en: `Allowed file type (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`,
      fr: `Type de fichier autorisé (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`,
      rw: `Ubwoko bwemewe (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`
    }

    const checkSize = Array.from(files).filter((file: any) => file.size > (maxSize * 1000000));
    const checkType = Array.from(files).filter((file: any) => {
      return !accept.filter((v: any) => v === file.type || v.split(".").pop() === file.name.split(".").pop()).length
    });

    setFileError('');

    setHelperFunc({
      push,
    });

    try {
        if (checkSize.length) {
            setFileError(sizeMessage[lang])
        }

        if (checkType.length) {
            setFileError(typeMessage[lang])
        }

        await addDocumentMutation?.mutateAsync(files);

    } catch (error) {
        return error;
    }

  }

  const { isLoading, mutateAsync } = useMutation((values: any) => {
    return replyCommunicationAction(values, parentId);
  }, {
    retry: false,
    onError: (error: any) => {
      const data = ErrorHandler(error, 'File server');
      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message: data?.message
      })
    },
    onSuccess: (data: any) => {
      const response = data?.data;
      socket.emit("reply message", response?.data);
      queryClient.invalidateQueries({ queryKey: ['parent messages']})
    }
  })


  useEffect(() => {
    if (addDocumentMutation?.data) {
        const { data: res, status } = addDocumentMutation?.data as any;

        if (status === 201) {
            for (const item of res?.data) {
                helperFunc?.push({
                    name: item.name,
                    link: item.url
                })
            }
        } else {
            const response = ErrorHandler(addDocumentMutation?.data, 'File server');
            setFileError(response["message"]);
        }
    }
}, [helperFunc, addDocumentMutation?.data])

  return (
    <Formik
      initialValues={{
        message: "",
        attachments: []
      }}
      validationSchema={Yup.object().shape({
        message: Yup.string().required('Enter the reply message'),
        attachments: Yup.array().nullable(),
      })}
      onSubmit={async (values, actions) => {
        try {
          await mutateAsync(values);
          actions.resetForm()
        } catch (error) {
          return error;
        }

      }}
    >
      {({ values }) => {
        return (
          <Form>
            <FieldArray
              name="attachments"
              render={({ push, remove }) => {
                return (
                  <>
                    <div className='mb-3 flex space-x-2 overflow-x-auto w-full'>
                      {
                        (addDocumentMutation?.isError || addDocumentMutation?.isLoading || fileError?.length > 0) ?
                          <div className='flex justify-center items-center w-full'>
                            {
                              addDocumentMutation?.isLoading ?
                                <div className='flex items-center'>
                                  <span className='text-sm'>Uploading files</span>
                                  <span><ThreeDots color="#b48523" height={15} width={15} /> </span>
                                </div>
                                :
                                <span className='text-red-500 text-sm'>{fileError}</span>
                            }
                          </div>
                          :
                          values["attachments"].map((att: any, ind: number) => {
                            return (
                              <div className='flex justify-between bg-gray-200 rounded p-3 items-center' key={ind}>

                                <Tooltip disableFocusListener disableTouchListener title={
                                  <p className="text-sm">{att.name}</p>
                                }>

                                  <span className="w-32 truncate">
                                    {att.name}
                                  </span>

                                </Tooltip>
                                <div className='flex space-x-2 text-gray-600 text-xs'>
                                  {/* <span className='cursor-pointer' onClick={() => handleOpen(att)}>
                                    <VisibilityOutlined />
                                  </span> */}

                                  <span className='cursor-pointer' onClick={() => remove(ind)}>
                                    <CloseOutlined />
                                  </span>
                                </div>
                              </div>
                            )
                          })

                      }
                    </div>

                    <div className="flex space-x-2">
                      <div className="flex-grow relative">

                        <Field as="textarea" name="message" rows="2" className=" bg-gray-200 w-full rounded px-2 focus:outline-none text-gray-600 h-12" placeholder={t('communication.newMessage.message')} />

                        <label className="cursor-pointer absolute right-0" htmlFor="attach">
                          <Tooltip disableFocusListener disableTouchListener title={
                            <p className="text-sm">{t('communication.newMessage.attach')}</p>
                          }>

                            <span className="absolute right-0 top-3 text-gray-600 cursor-pointer">
                              <AttachFileOutlined />
                            </span>

                          </Tooltip>

                          <input
                            className="z-0 w-full h-10 px-3 mt-2 text-xs text-gray-500 bg-black border rounded outline-none hidden"
                            type="file"
                            id="attach"
                            name="attach"
                            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, text/plain"
                            multiple
                            onChange={(e) => handleAttachments(e, {
                              push,
                            })}
                          />
                        </label>


                      </div>

                      <button
                        type="submit"
                        className="buttonsColor flex justify-center items-center rounded text-white w-28"
                      >
                        {
                          isLoading ?
                            <ButtonLoader />
                            :
                            t('communication.buttons.reply')
                        }
                      </button>
                    </div>
                    <ErrorMessage name="message" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />

                  </>
                )
              }}
            />

            {openSnack && <SnackMessage open={openSnack} handleCloseSnack={() => setOpenSnack(false)} successMessage={snackMessage} />}

          </Form>
        )
      }}
    </Formik>
  )
}

export default ReplyBox