import React, { ReactElement, FC, useEffect, useState, useCallback } from "react";
import { useQueryClient } from 'react-query'
import { io, Socket } from 'socket.io-client';
import "./Layout.scss";
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import { getLoggedInUserAction, UserTypes } from "../../../reduxes/user";

import { SocketContext, useIdleTimeout } from '../../../context'

import { NavBar, SnackMessage, Footer, AppLoader, Error, IdleModal } from '../../Reusable'

const socket_url: string = process.env.REACT_APP_SOCKET_URL as string

interface Props {
  children: ReactElement;
}


const Layout: FC<Props> = ({ children }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const access_token = localStorage.getItem('app_access_token');

  const [open, setOpen] = useState(false)
  const [openIdleModal, setOpenIdleModal] = useState(false);

  const [snackMessage, setSnackMessage] = useState<any>(null)
  const [socket, setSocket] = useState<any>(null);

  const handleCloseSnack = () => setOpen(false);

  const handleIdle = () => setOpenIdleModal(true);

  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle })

  const userState = useSelector((state: any) => state.users);
  const communicationState = useSelector((state: any) => state?.communication);

  const handleNotificationCount = useCallback((action: string, state: any) => {
    const sendData = { ...state, data: { ...state.data, notificationsCount: action === "remove" ? state.data.notificationsCount - 1 : state.data.notificationsCount + 1 } }
    dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: sendData } });
  }, [dispatch])

  const handleCommunicationCount = useCallback(async (state: any, action: string, count: number) => {
    const sendData = {
      ...state,
      data: {
        ...state.data,
        communicationsCount: action === "remove" ? state.data.communicationsCount - count : state.data.communicationsCount + count
      }
    }
    dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: sendData } });
  }, [dispatch])

  useEffect(() => {
    const newSocket: Socket = io(socket_url);
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    }
  }, [])

  useEffect(() => {
    if (socket === null) return;
    socket.emit('setup', userState?.user?.data);

    socket?.on("reply received" || "message received", () => {
      
      if (communicationState?.parent?.id) {
        queryClient.invalidateQueries({ queryKey: ['parent messages'] })
      }

      if (!communicationState?.parent && userState?.user) {
        handleCommunicationCount(userState?.user, 'add', 1);
        queryClient.invalidateQueries({ queryKey: ['all parents'] })
      }

      setOpen(true);
      setSnackMessage({
        error: false,
        message: "New message received"
      })
    })

    socket?.on("notification received", () => {
      if (userState?.user) {
        handleNotificationCount('add', userState?.user);
      }

      queryClient.invalidateQueries({ queryKey: ['filter user notifications'] })

      setOpen(true);
      setSnackMessage({
        error: false,
        message: "New notification received"
      })

    })

  }, [socket, userState, communicationState, queryClient, handleCommunicationCount, handleNotificationCount])

  useEffect(() => {
    if (!access_token) {

      dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: null } });

      navigate('/login', {
        state: {
          from: `${location?.pathname}${location?.search}`
        }
      })
    }
  }, [access_token, dispatch, location, navigate])

  const { isLoading, isError, error, refetch } = useQuery(['user', access_token], () => {
    return getLoggedInUserAction();
  }, {
    retry: false,
    enabled: !userState?.user,
    onError: (error: any) => {
      return error;
    },
    onSuccess: (data: any) => {
      const response = data?.data;
      const dispatchData = {
        ...response,
        data: {
          ...response?.data,
          profileURL: {
            url: '',
            isLoading: false
          }
        }
      }

      dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: dispatchData } });
    },
  })

  if (isLoading || !socket) {
    return (
      <div className="flex justify-center items-center mainBackground h-screen">
        <AppLoader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center mainBackground h-screen">
        <Error error={error} action={() => refetch()} />
      </div>
    )
  }

  return (
    <SocketContext.Provider value={{
      socket,
      user: userState?.user,
      handleNotificationCount,
      handleCommunicationCount
    }}>
      <div className="flex flex-col mainBackground font-body h-screen">
        <NavBar />
        <main className="flex-grow md:p-8 p-3 mainBackground">
          {children}
        </main>
        <Footer />
        {open && <SnackMessage
          open={open}
          handleCloseSnack={handleCloseSnack}
          successMessage={snackMessage}
        />}

        {openIdleModal && <IdleModal
          open={openIdleModal}
          handleClose={() => setOpenIdleModal(false)}
          idleTimer={idleTimer}
        />}
      </div>
    </SocketContext.Provider>
  )
}

export default Layout;
