export enum NotificationTypes {
  GetNotifications = 'NotificationTypes/GetNotifications',
  UpdateNotification = 'NotificationTypes/UpdateNotification',
  SocketNotification = 'NotificationTypes/SocketNotification',
  ResetPayload = 'NotificationTypes/ResetPayload',
  Loading = 'NotificationTypes/Loading',
  Errors = 'NotificationTypes/Errors'
}

interface GetNotifications {
  type: typeof NotificationTypes.GetNotifications;
  payload: { notifications: any };
}

interface SocketNotification {
  type: typeof NotificationTypes.SocketNotification;
  payload: {
    data: any
  };
}

interface ResetPayload {
  type: typeof NotificationTypes.ResetPayload;
  payload: {
    data: any
  };
}

interface Loading {
  type: typeof NotificationTypes.Loading;
  payload: {
    loading: boolean;
  };
}

interface Errors {
  type: typeof NotificationTypes.Errors;
  payload: {
    errors: any;
  };
}

export type UserTypeAction =
  | GetNotifications
  | SocketNotification
  | ResetPayload
  | Loading
  | Errors
