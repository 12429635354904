import React, { FC, useState } from 'react'
import { useMutation } from 'react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { fetchExternalReports } from 'reduxes/applications'

import { useTranslation } from 'react-i18next';
import { ButtonLoader } from 'components/Reusable'

type Props = {
    lang: string,
    setFieldValue: any,
    fieldName: string,
    fetchFrom: string,
    fieldValue: any,
    setOpenModal: any
}

const OtherReport: FC<Props> = ({ setFieldValue, fetchFrom, fieldValue, setOpenModal }) => {

    const [openAlert, setOpenAlert] = useState(false)
    const [snackMessage, setSnackMessage] = useState<any>(null);

    const { t } = useTranslation();

    const handleFetchReport = (val: any) => {
        setOpenAlert(false);
        return fetchExternalReports(val, fetchFrom.toLowerCase());
    }

    const handleCloseModal = () => {
        setOpenAlert(false)
        setOpenModal(false)
    }

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return handleFetchReport(values);
    }, {
        retry: false,
        onSettled: (data: any, error: any) => {
            setOpenAlert(true)
            setSnackMessage({
                error: error ? true : false,
                message: error ? error?.response?.data?.message : `${fetchFrom} Report is attached successfully.`
            })

            if (data) {
                const response = data?.data;
                (fieldValue.length > 0) ? setFieldValue.replace(0, response.data) : setFieldValue.push(response.data)
            }

            setTimeout(() => {
                setOpenAlert(false)
                setSnackMessage(null);
                setOpenModal(false);
            }, 4000)


        }
    })

    return (
        <Formik
            initialValues={{
                tinNo: "",
            }}
            validationSchema={Yup.object().shape({
                tinNo: Yup.number().typeError('you must provide only numbers')
                    .test('len', 'should not be greater than 9 numbers', (val: any) => {
                        const checkLength = val && val.toString().length <= 9;
                        return checkLength;
                    })
                    .required('Enter the tin number'),
            })}
            onSubmit={async (values) => {
                const sendVal = {
                    tin: values?.tinNo
                }
                try {
                    await mutateAsync(sendVal)
                } catch (error) {
                    return error;
                }
            }}
        >
            {({ values, errors }) => {
                return (
                    <Form className='bg-white w-3/4 xl:w-1/4 h-auto rounded flex flex-col p-2'>
                        {openAlert && <span className={`w-full mb-3 flex justify-center items-center p-3 rounded text-white ${snackMessage?.error ? 'bg-red-500' : 'bg-green-700'}`}>{snackMessage?.message}</span>}

                        <div className='flex-grow mb-3'>

                            <div className='flex flex-col mb-3'>
                                <label className='mb-1 font-bold text-sm flex'>{`${fetchFrom} Tin number`}<span className="text-red-500">*</span></label>
                                <Field
                                    className="w-full h-12 px-3 text-xs text-gray-500 bg-white border rounded outline-none"
                                    name="tinNo"
                                    placeholder={`Enter the ${fetchFrom} tin number`}
                                />

                                <ErrorMessage name="tinNo" render={msg => < div style={{ color: 'red' }} > {msg}</div >} />
                            </div>

                        </div>

                        <div className='grid md:grid-cols-2 gap-2'>
                            <button
                                className='w-full buttonsColor flex justify-center p-3 text-white rounded'
                                type='submit'
                            >
                                {
                                    isLoading ?
                                        <ButtonLoader />
                                        :
                                        t('service.application.buttons.fetch')

                                }
                            </button>

                            <button
                                className='w-full border text-gray-600 border-gray-300 flex justify-center p-3 rounded'
                                type='button'
                                onClick={() => handleCloseModal()}
                            >
                                {t('service.application.buttons.close')}
                            </button>
                        </div>
                    </Form>
                )
            }}

        </Formik>
    )
}

export default OtherReport
