import { FC } from 'react';

import { FormDetails, Fetches, Uploads, Additionals, Notification } from './index'

type Props = {
    stepName: string,
    json: any,
    values: any,
    lang: string,
    formik: any,
    user: any
}

const GetStepContent: FC<Props> = ({ stepName, json, values, lang, formik, user }) => {

    switch (stepName) {
        case "form_details":
            return (
                <FormDetails schema={json["form_details"]} lang={lang} values={values} formik={formik} user={user} />
            )

        case "uploads":
            return (
                <Uploads schema={json["uploads"]} values={values} lang={lang} formik={formik} user={user} />
            )

        case "additionals":
            return (
                <Additionals
                    schema={json["additionals"]}
                    values={values}
                    lang={lang}
                    user={user}
                />
            )

        case "fetches":
            return (
                <Fetches schema={json["fetches"]} lang={lang} values={values} formik={formik} user={user} />
            )

        case "notification":
            return (
                <Notification values={values} formik={formik} lang={lang} />
            )

        default:
            return null
    }
}

export default GetStepContent;
