import { Routes } from "utils";

import {
  Login,
  ForgotPassword,
  Signup,
  VerifyMail,
  ResetPassword,
  Associate
} from "components/Authentication";

import { Events } from 'components/Events';


export const unauthorizedRoutes = [
  {
    component: <Login />,
    path: Routes.Login,
  },
  {
    component: <ForgotPassword />,
    path: Routes.ForgotPassword,
  },
  {
    component: <Signup />,
    path: Routes.Signup,
  },
  {
    component: <VerifyMail />,
    path: Routes.VerifyMail,
  },
  {
    component: <ResetPassword />,
    path: Routes.ResetPassword,
  },
  {
    component: <Events />,
    path: Routes.events,
  },
  {
    component: <Associate />,
    path: Routes?.Associate
  }
];
