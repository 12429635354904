import { GetField, LocationField, FileField, FetchField, PhoneField, IdentificationType } from './Elements'

export const getFormElement = (elementSchema: any, lang: string, schemaName: string, formValues: any, user: any) => {

    const { inputType } = elementSchema["schema"]
    const { index } = elementSchema;

    const props = { ...elementSchema, lang, schemaName, formValues };
    props.hide = true;

    const getValueInModal = (name: string, values: any) => {
        for (const item in values) {
            if (values[item]["name"]["en"] === name) return values[item]["inputValue"]
        }
    }

    if (inputType === 'address') {
        return <LocationField key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
    }

    if (inputType === "phone") {
        return <PhoneField key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
    }

    if (inputType === "identification") {
        return <IdentificationType key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
    }

    if (inputType === "fetch") {
        const countryValue = getValueInModal("Country", formValues.modalValues);

        const { resultType } = props.schema;

        if (props.inModal && countryValue && countryValue?.length === 0) {
            return <FetchField key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
        } else if ((props.inModal && countryValue && countryValue !== "Rwanda") || (props?.fetchFrom === "nppa") || (resultType?.toLowerCase() === 'upload')) {
            return <FileField key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
        } else {
            return <FetchField key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
        }
    }

    if (inputType === "file") {
        return <FileField key={index} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
    }

    return (
        <GetField inputType={inputType} props={{ ...props, formValues: props.inModal ? { ...formValues.modalValues } : formValues }} />
    )

}
