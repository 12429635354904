import React, { useState, FC } from "react";
import { useMutation } from 'react-query'
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { useTranslation } from 'react-i18next';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import { Modal, Backdrop } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Alert } from '@mui/material'

import { verifyPhoneNumberAction, getOTPAction } from "../../../../reduxes/user"
import { ButtonLoader } from "../../../Reusable";
import { ErrorHandler } from "utils";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required('Enter the phone number'),
  otpNumber: Yup.number().typeError('must only be numbers').required('Enter the OTP sent to your phone')
})

type Props = {
  open: boolean,
  handleClose: any,
  existingValues: any,
  setFieldValue: any
}

const VerifyPhone: FC<Props> = ({ open, handleClose, existingValues, setFieldValue }) => {

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%'
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: "50vh"
      },
    }),
  );

  const classes = useStyles();

  const { t } = useTranslation();

  const [openAlert, setOpenAlert] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>(null)

  const handleGetOTP = (phonenumber: any, setFieldError: any) => {
    let error = false;
    const phoneNumber = parsePhoneNumber(phonenumber)
    const num = phoneNumber?.number as string
    const CD = phoneNumber?.country
    if (!isValidPhoneNumber(num, CD)) {
      setFieldError("phoneNumber", 'phone number is invalid')
      error = true;
    }
    return error;
  }

  const handleCloseModal = () => {
    setOpenAlert(false);
    handleClose()
  }

  const getOTPMutation = useMutation(async (values: any) => {
    return await getOTPAction(values);
  }, {
    retry: false,
    onMutate: () => {
      setOpenAlert(false)
    },
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenAlert(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: () => {
      setOpenAlert(true);
      setSnackMessage({
        error: false,
        message: "We've sent you an OTP to use, please insert the number you received in the below field."
      })
    }
  })

  const { isLoading, mutateAsync } = useMutation(async (values: any) => {
    return await verifyPhoneNumberAction(values);
  }, {
    retry: false,
    onMutate: () => {
      setOpenAlert(false)
    },
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenAlert(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: (data: any) => {
      const response = data?.data;
      setOpenAlert(true);
      setSnackMessage({
        error: false,
        message: response?.message
      })

      setFieldValue("profile.profileCompletion", existingValues["profileCompletion"] < 100 ? existingValues["profileCompletion"] + 25 : existingValues["profileCompletion"])
      setFieldValue("profile.isPhoneVerified", 1)

      setTimeout(() => {
        return handleClose()
      }, 1500)
    }
  })

  return (

    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={(_, reason: any) => {
        handleCloseModal()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="w-11/12 p-4 bg-white rounded outline-none md:h-auto md:w-1/2 lg:w-1/4 h-auto flex flex-col">
        <div className="flex flex-row items-center justify-between mb-5 text-gray-600 text-xl">
          <p className="font-medium">Verify your phone</p>
        </div>

        {openAlert && <Alert
          severity={snackMessage.error ? 'error' : 'success'}
          variant='filled'
          className="mb-3"
        >{snackMessage.message}</Alert>}

        <Formik
          initialValues={{
            phoneNumber: existingValues["phoneNumber"],
            otpNumber: ""
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            if (openAlert) setOpenAlert(false)
            try {
              await mutateAsync({ ...values })
            } catch (error) {
              return error
            }
          }}
        >
          {({ values, setFieldError }) => {

            return (
              <Form autoComplete="off">
                <div className="flex flex-col mb-3">
                  <label className="text-sm font-medium text-gray-500">
                    {t('login.phone_label')}
                  </label>

                  <div className="flex h-12 text-sm">
                    <Field
                      className="border px-3 rounded-l flex-grow"
                      name="phoneNumber"
                      readOnly
                      placeholder={t('login.phone_placeholder')}
                    />

                    <button
                      type="button"
                      className=" text-white buttonsColor rounded-r w-20 flex justify-center items-center"
                      onClick={async () => {
                        const res = handleGetOTP(values.phoneNumber, setFieldError)
                        if (!res) {
                          try {
                            return await getOTPMutation.mutateAsync({ phoneNumber: values?.phoneNumber.replace(/\s/g, ''), verify: false });
                          } catch (error) {
                            return error;
                          }
                        }
                      }}
                    >
                      {getOTPMutation?.isLoading ? <ButtonLoader /> : t('login.get_otp')}
                    </button>

                  </div>


                  <ErrorMessage name="phoneNumber" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                </div>

                <div className="flex flex-col mb-5">
                  <label className="text-sm font-medium text-gray-500">
                    OTP Number
                  </label>
                  <Field
                    className="w-full h-12 px-3 mt-2 text-sm text-gray-500 bg-white border rounded outline-none"
                    name="otpNumber"
                    placeholder="Enter the OTP sent to your phone"
                  />

                  <ErrorMessage name="otpNumber" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                </div>

                <div className="grid grid-cols-2 gap-3">
                  <button
                    className="flex items-center justify-center w-full p-3 font-semibold text-white rounded shadow buttonsColor focus:outline-none"
                    type="submit"
                  >

                    {
                      isLoading ?
                        <ButtonLoader />
                        :
                        "Verify"
                    }

                  </button>

                  <button
                    className="flex items-center justify-center w-full p-3 border border-black rounded text-black focus:outline-none"
                    type="button"
                    onClick={() => {
                      setOpenAlert(false);
                      handleClose()
                    }}
                  >
                    Cancel
                  </button>
                </div>

              </Form>
            );
          }}
        </Formik>


      </div>
    </Modal>

  );
};

export default VerifyPhone;
