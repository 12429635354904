import { CommunicationTypes } from ".";

const initialState: any = {};

export const communicationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CommunicationTypes.GetAllDepartments:
            return { ...state, departments: action.payload.data, loading: false };

        case CommunicationTypes.SendCommunication:
            return { ...state, sentMessage: action.payload.data }

        case CommunicationTypes.ReplyCommunication:
            return { ...state, replyMessage: action.payload.data, loading: 'replying' }

        case CommunicationTypes.UploadAttachments:
            return { ...state, uploads: [...state.uploads!, ...action.payload.data], loading: false }

        case CommunicationTypes.GetParents:
            return { ...state, parents: action.payload.data, loading: 'get parents' }

        case CommunicationTypes.OpenParent:
            return { ...state, parent: action.payload.data, loading: 'get messages' }

        case CommunicationTypes.GetParentMessages:
            return { ...state, messages: action.payload.data, loading: 'get messages' }

        case CommunicationTypes.ContactUs:
            return { ...state, contactUs: action.payload.data, loading: false }

        case CommunicationTypes.UpdateReadStatus:
            return { ...state, updateStatus: action.payload.data, loading: false }

        case CommunicationTypes.GetUnreadCount:
            return { ...state, unreadCount: action.payload.data, loading: false }

        case CommunicationTypes.SocketCommunication:
            return { ...state, socketReply: action.payload.data.message, loading: action?.payload?.data?.loading }

        case CommunicationTypes.ResetPayload:
            return { ...action.payload.data };

        case CommunicationTypes.Loading:
            return { ...state, loading: action.payload.data };

        case CommunicationTypes.Errors:
            return { ...state, errors: action.payload.errors };
        default:
            return state;
    }
};
