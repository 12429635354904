import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
export const useIdleTimeout = ({ onIdle }: any) => {

    const idleTime = Number(process.env.REACT_APP_IDLE_TIME_IN_SECONDS);
    const idleTimeout = 1000 * idleTime;

    const [isIdle, setIdle] = useState(false);

    const handleIdle = () => {
        setIdle(true)
        localStorage.removeItem("app_access_token");
        localStorage.removeItem("ttlnt.refresh");
        localStorage.removeItem("app_refresh_token");  
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: idleTimeout / 2,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500
    })

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
