import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Signup.scss";
import { useMutation, useQuery } from 'react-query';

import { Formik, Form, Field } from "formik";
import { useCookies } from "react-cookie";
import { useTranslation } from 'react-i18next';


import SignUpFields from "./Fields";
import { validationSchema, initialValues } from "./schema";
import { LoginInformation, registerAction } from "../../../reduxes/user";

import { readSingleFileAction } from '../../../reduxes/general'

import { SnackMessage, ButtonLoader, ViewModal } from '../../Reusable'
import { ErrorHandler } from "utils";

const Signup: FC = () => {
  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openSnack, setOpenSnack] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(null)
  const [selectNationality, setSelectNationality] = useState<any>(null)
  const [snackMessage, setSnackMessage] = useState<any>(null);

  const [openModal, setOpenModal] = useState<{ open: boolean, file: any }>({
    open: false,
    file: null
  })

  const handleCloseSnack = () => setOpenSnack(false)
  const handleCloseModal = () => setOpenModal({
    open: false,
    file: null
  });

  useQuery('read pricacy policy', async () => {
    return await readSingleFileAction(openModal?.file?.name);
  }, {
    retry: false,
    keepPreviousData: false,
    enabled: openModal?.file?.name ? true : false,
    onSettled: (data, error) => {
      if (error) {
        const { message } = ErrorHandler(error, 'server');
        setOpenSnack(true);
        setSnackMessage({
          error: true,
          message
        })
      }

      const { link } = data?.data?.data;

      setOpenModal((prev) => {
        return {
          open: true,
          file: {
            name: prev?.file?.name,
            link
          }
        }
      })
    }
  })

  const handleChecked = (props: any) => {
    const { setFieldValue, resetForm, type } = props;
    resetForm();
    setFieldValue('accountType', type);
    setSelectedValue(null);
    setSelectNationality(null)
  };

  const handleViewFile = () => {
    setOpenModal((prev) => {
      return {
        ...prev,
        file: {
          name: 'privacy policy',
          link: null
        }
      }
    })
  }


  const { isLoading, mutateAsync } = useMutation(async (values: LoginInformation) => {
    return await registerAction(values);
  }, {
    retry: false,
    onSettled: (data, error) => {
      let message = data?.data?.message;
      if (error) {
        const { message: res } = ErrorHandler(error, 'server');
        message = res;
      }

      setOpenSnack(true);
      setSnackMessage({
        error: error ? true : false,
        message
      })

      if (data) {
        setTimeout(() => navigate("/login"), 3000)
        localStorage.removeItem('app_access_token');
        localStorage.removeItem('app_refresh_token');
      }

    }
  })

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col w-full md:w-1/2 xl:w-5/12 my-5">
        <div className="flex flex-row items-center justify-between mb-8 text-xl text-gray-500">
          <p className="mr-4 text-sm font-medium md:text-xl">
            {t('signup.title')}
          </p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            try {
              await mutateAsync({ ...values });
              actions.resetForm()
            } catch (error) {
              return error;
            }
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, resetForm }) => {
            return (
              <Form autoComplete="off" className="flex flex-col">

                <div className="flex justify-between mb-5 font-medium text-gray-500 md:justify-between">
                  <label htmlFor="company" className="flex justify-center items-center cursor-pointer" onChange={() => handleChecked({
                    setFieldValue,
                    resetForm,
                    type: "company"
                  })}>
                    <Field type="radio" name="accountType" value="company" id="company" className="hidden" checked={values["accountType"] === "company"} />
                    <span className={`inline-block w-5 h-5 mr-2 border border-gray-900 rounded-full ${values["accountType"] === "company" && 'buttonsColor'}`}></span>
                    {t('signup.company')}
                  </label>

                  <label htmlFor="individual" className="flex justify-center items-center cursor-pointer" onChange={() => handleChecked({
                    setFieldValue,
                    resetForm,
                    type: "individual"
                  })}>
                    <Field type="radio" name="accountType" value="individual" id="individual" className="hidden" checked={values["accountType"] === "individual"} />
                    <span className={`inline-block w-5 h-5 mr-2 border border-gray-900 rounded-full ${values["accountType"] === "individual" && 'buttonsColor'}`}></span>
                    {t('signup.individual')}
                  </label>
                </div>

                <div className="flex flex-col pt-5 font-medium text-gray-500">
                  <SignUpFields
                    values={values}
                    setFieldValue={setFieldValue}
                    lang={lang}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    selectNationality={selectNationality}
                    setSelectNationality={setSelectNationality}
                  />
                  <button
                    className="flex items-center justify-center w-full h-12 px-1 py-1 pt-2 font-semibold text-white rounded shadow buttonsColor"
                    type="submit"
                  >
                    {isLoading ? (
                      <ButtonLoader />
                    ) : (
                      t('navbar.sign_up')
                    )}
                  </button>

                  <div className="flex justify-between mt-4 text-gray-500 text-sm">
                    <p>
                      {t('signup.have_an_account')}{" "}
                      <span className="font-semibold cursor-pointer textColor">
                        <Link to="/login">{t('signup.sign_in_here')}</Link>
                      </span>
                    </p>
                  </div>

                  <div className="mt-4 font-medium text-center text-gray-500 text-sm">
                    <p>
                      {t('signup.by_creating')}{" "}
                      <span className="cursor-pointer textColor" onClick={() => handleViewFile()}>
                        {t('policies.privacy_policy')}
                      </span>
                    </p>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
      {openModal?.open && <ViewModal handleClose={handleCloseModal} open={openModal} />}

    </div>
  );
};

export default Signup;
