import React, { useState } from "react";

import { useMutation } from "react-query";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ErrorHandler, Routes } from "../../../utils";
import { LoginInformation, getOTPAction, loginAction, resendEmailAction } from "../../../reduxes/user";
import { useTranslation } from 'react-i18next';
import { SnackMessage, ButtonLoader } from '../../Reusable'
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput from "react-phone-input-2";

import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { loginSchema } from './Schema'
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>(null);

  const handleCloseSnack = () => setOpenSnack(false)

  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => setShowPassword(!showPassword)

  const handleValidateOTP = (phonenumber: any, props: any) => {
    const { setFieldError, setFieldTouched } = props;

    let error = false;
    setFieldTouched("username", true, false);

    if (phonenumber?.length === 0) {
      error = true;
      setFieldError("username", 'phone number is required')
      return error;
    }

    const phoneNumber = parsePhoneNumber(phonenumber)
    const num = phoneNumber?.number as string
    const CD = phoneNumber?.country

    if (CD?.toLowerCase() !== "rw") {
      error = true;
      setFieldError("username", 'we can not send the OTP to an international number as of now.')
      return error;
    }

    if (!isValidPhoneNumber(num, CD)) {
      error = true;
      setFieldError("username", 'phone number is invalid')
      return error;
    }
    setFieldError("username", '')
    return error;
  }

  const handleChecked = (props: any) => {
    const { setFieldValue, resetForm, type } = props;
    resetForm();
    setFieldValue('type', type);
  };

  const getOTPMutation = useMutation(async (values: any) => {
    return await getOTPAction(values);
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: () => {
      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: "We've sent you an OTP to use, please insert the number you received below in the password field."
      })
    }
  })

  const resendEmailMutation = useMutation(async (values) => {
    return await resendEmailAction(values);
  }, {
    retry: false,
    onMutate: () => {
      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: 'Resending the email....'
      })
    },
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: () => {
      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: "We've sent you an email, please check it and activate your account."
      })
    }
  })

  const handleResendEmail = async (values: any) => {
    try {
      await resendEmailMutation.mutateAsync(values);
    } catch (error) {
      return error;
    }
  }

  const handleResetPassword = () => navigate('/forgot-password');

  const { isLoading, mutateAsync } = useMutation((values: LoginInformation) => {
    return loginAction(values);
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      let customMessage = message;
      const email = error?.response?.data?.data?.email

      if (message.toLowerCase() === 'your email has not been verified yet.') customMessage = <span>
        {message}{" "}
        <button className=" underline" onClick={() => handleResendEmail({
          username: email
        })}>Click here to resend the email verification link</button>
      </span>

      if (message.toLowerCase() === 'your password has expired,') customMessage = <span>
        {message}{" "}
        <button className=" underline" onClick={() => handleResetPassword()}>Click here to reset it</button>
      </span>

      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message: customMessage
      })
    },
    onSuccess: (data: any) => {
      const response = data?.data;
      localStorage.setItem("app_access_token", response?.data.token);
      localStorage.setItem("app_refresh_token", response?.data.refreshToken);
      navigate(location?.state?.from ? location?.state?.from : '/overview');
    }
  })

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col w-full md:w-1/2 lg:max-w-md">
        <div className="flex flex-row items-center justify-between mb-5 text-xl text-gray-500">
          <p className="mr-4 text-sm font-medium md:text-xl">
            {t('login.title')}
          </p>
        </div>

        <Formik
          initialValues={{
            type: "email",
            username: "",
            password: ""
          }}
          validationSchema={loginSchema}
          onSubmit={async (values) => {

            try {
              await mutateAsync(values);
            } catch (error) {
              return error;
            }
          }}
        >
          {({ values, setFieldValue, setFieldError, resetForm, setFieldTouched }) => {
            return (
              <Form className="flex flex-col">
                <div className="flex justify-between mb-5 font-medium text-gray-500 md:justify-between">
                  <label htmlFor="email" className="flex justify-center items-center cursor-pointer" onChange={() => handleChecked({
                    setFieldValue,
                    resetForm,
                    type: "email"
                  })}>
                    <Field type="radio" name="type" value="email" id="email" className="hidden" checked={values["type"] === "email"} />
                    <span className={`inline-block w-5 h-5 mr-2 border border-gray-900 rounded-full ${values["type"] === "email" && 'buttonsColor'}`}></span>
                    {t('login.password')}
                  </label>

                  <label htmlFor="phone" className="flex justify-center items-center cursor-pointer" onChange={() => handleChecked({
                    setFieldValue,
                    resetForm,
                    type: "phone"
                  })}>
                    <Field type="radio" name="type" value="phone" id="phone" className="hidden" checked={values["type"] === "phone"} />
                    <span className={`inline-block w-5 h-5 mr-2 border border-gray-900 rounded-full ${values["type"] === "phone" && 'buttonsColor'}`}></span>
                    {t('login.otp')}
                  </label>
                </div>

                <div className="flex flex-col mb-5">
                  <label className="text-sm font-medium text-gray-500">
                    {values["type"] === "email" ? t('login.email_label') : t('login.phone_label')}
                  </label>
                  {
                    values["type"] === "email" ?
                      <Field
                        className="w-full h-12 px-3 mt-2 text-sm text-gray-500 bg-white border-0 rounded outline-none"
                        name="username"
                        placeholder={t('login.email_placeholder')}
                      />
                      :
                      <div className="flex h-12 mt-2 text-sm text-gray-600">
                        <PhoneInput
                          inputClass="px-3 text-sm text-gray-500 bg-white border-0 rounded outline-none"
                          country="rw"
                          value={values.username}
                          placeholder={t('login.phone_placeholder') as string}
                          onChange={(val) => setFieldValue("username", `+${val}`, false)}
                        />

                        <button
                          type="button"
                          className="text-sm text-white buttonsColor rounded-r cursor-pointer flex justify-center items-center px-3 w-1/4"
                          onClick={async () => {

                            try {
                              const res = handleValidateOTP(values.username, { setFieldError, setFieldTouched })

                              if (!res) {
                                setFieldError('username', '');
                                const obj = { phoneNumber: values?.username.replace(/\s/g, ''), verify: false }
                                await getOTPMutation?.mutateAsync(obj);
                              }

                            } catch (error) {
                              return error;
                            }


                          }}
                        >
                          {
                            getOTPMutation?.isLoading ? <ButtonLoader /> : t('login.get_otp')
                          }

                        </button>


                      </div>
                  }

                  <ErrorMessage name="username" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                </div>

                <div className="flex flex-col mb-5">
                  <label className="text-sm font-medium text-gray-500">
                    {values["type"] === "email" ? t('login.password_label') : t('login.otp_label')}
                  </label>

                  <div className="flex h-12 bg-white mt-2">
                    <Field
                      className="px-3 text-sm text-gray-500 bg-white border-0 rounded outline-none flex-grow"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder={t('login.password_placeholder')}
                    />

                    <span
                      className="flex justify-center items-center h-full p-3 rounded-r cursor-pointer text-gray-600"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </span>


                  </div>


                  <ErrorMessage name="password" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                </div>

                <button
                  className="flex items-center justify-center w-full p-3 font-semibold text-white rounded shadow buttonsColor focus:outline-none"
                  type="submit"
                >
                  {isLoading ? (
                    <ButtonLoader />
                  ) : (
                    t('navbar.sign_in')
                  )}
                </button>
              </Form>
            )
          }}

        </Formik>


        <div className="flex justify-between mt-4 text-sm text-gray-500">
          <p>
            {t('login.new_user_request')}{" "}
            <span className="font-semibold cursor-pointer textColor">
              <Link to="/register">{t('login.request_credentials')}</Link>
            </span>
          </p>
          <p
            className="font-medium cursor-pointer textColor"
            onClick={() => navigate(Routes.ForgotPassword)}
          >
            {t('login.forgot_password')}
          </p>
        </div>

      </div>
      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
    </div>
  );
};

export default Login;
