import React, { FC, useState } from 'react'

import { Tooltip } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import {  ViewMany } from 'components/Reusable'

type Props = {
    schema: any,
    lang: string
}

const Uploads: FC<Props> = ({ schema, lang }) => {

    const [open, setOpen] = useState(false);
    const [fieldProps, setFieldProps] = useState({}) as any;
    const [showIndex, setShowIndex] = useState(0);

    const handleOpen = async (values: any, props: any) => {
        setFieldProps({ ...props, values });
        setOpen(true)
    }

    const handleClose = () => {
        setShowIndex(0)
        setOpen(false);
    };

    return (
        <>
            {
                schema.length === 0 ?
                    <div className='flex justify-center items-center h-full text-lg'>
                        No form Attachments available for this service
                    </div>
                    :
                    <div className='grid md:grid-cols-3 gap-3'>
                        {
                            schema.map((item: any, index: number) => {
                                return (
                                    <div className='flex flex-col' key={index}>
                                        <span className="text-base mb-3 font-bold text-gray-600">
                                            {item.name[lang]}
                                        </span>
                                        <div className='flex justify-between border rounded-t rounded-b items-center' key={index}>
                                            <Tooltip disableFocusListener disableTouchListener title={
                                                <p className='text-sm'>{item.name[lang]}</p>
                                            }>
                                                <span className="text-sm px-5 py-2 truncate cursor-pointer w-3/4">
                                                    {`${item["attachments"].length} files added`}
                                                </span>
                                            </Tooltip>
                                            <Tooltip placement='bottom' title={
                                                <p className="text-sm">view document</p>
                                            }>
                                                <button
                                                    type="button"
                                                    disabled={item["attachments"] && item["attachments"].length > 0 ? false : true}
                                                    className='px-5 py-3 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg'
                                                    onClick={() => handleOpen(item["attachments"], {
                                                        index,
                                                        showButton: false
                                                    })}
                                                >
                                                    <Visibility fontSize='small' />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
            }

            {open && <ViewMany
                handleClose={handleClose}
                open={open}
                fieldProps={fieldProps}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
            />}

        </>

    )
}

export default Uploads
