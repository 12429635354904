import * as Yup from 'yup';

export const initialValues = {
    accounts: []
}

export const validationSchema = Yup.object().shape({
    accounts: Yup.array().min(1, 'enter at least 1 associate')
})

export const addValidationSchema = Yup.object().shape({
    name: Yup.string().required('Associate name is required'),
    email: Yup.string().email('enter a valid email').required('Associate email is required').test('isNewEmail', 'Email already added in the list below', (value, context) => {
        const { accounts } = context?.parent;
        const el = accounts?.find((el: any) => el?.email === value); 
        return el ? false : true;
    }),
})

export const headers = {
    'name': {
        en: 'Name',
        fr: 'Nom',
        rw: 'Amazina'
    },
    'email': {
        en: 'Email',
        fr: 'Email',
        rw: 'Imeli'
    },
    'status': {
        en: 'Invitation status',
        fr: "État de l'invitation",
        rw: "Imiterere y'ubutumire"
    },
    'state': {
        en: 'Associate status',
        fr: "Statut d'associé",
        rw: "Imiterere y'asosiye"
    },
    'created': {
        en: 'Invited on',
        fr: "Invité le",
        rw: "Yatumiwe kuri"
    },
    'updated': {
        en: 'Reacted on',
        fr: "Réagi le",
        rw: "Byemejwe kuri"
    },
    'actions': {
        en: "Actions",
        fr: 'Actions',
        rw: 'Igikorwa'
    }
}

export const tableButtons = {
    "active": {
        en: 'Block',
        fr: 'Bloquer',
        rw: 'Hagarika'
    },
    "block": {
        en: 'Activate',
        fr: 'Activer',
        rw: 'Mugarure'
    },
    "delete": {
        en: "Delete",
        fr: 'Supprimer',
        rw: 'Siba'
    },
    "add": {
        en: 'Add',
        fr: 'Ajouter',
        rw: 'Ongeramo'
    },
    "invite": {
        en: 'Invite',
        fr: 'Inviter',
        rw: 'Tumira'
    },
    "close": {
        en: 'Close',
        fr: 'Fermer',
        rw: 'Funga'
    },
    "pending": {
        en: 'Resend email',
        fr: "ré-envoyer l'email",
        rw: 'Ongera wohereze imeli'
    }
}
