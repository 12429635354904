import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, ErrorMessage } from 'formik'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type Props = {
    values: any,
    formik: any,
    lang: string
}

const message = {
    en: "NB: Since this is an associated account, notification will also be sent to the parent account.",
    fr: "NB : S'agissant d'un compte associé, une notification sera également envoyée au compte parent.",
    rw: "NB: Kubera ko iyi ari konti ishamikiye ku yindi, turohereza ubutumwa no kuri konti ishamikiyeho."
}

const Notification: FC<Props> = ({ values, formik, lang }) => {
    const { t } = useTranslation();

    const { setFieldValue } = formik;

    const [showMessage, setShowMessage] = useState(false);

    const userState = useSelector((state: any) => state?.users);

    useEffect(() => {
        const { user } = userState;
        if (user) {
            const { data } = user;
            if (data?.isSubscribed === 1) setShowMessage(true)
        }
    }, [userState])


    return (
        <div className="flex flex-col text-sm text-gray-600">

            {showMessage && <span className="text-lg font-bold mb-5">{message[lang]}</span>}

            <span className="text-sm font-bold">{t('service.application.notification.message')}</span>

            <div className="grid md:grid-cols-2 gap-2 mt-3">
                <div className='flex flex-col'>
                    <label className="text-sm font-medium text-gray-500 mb-2">
                        {t('signup.individual_fields.phone_label')} <span className='font-bold text-black'>only local numbers are allowed.</span>
                    </label>

                    <PhoneInput
                        inputStyle={{
                            backgroundColor: 'rgba(229, 231, 235, 1)'
                        }}
                        country="rw"
                        onlyCountries={["rw"]}
                        value={values["notification"]["phoneNumber"]}
                        searchPlaceholder="search"
                        placeholder={t('signup.individual_fields.phone_placeholder') as string}
                        onChange={(val) => setFieldValue(`notification.phoneNumber`, `+${val}`, false)}
                    />
                    <ErrorMessage name={`notification.phoneNumber`} render={msg => <div style={{ color: 'red' }} > {msg}</div >} />
                </div>

                <div className='flex flex-col'>
                    <label className="text-sm font-medium text-gray-500 mb-2">
                        {t('signup.individual_fields.email_label')}
                    </label>
                    <Field
                        className="w-full h-12 px-3 text-xs text-gray-500 bg-white border rounded outline-none mb-2"
                        type="email"
                        name={`notification.email`}
                        placeholder={t('signup.individual_fields.email_placeholder')}
                    />
                    <ErrorMessage name={`notification.email`} render={msg => <div style={{ color: 'red' }} > {msg}</div >} />
                </div>

            </div>

        </div >
    )
}

export default Notification
