import React, { useState } from "react";

import { useQuery } from 'react-query'

import { useSelector } from "react-redux";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { getApplicationByID } from "../../../reduxes/applications";

import { useCookies } from "react-cookie";
import { KeyboardBackspace } from '@material-ui/icons'

import { FormDetails, Additionals, Uploads, Fetches, Contacts, Comments, OtherDocuments } from './Contents'
import { getViewSteps } from "./getViewSteps";
import { AppLoader, Error } from "../../Reusable";

const language = {
  "back": {
    en: 'Go Back',
    fr: 'Retourner',
    rw: 'Subira inyuma'
  },
  "details": {
    "app": {
      en: 'Application Details',
      fr: 'Détails de l\'application',
      rw: 'Ibisobanuro byibanze'
    },
    "Form Details": {
      en: 'Form Details',
      fr: 'Détails du formulaire',
      rw: 'Ibyujujwe'
    },
    "Additionals": {
      en: 'Additionals',
      fr: 'Suppléments',
      rw: 'Inyongera'
    },
    "Uploads": {
      en: 'Uploads',
      fr: 'Pièces jointes',
      rw: 'Imigereka'
    },
    "Fetches": {
      en: "Fetches",
      fr: "Extractions",
      rw: "Ibyakuruwe"
    },
    "comments": {
      en: 'BNR Feedback',
      fr: 'Rétroaction BNR',
      rw: 'Ibisubizo bya BNR'
    },
    "edms": {
      en: "Other added documents",
      fr: "Autres documents ajoutés",
      rw: "Izindi dokima zongewemo"
    }
  },
  "error": {
    en: 'Application not found',
    fr: 'Application non trouvée',
    rw: 'Ubusabe ntago bubonetse'
  }
}

const ViewSingleApplication = () => {

  const navigate = useNavigate()
  const params: any = useParams();

  const [activeSection, setActiveSection] = useState("notification");

  const [viewSteps, setViewSteps] = useState<any>({
    items: [],
    gridValue: 'lg:grid-cols-2'
  });

  const location = useLocation();

  const { search } = location

  const status = new URLSearchParams(search).get('status')

  const reducer = useSelector((state: any) => {
    const { user } = state.users;
    const comms = state.communication;

    return { user, comms };
  });

  const { user } = reducer;

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const changeSection = async (section: string) => {
    setActiveSection(section);
  };

  const fetchApplication = (id: string) => {
		return getApplicationByID(id)
	}

	const { isLoading, isError, error, refetch, data } = useQuery(['read application', params?.appId], () => {
		return fetchApplication(params?.appId);
	},
		{
			retry: false,
			keepPreviousData: true,
      onSuccess: (data: any) => {
        const response = data?.data;
        const parseJson = JSON.parse(response?.data?.applicationJSON);

        const steps = getViewSteps(parseJson);

        setViewSteps(() => {
          return {
            items: [...steps],
            gridValue: response?.data?.status === 'approved' ? `lg:grid-cols-${steps?.length + 3}` : `lg:grid-cols-${steps?.length + 2}`
          }
        })
      },
			onError: (error: any) => {
				return error;
			}
		}
	)

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <AppLoader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center h-screen">
          <Error error={error} action={() => refetch()} />
      </div>
    )
  }


  const appliedApp = data?.data;

  const parseApplication = JSON.parse(appliedApp?.data?.applicationJSON)
  const { form_details, uploads, fetches, additionals, notification } = parseApplication;

  return (

    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-5 cursor-pointer text-gray-600">
        <div className="flex space-x-6" onClick={() => navigate('/overview', { state: { activeStatus: status }})}>
          <KeyboardBackspace />
          <span>{language["back"][lang]}</span>
        </div>

        {
          (appliedApp?.data.status === "missing-information") && (
            <Link
              to={{
                pathname: `/apply`,
                search: `?mode=edit&&id=${appliedApp?.data.id}`,
              }}
              className="buttonsColor px-8 py-2 rounded text-white"
            >
              Edit
            </Link>
          )
        }

      </div>
      <div className={` grid gap-3 md:grid-cols-3 ${viewSteps?.gridValue} text-base text-gray-600 mb-5 `}>

        <div className={`p-5 bg-white rounded cursor-pointer text-center ${activeSection === "notification" ? "stepsColor text-white" : ""}`} onClick={() => changeSection("notification")}>
          <span>{language["details"]["app"][lang]}</span>
        </div>

        {
          viewSteps?.items.map((step: string, ind: number) => {
            return (
              <div className={`p-5 bg-white rounded cursor-pointer text-center ${activeSection === step ? "stepsColor text-white" : ""}`} onClick={() => changeSection(step)} key={ind}>
                <span>{language["details"][step][lang]}</span>
              </div>
            )
          })
        }

        <div className={`p-5 bg-white rounded cursor-pointer text-center ${activeSection === "comments" ? "stepsColor text-white" : ""}`} onClick={() => changeSection("comments")}>
          <span>{language["details"]["comments"][lang]}</span>
        </div>

        {
          appliedApp?.data.status === "approved" && <div className={`p-5 bg-white rounded cursor-pointer text-center ${activeSection === "other" ? "stepsColor text-white" : ""}`} onClick={() => changeSection("other")}>
            <span>{language["details"]["edms"][lang]}</span>
          </div>
        }

      </div>

      <div className="bg-white application-form p-2 flex-grow font-body text-gray-600 overflow-x-auto">

        {
          activeSection === "Form Details" ?
            <FormDetails schema={form_details} lang={lang} />
            :
            activeSection === "Additionals" ?
              <Additionals schema={additionals} lang={lang} />
              :
              activeSection === "Uploads" ?
                <Uploads schema={uploads} lang={lang} />
                :
                activeSection === "Fetches" ?
                  <Fetches schema={fetches} lang={lang} />
                  :
                  activeSection === "notification" ?
                    <Contacts application={appliedApp?.data} schema={notification} lang={lang} />
                    :
                    activeSection === "other" ?
                      <OtherDocuments documents={JSON.parse(appliedApp?.data?.externalData)} />
                      :
                      <Comments application={appliedApp?.data} lang={lang} user={user} />
        }


      </div>

    </div>
  );
};

export default ViewSingleApplication;
