import React, { useState, FC, useEffect } from "react";
import { useMutation, useQuery } from 'react-query'
import { Navigate, useLocation } from 'react-router-dom';

import { SnackMessage, AppLoader, ButtonLoader } from '../../Reusable'
import { verifyAccountAction, resendEmailAction } from "../../../reduxes/user"

import { CheckCircle, CancelOutlined, MailOutlined } from "@material-ui/icons";
import { useTranslation } from 'react-i18next';
import { ErrorHandler } from "utils";

const VerifyEmail: FC = () => {

  const location = useLocation();

  const { t } = useTranslation();

  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState<any>(null)
  const [showResend, setShowResend] = useState(false)
  const [showMessage, setShowMessage] = useState('');
  const [stayedOnPage, setStayedOnPage] = useState(false);

  const handleCloseSnack = () => setOpenSnack(false)

  const queryParams = new URLSearchParams(location.search);
  const email: string = queryParams.get('email') as string;
  const token: string = queryParams.get('token') as string;

  const resendEmailMutation = useMutation(async (values) => {
    return await resendEmailAction(values);
  }, {
    retry: false,
    onMutate: () => {
      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: 'Resending the email....'
      })
    },
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');

      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: () => {
      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: "We've sent you an email, please check it and activate your account."
      })
    }
  })

  const handleResendEmail = async (values: any) => {
    try {
      await resendEmailMutation.mutateAsync(values);
    } catch (error) {
      return error;
    }
  }

  const { isError, isLoading, isSuccess } = useQuery(['verify email', token], () => {
    return verifyAccountAction(token);
  }, {
    retry: false,
    enabled: token?.length > 0,
    onSettled: (_, error) => {
      if (error) {
        const { message } = ErrorHandler(error, 'server');
        if (message.toLowerCase() === 'jwt expired') setShowResend(true);
        setShowMessage(message);
        return error;
      }
    }
  })

  useEffect(() => {
    let timer: any = null;

    // Check if you are still on the current page
    if (location.pathname === '/verify-email') {
      timer = setTimeout(() => {
        setStayedOnPage(true);
      }, 30000); // 30 seconds stay
    }

    return () => {
      // Clear the timer when the component unmounts or when the route changes
      clearTimeout(timer);
    };

  }, [location])

  if (stayedOnPage) {
    return <Navigate to="/login" />
  }

  if (isLoading) {
    return (
      <div className="flex justify-center h-screen items-center">
        <AppLoader />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex flex-col w-full lg:w-3/4 xl:w-1/3">
        <div className="flex flex-row items-center justify-between mb-8 text-gray-600 text-xl">
          <p className="font-medium">{t('verify_account.head')}</p>
        </div>
        <div className="p-2 bg-white">
          <div className="flex justify-center items-center p-4">
            {
              isSuccess ?
                <CheckCircle style={{ fontSize: "3rem", color: "#89724E" }} />
                :
                isError ?
                  <CancelOutlined style={{ fontSize: "3rem", color: "red" }} />
                  :
                  <MailOutlined style={{ fontSize: "3rem", color: "#89724E" }} />
            }

          </div>

          <div className="mb-5 text-lg text-center text-gray-600 font-medium">
            {
              isSuccess ?
                <p>{t('verify_account.message_two')}</p>
                :
                isError ?
                  <p>{showMessage?.toLowerCase() === 'jwt expired' ? 'Your email verification link has expired, please request a new one' : showMessage}</p>
                  :
                  <p>{t('verify_account.message_one')}</p>
            }

          </div>

          <div className="flex justify-center items-center mb-5">
            {
              isError && <button
                className={`buttonsColor px-8 py-2 rounded text-white text-lg w-full md:w-1/2 flex justify-center items-center ${!showResend && 'hidden'}`}
                onClick={() => handleResendEmail({ username: email })}
              >
                {resendEmailMutation.isLoading ? <ButtonLoader /> : "Resend"}
              </button>
            }
          </div>
        </div>
      </div>
      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
    </div>



  );
};

export default VerifyEmail;
