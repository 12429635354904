export enum UploadTypes {
	UploadDocument = 'UploadTypes/UploadDocument',
	UploadManyDocuments = 'UploadsTypes/UploadManyDocuments',
	GetDocument = 'UploadTypes/GetDocument',
	GetManyDocuments = 'UploadTypes/GetManyDocuments',
	GetGeneralFiles = "UploadTypes/GetGeneralFiles",
	DownloadUserGuide = 'UploadTypes/downloadUserGuide',
	ResetPayload = 'UploadTypes/ResetPayload',
	Loading = "UploadTypes/Loading",
	Errors = "UploadTypes/Errors",
}

interface UploadDocument {
	type: typeof UploadTypes.UploadDocument,
	payload: {
		data: any
	}
}

interface UploadManyDocuments {
	type: typeof UploadTypes.UploadManyDocuments,
	payload: {
		data: any
	}
}

interface GetDocument {
	type: typeof UploadTypes.GetDocument,
	payload: {
		data: any
	}
}

interface GetGeneralFiles {
	type: typeof UploadTypes.GetGeneralFiles,
	payload: {
		data: any
	}
}

interface DownloadUserGuide {
	type: typeof UploadTypes.DownloadUserGuide,
	payload: {
		data: any
	}
}

interface GetManyDocuments {
	type: typeof UploadTypes.GetManyDocuments,
	payload: {
		data: any
	}
}

interface ResetPayload {
	type: typeof UploadTypes.ResetPayload;
	payload: { data: any };
}

interface Loading {
	type: typeof UploadTypes.Loading;
	payload: {
		loading: boolean;
	};
}

interface Errors {
	type: typeof UploadTypes.Errors;
	payload: {
		errors: any;
	};
}

export type UploadTypeAction =
	| UploadDocument
	| UploadManyDocuments
	| GetDocument
	| GetManyDocuments
	| GetGeneralFiles
	| DownloadUserGuide
	| ResetPayload
	| Loading
	| Errors
