import React, { useState, FC, ChangeEvent } from 'react'
import axios from 'axios';

import { CloudUploadOutlined, VisibilityOutlined, InfoOutlined } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core';

import { ErrorMessage, FieldArray } from 'formik';
import { ViewMany } from '../../../../Reusable'
import { ErrorHandler } from 'utils/ErrorHandler';

type Props = {
    props: any
}

const fileURL = process.env.REACT_APP_FILE_URL

const FileField: FC<Props> = ({ props }) => {
    const { name, description, validation, id } = props["schema"]
    const { lang, index, formValues, inModal, schemaName, formik } = props

    const { setFieldError, setFieldValue, setFieldTouched } = formik;

    const fieldName = inModal ? `${id}["attachments"]` : `${schemaName}[${index}]["attachments"]`;
    const fieldProgress = inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`;

    const [open, setOpen] = useState(false);

    const [showIndex, setShowIndex] = useState(0);

    const [fieldProps, setFieldProps] = useState<any>({});

    const handleOpen = async (props: any) => {
        setFieldProps(props);
        setOpen(true)
    }

    const handleClose = () => {
        setShowIndex(0)
        setOpen(false)
    };

    const handleUploading = async (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const files: any = target.files!

        const maxSize = 25;
        const accept = validation.required.format.accept;

        const sizeMessage = {
            en: `Allowed file size is ${maxSize} mb`,
            fr: `La taille de fichier autorisée est ${maxSize} mb`,
            rw: `Ingano ya dosiye yemewe ni ${maxSize} mb`
        }

        const typeMessage = {
            en: `Allowed file type (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`,
            fr: `Type de fichier autorisé (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`,
            rw: `Ubwoko bwemewe (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`
        }

        const checkSize = Array.from(files).filter((file: any) => file.size > (maxSize * 1000000));
        const checkType = Array.from(files).filter((file: any) => {
            return !accept.filter((v: any) => ((v === file.type) || (file.type.toLowerCase() === 'application/pdf') || (v.split(".").pop().toLowerCase() === file.name.split(".").pop().toLowerCase()))).length
        });

        try {
            if (checkSize.length > 0) {
                return setFieldError(fieldName, sizeMessage[lang])
            } else if (checkType.length > 0) {
                return setFieldError(fieldName, typeMessage[lang])
            } else {
                const formData = new FormData();

                Array.from(files).forEach((file: any) => {
                    formData.append('files', file)
                })
    
                const response = await axios.post(`${fileURL}uploads`, formData, {
                    onUploadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setFieldValue(fieldProgress, `${percentCompleted}%`, false)
                    },
                    headers: {
                        'x-auth': '1a9b83aa-8af3-485a-a398-13cb28475684'
                    }
                })
    
                const newAttachments = response?.data?.data?.map((att: any) => {
                    return {
                        name: att?.name,
                        link: att?.url
                    }
                })
                const existing = formValues[inModal ? id : index]['attachments'];
                setFieldValue(fieldName, [...existing, ...newAttachments], false);
                setFieldValue(fieldProgress, ``, false);
            }

        } catch (error) {
            const { message } = ErrorHandler(error, 'File server');
            setFieldTouched(fieldName, true, false)
            setFieldError(fieldName, message);
            return error;
        }

    }

    return (

        <FieldArray
            name={inModal ? `${id}["attachments"]` : `${schemaName}[${index}]["attachments"]`}
            key={index}
            render={({ remove, replace }) => {

                return (

                    <div className="flex flex-col text-gray-600 text-sm" key={index}>
                        {name[lang] && props.hide === true && <label htmlFor={name[lang]}>{name[lang]}
                            {
                                validation.required.value && <span className="text-red-500">*</span>
                            }
                        </label>}
                        <div className="flex mt-2 border rounded-t rounded-b h-10 w-full pl-3">
                            <div className="flex-grow self-center w-1">
                                <div className="flex items-center justify-start ">
                                    <span
                                        className="mr-4 cursor-pointer hidden md:block"
                                    >
                                        <Tooltip disableFocusListener disableTouchListener title={
                                            <p className='text-sm'>{description[lang]}</p>
                                        }>
                                            <span>
                                                <InfoOutlined
                                                    fontSize="small"
                                                />
                                            </span>

                                        </Tooltip>
                                    </span>

                                    <span className="cursor-pointer">
                                        {
                                            `${formValues[inModal ? `${id}` : `${index}`] && formValues[inModal ? `${id}` : `${index}`]["attachments"].length} added files`
                                        }
                                    </span>

                                </div>
                            </div>

                            <div className='flex justify-center items-center h-full'>

                                <label htmlFor={`${fieldName}`} className="custom-yellow-bg h-full w-max p-2 mr-2 text-white cursor-pointer font-semibold">

                                    {
                                        formValues[inModal ? id : index]['inputValue'].length > 0
                                            ?
                                            formValues[inModal ? id : index]['inputValue']
                                            :
                                            <CloudUploadOutlined />
                                    }

                                    <input
                                        className="z-0 hidden w-full h-10 px-3 mt-2 text-xs text-gray-500 bg-white border-0 rounded outline-none opacity-0"
                                        type="file"
                                        id={fieldName}
                                        name={fieldName}
                                        multiple
                                        accept={validation.required.format.accept.join(",")}
                                        onChange={(e) => handleUploading(e)}
                                    />

                                </label>


                                <span
                                    className="px-5 py-2 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg"
                                    onClick={() => handleOpen({
                                        remove,
                                        replace,
                                        index,
                                        showButton: true,
                                        values: formValues[inModal ? `${id}` : `${index}`]["attachments"]
                                    })}
                                >
                                    <VisibilityOutlined />
                                </span>

                            </div>


                        </div>

                        <ErrorMessage name={inModal ? `${id}["attachments"]` : `${schemaName}[${index}]["attachments"]`} render={msg => <div style={{ color: 'red' }}>
                            {msg}
                        </div >} />

                        {open && <ViewMany
                            handleClose={handleClose}
                            open={open}
                            fieldProps={fieldProps}
                            showIndex={showIndex}
                            setShowIndex={setShowIndex}
                        />}


                    </div>
                )
            }}
        />

    )
}

export default FileField