import React, { useState, useEffect, FC } from 'react'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next';

import MessagesBox from './MessagesBox'
import ReplyBox from './ReplyBox'

type Props = {
    lang: string
}

const Messages: FC<Props> = ({ lang }) => {
    const [show, setShow] = useState(false);
    const [parentId, setParentId] = useState(null);

    const { t } = useTranslation();

    const communicationState = useSelector((state: any) => state?.communication);

    useEffect(() => {
        const { parent } = communicationState;
        if (parent) {
            setShow(true)
            setParentId(parent?.id);
        }
    }, [communicationState])

    return (
        <div className={`flex-grow bg-white rounded ${show ? '' : 'hidden xl:block'} w-1`}>

            {
                !show ?
                    <div className="flex justify-center items-center h-full" id="check">
                        <span className="text-lg font-medium">{t('communication.buttons.choose')}</span>
                    </div>
                    :
                    <div className="p-2 flex flex-col h-full">
                        <MessagesBox parentId={parentId} />
                        <ReplyBox parentId={parentId} lang={lang} />
                    </div>
            }

        </div>
    )
}

export default Messages