import React, { FC } from 'react'
import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

type Props = {
    schema: any,
    lang: string
}

const Fetches: FC<Props> = ({ schema, lang }) => {
    return (
        <div className='h-full'>
            {
                schema.length === 0 ?
                    <div className='flex justify-center items-center h-full'>
                        No form fetches available for this service
                    </div>
                    :
                    <div className='grid md:grid-cols-3 gap-3'>
                        {
                            schema.map((item: any, index: number) => {
                                return (
                                    <div className='flex flex-col' key={index}>
                                        <span className='font-bold mb-3'>{item.name[lang]}</span>
                                        <div className='flex justify-between border rounded-t rounded-b items-center px-5 py-3'>

                                            {
                                                item["attachments"].length > 0 ?
                                                    <>
                                                        <span className=''>{`${item["attachments"].length} added files`}</span>
                                                        <Tooltip placement='bottom' title={
                                                            <p className='text-sm'>{`${item.name[lang]} is added and can only be viewed by the reviewers.`}</p>
                                                        }>
                                                            <span className='text-gray-600 cursor-pointer'>
                                                                <InfoOutlined fontSize='small' />
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                    :
                                                    <span>{`${item["attachments"].length} added file`}</span>
                                            }
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

export default Fetches
