import React, { FC, useState } from 'react'
import { Visibility } from '@material-ui/icons'
import { Tooltip, Modal, Backdrop, createStyles, makeStyles, Theme } from '@material-ui/core'
import { ViewMember } from './ViewMember'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);

type Props = {
    schema: any,
    lang: string
}

const Additionals: FC<Props> = ({ schema, lang }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false)

    const [modalValues, setModalValues] = useState({} as any)

    const handleClose = () => setOpen(false)
    const handleOpen = (values: any) => {
        setModalValues(values)
        setOpen(true)
    }

    return (
        <div className='h-full'>
            {
                schema.length === 0 ?
                    <div className='flex justify-center items-center h-full'>
                        No form collections available for this service
                    </div>
                    :
                    <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-3 h-full'>
                        {
                            schema.map((item: any, index: number) => {
                                return (

                                    <div className='flex flex-col' key={index}>
                                        <span className='text-lg mb-2'>{item.collection_name[lang]}</span>
                                        <div className=' border-gray-300 border rounded p-2 flex-grow overflow-x-auto h-1'>

                                            {
                                                item.collection_values.length === 0 ?
                                                    <div>No data</div>
                                                    :

                                                    item.collection_values.map((val: any, ind: number) => {
                                                        return (
                                                            <div className='flex justify-between bg-gray-100 rounded mb-3' key={ind}>
                                                                <span className='px-5 py-3'>{`Member ${ind + 1}`}</span>

                                                                <Tooltip placement='bottom' title={
                                                                    <span className='text-sm'>View Member</span>
                                                                }>
                                                                    <span className='px-5 py-3 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg' onClick={() => handleOpen(val)}>
                                                                        <Visibility fontSize='small' />
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    })
                                            }

                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
            }

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <ViewMember member={modalValues} lang={lang} />

            </Modal>


        </div>
    )
}

export default Additionals
