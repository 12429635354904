import React, { FC, useState } from 'react'
import { ViewMany } from 'components/Reusable'

import { ViewLocationField, ViewFileField, ViewSelectField } from './ViewFields/ViewFields'

type Props = {
    schema: any,
    lang: string
}

const FormDetails: FC<Props> = ({ schema, lang }) => {

    const [open, setOpen] = useState(false);
    const [fieldProps, setFieldProps] = useState({}) as any;
    const [showIndex, setShowIndex] = useState(0);

    const handleOpen = async (values: any, props: any) => {
        setFieldProps({ ...props, values });
        setOpen(true)
    }

    const handleClose = () => {
        setShowIndex(0)
        setOpen(false);
    };

    return (
        <div className='h-full'>
            {
                schema.length === 0 ?
                    <div className='flex justify-center items-center h-full'>
                        No form Inputs available for this service
                    </div>
                    :
                    <div className='grid md:grid-cols-2 xl:grid-cols-3 gap-3'>
                        {
                            schema.map((item: any, index: number) => {
                                const countryValue = schema.find((el: any) => el.inputType === "address" && el.name["en"].toLowerCase() === "country")
                                return (
                                    <>
                                        {
                                            item.inputType === "select" ?
                                                <ViewSelectField item={item} lang={lang} />
                                                :
                                                item.inputType === "file" ?
                                                    <ViewFileField item={item} lang={lang} handleOpen={handleOpen} index={index} />
                                                    :
                                                    item.inputType === "address" ?
                                                        <ViewLocationField item={item} lang={lang} countryVal={countryValue?.inputValue} />
                                                        :
                                                        item.inputType === "decimal" ?
                                                            <div className='flex flex-col'>
                                                                <span className=' mb-2'>{item.name[lang]}</span>
                                                                <span className={`border rounded p-3 h-auto`}>{`${item.inputValue} ${item.fetchFrom}`} </span>
                                                            </div>
                                                            :
                                                            <div className='flex flex-col'>
                                                                <span className=' mb-2'>{item.name[lang]}</span>
                                                                <span className={`border rounded p-3 h-auto`}>{item.inputValue}</span>
                                                            </div>
                                        }

                                    </>

                                )
                            })
                        }
                    </div>
            }

            {open && <ViewMany
                handleClose={handleClose}
                open={open}
                fieldProps={fieldProps}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
            />}
        </div>
    )
}

export default FormDetails
