import React, { ChangeEvent, FC, useRef, useState } from 'react'
import axios from 'axios';
import { FieldArray, ErrorMessage } from 'formik';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined, CloudUploadOutlined, VisibilityOutlined } from '@material-ui/icons';
import ViewMany from './ViewMany';
import { ErrorHandler } from 'utils/ErrorHandler';

type Props = {
    props: any;
}

const fileURL = process.env.REACT_APP_FILE_URL

const UploadFile: FC<Props> = ({ props }) => {

    const { values, setFieldError, setFieldValue, setFieldTouched, lang, fieldName } = props;

    const inputRef: any = useRef(null);

    const [open, setOpen] = useState(false);

    const [showIndex, setShowIndex] = useState(0);

    const [fieldProps, setFieldProps] = useState<any>({});

    const [uploading, setUploading] = useState<String>('');

    const handleOpen = async (props: any) => {
        setFieldProps(props);
        setOpen(true)
    }

    const handleClose = () => {
        setShowIndex(0)
        setOpen(false)
    };

    const handleButtonClick = () => {
        return inputRef?.current.click();
    }

    const handleUploading = async (e: ChangeEvent<HTMLInputElement>, helpers: any) => {
        const target = e.target as HTMLInputElement;
        const files: any = target.files!

        const maxSize = 25;
        const accept = [
            '.png',
            '.jpeg',
            '.jpg',
            '.pdf',
            '.doc',
            '.docx',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'application/vnd.ms-powerpoint',
            'text/plain'
        ];

        const sizeMessage = {
            en: `Allowed file size is ${maxSize} mb`,
            fr: `La taille de fichier autorisée est ${maxSize} mb`,
            rw: `Ingano ya dosiye yemewe ni ${maxSize} mb`
        }

        const typeMessage = {
            en: `Allowed file type (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`,
            fr: `Type de fichier autorisé (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`,
            rw: `Ubwoko bwemewe (.png, .jpeg, .jpg, .pdf, .doc, .xlsx, .ppt)`
        }

        const checkSize = Array.from(files).filter((file: any) => file.size > (maxSize * 1000000));
        const checkType = Array.from(files).filter((file: any) => {
            return !accept.filter((v: any) => v === file.type || v.split(".").pop() === file.name.split(".").pop()).length
        });

        setFieldTouched(fieldName, true, false)

        try {
            if (checkSize.length > 0) {
                setFieldError(fieldName, sizeMessage[lang])
            } else if (checkType.length > 0) {
                setFieldError(fieldName, typeMessage[lang])
            } else {
                const formData = new FormData();

                Array.from(files).forEach((file: any) => {
                    formData.append('files', file)
                })
                
                const response = await axios.post(`${fileURL}uploads`, formData, {
                    onUploadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploading(`${percentCompleted}%`)
                    },
                    headers: {
                        'x-auth': '1a9b83aa-8af3-485a-a398-13cb28475684'
                    }
                })
    
                const newAttachments = response?.data?.data?.map((att: any) => {
                    return {
                        name: att?.name,
                        link: att?.url
                    }
                })
    
                setFieldValue(fieldName, [...values, ...newAttachments], false);
                setUploading('');
            }

        } catch (error) {
            const { message } = ErrorHandler(error, 'File server');
            setFieldTouched(fieldName, true, false)
            setFieldError(fieldName, message);
            return error;
        }

    }

    return (
        <FieldArray
            name={fieldName}
            render={({ push, remove, replace }) => {

                return (

                    <div className="flex flex-col text-gray-600 text-sm w-full xl:w-1/3">
                        <label htmlFor={fieldName}>Attachments</label>
                        <div className="flex mt-2 border rounded-t rounded-b h-10 w-full pl-3">
                            <div className="flex-grow self-center w-1">
                                <div className="flex items-center justify-start ">
                                    <span
                                        className="mr-4 cursor-pointer hidden md:block"
                                    >
                                        <Tooltip disableFocusListener disableTouchListener title={
                                            <p className='text-sm'>Attached files</p>
                                        }>
                                            <span>
                                                <InfoOutlined
                                                    fontSize="small"
                                                />
                                            </span>

                                        </Tooltip>
                                    </span>

                                    <span className="cursor-pointer">
                                        {
                                            `${values?.length} added files`
                                        }
                                    </span>

                                </div>
                            </div>

                            <div className='flex justify-center items-center h-full'>

                                <input
                                    className="z-0 hidden w-full h-10 px-3 mt-2 text-xs text-gray-500 bg-white border-0 rounded outline-none opacity-0"
                                    ref={inputRef}
                                    type="file"
                                    id="files"
                                    multiple
                                    accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, text/plain "
                                    name="files"
                                    onChange={(e) => handleUploading(e, { push })}
                                />

                                <button
                                    type="button"
                                    onClick={handleButtonClick}
                                    className="h-full w-max text-white mr-2 cursor-pointer custom-yellow-bg p-2 font-semibold"
                                >
                                    {uploading?.length > 0 ?
                                        uploading
                                        :
                                        <CloudUploadOutlined />
                                    }
                                </button>


                                <button
                                    disabled={values?.length === 0 ? true : false}
                                    className="px-5 py-2 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg"
                                    onClick={() => handleOpen({
                                        remove,
                                        replace,
                                        index: 0,
                                        showButton: true,
                                        values
                                    })}
                                    type="button"
                                >
                                    <VisibilityOutlined />
                                </button>

                            </div>


                        </div>

                        <ErrorMessage name={fieldName} render={msg => <div style={{ color: 'red' }}>{msg}</div >} />

                        {open && <ViewMany
                            handleClose={handleClose}
                            open={open}
                            fieldProps={fieldProps}
                            showIndex={showIndex}
                            setShowIndex={setShowIndex}
                        />}

                    </div>
                )
            }}
        />
    )
}

export default UploadFile