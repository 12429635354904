export const ApiEndpoints = {
	welcome: '/users',
	login: '/frontend/users/login',
	loginWithOTP: '/frontend/users/login-otp',
	forgotPass: '/frontend/users/forgot-password',
	resendEmail: '/frontend/users/resend-email',
	register: '/frontend/users/register',
	verifyAccount: '/frontend/users/verify-account',
	verifyPhoneNumber: '/frontend/users/verify-phone-number',
	resetPassword: '/frontend/users/reset-password',
	changePassword: '/frontend/users/change-password',
	completeAccount: '/frontend/users/complete-account',
	getLoggedInUser: '/frontend/users/get-profile',
	uploadPicture: '/frontend/users/update-profile-picture',
	updateProfile: '/frontend/users/update-profile',
	getUserTypes: '/backend/input-types/all',
	getAllDepartmentServices: '/frontend/departments',
	getAllDepartmentServicesTable: '/frontend/departments/table',
	getAllServices: '/frontend/services',
	getService: '/frontend/services/read',
	getAllServicesTable: '/frontend/services/table',
	createApplication: '/frontend/applications/create',
	updateApplication: '/frontend/applications/update',
	getAllUserApplications: '/frontend/applications',
	getApplicationById: '/frontend/applications/read',
	getFilteredApplication: '/frontend/applications/table',
	getApplicationStats: '/frontend/applications/stats',
	cancelApplication: '/frontend/applications/delete',
	searchServices: '/frontend/app/search',
	checkServiceApply: '/frontend/services/check',
	rcaDetails:'/frontend/external/rca',
	getOTP: '/frontend/users/send-otp',
	searchUser: '/frontend/users/search-user',
	getUserNotifications: '/frontend/user-notifications/table',
	UpdateNoticationStatus: '/frontend/user-notifications/update-status',
	getRRAdetails: 'integrations/rra',
	getRDBdetails: 'integrations/rdb',
	getNidaDetails: 'integrations/nida',
	getRCAdetails: 'integrations/rca',
	getCRBdetails: 'integrations/crb',
	readUserGuide: '/user-guide/read',
	readGeneralFiles: '/user-guide/read-files',
	SendCommunication: '/communication/create-applicant',
	replyCommunication: '/communication/reply',
	contactUs: '/communication/contact-us',
	GetAllParents: '/communication/parents',
	GetParentMessages: '/communication/parent-messages',
	UpdateReadStatus: '/communication/update-status',
	GetUnreadCount: '/communication/unread-messages',
	EventAction: '/backend/events/act',
	CreateAssociates: '/frontend/associates/create',
	ReadAssociates: '/frontend/associates/read',
	UpdateAssociate: '/frontend/associates/update',
	resendAssociateEmail: '/frontend/associates/resend-email',
	DeleteAssociate: '/frontend/associates/delete',
};
