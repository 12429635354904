import React, { FC, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

import { SnackMessage, ButtonLoader } from '../../Reusable'
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

import { getOTPAction, completeAccountAction } from '../../../reduxes/user'
import { ErrorHandler } from 'utils';

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{14,}$/g;

const Associate: FC = () => {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const location = useLocation();

    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState<any>(null);
    const [showPassword, setShowPassword] = useState({
        show: false,
        type: 'password'
    });

    const handleCloseSnack = () => setOpenSnack(false);

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');


    const handleValidateOTP = (phonenumber: any, props: any) => {
        const { setFieldError, setFieldTouched } = props;

        let error = false;
        setFieldTouched("phoneNumber", true, false);

        if (phonenumber?.length === 0) {
            error = true;
            setFieldError("phoneNumber", 'phone number is required')
            return error;
        }

        const phoneNumber = parsePhoneNumber(phonenumber)
        const num = phoneNumber?.number as string
        const CD = phoneNumber?.country

        if (CD?.toLowerCase() !== "rw") {
            error = true;
            setFieldError("phoneNumber", 'we can not send the OTP to an international number as of now.')
            return error;
        }

        if (!isValidPhoneNumber(num, CD)) {
            error = true;
            setFieldError("phoneNumber", 'phone number is invalid')
            return error;
        }
        setFieldError("username", '')
        return error;
    }

    const getOTPMutation = useMutation(async (values: any) => {
        return await getOTPAction(values);
    }, {
        retry: false,
        onError: (error: any) => {
            const { message } = ErrorHandler(error, 'server');
            setOpenSnack(true);
            setSnackMessage({
                error: true,
                message
            })
        },
        onSuccess: () => {
            setOpenSnack(true);
            setSnackMessage({
                error: false,
                message: "We've sent you an OTP to use, please insert the number you received below in the password field."
            })
        }
    })

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return completeAccountAction(values);
    }, {
        retry: false,
        onSettled: (data, error) => {
            let message = 'Associate account created successfully.'

            if (error) {
                const { message: res } = ErrorHandler(error, 'server');
                message = res;
            }

            setOpenSnack(true);
            setSnackMessage({
                error: error ? true : false,
                message
            })

            if (data) {
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        }
    })

    return (
        <div className="flex justify-center items-center h-full">
            <div className="flex flex-col w-full md:w-1/2 xl:w-1/4 my-5">
                <div className="flex flex-row items-center justify-between mb-8 text-xl text-gray-500">
                    <p className="mr-4 text-sm font-medium md:text-xl">
                        {t('create_associate.title')}
                    </p>
                </div>

                <Formik
                    initialValues={{
                        phoneNumber: '',
                        otp: '',
                        password: ''
                    }}
                    validationSchema={Yup.object().shape({
                        phoneNumber: Yup.string().required('Enter your phone number'),
                        otp: Yup.string().required('Enter the otp sent to your phone'),
                        password: Yup.string().matches(passwordRegex, 'Password must be at least 14 character long and contain at least one uppercase, lowercase, numeric and a special character').required("password is required"),
                    })}
                    onSubmit={async (values, actions) => {
                        const phoneNumber = parsePhoneNumber(values?.phoneNumber)
                        const num = phoneNumber?.number as string
                        const CD = phoneNumber?.country

                        if (!isValidPhoneNumber(num, CD)) actions?.setFieldError("phoneNumber", 'phone number is invalid');
                        else {
                            try {
                                const obj = {
                                    token,
                                    ...values
                                }
                                await mutateAsync({ ...obj });
                            } catch (error) {
                                return error;
                            }
                        }

                    }}
                >
                    {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
                        return (
                            <Form className='flex flex-col text-gray-500 text-sm' autoComplete='off'>

                                <div className='flex flex-col mb-3'>
                                    <label className="text-sm" htmlFor='phone'>{t('create_associate.phone_label')}*</label>
                                    <div className="flex h-12 mt-2 text-sm text-gray-600">
                                        <PhoneInput
                                            inputClass="px-3 text-sm text-gray-500 bg-white border-0 rounded outline-none"
                                            country="rw"
                                            onlyCountries={["rw"]}
                                            value={values.phoneNumber}
                                            placeholder={t('login.phone_placeholder') as string}
                                            onChange={(val) => setFieldValue("phoneNumber", `+${val}`, false)}
                                        />

                                        <button
                                            type="button"
                                            className="text-sm text-white buttonsColor rounded-r cursor-pointer flex justify-center items-center px-3 w-1/4"
                                            onClick={async () => {
                                                try {
                                                    const res = handleValidateOTP(values.phoneNumber, { setFieldError, setFieldTouched })
                                                    if (!res) {
                                                        setFieldError('phoneNumber', '');
                                                        const obj = { phoneNumber: values?.phoneNumber.replace(/\s/g, ''), verify: true }
                                                        return await getOTPMutation?.mutateAsync(obj);
                                                    }
                                                    return;
                                                } catch (error) {
                                                    return error;
                                                }
                                                
                                            }}
                                        >
                                            {
                                                getOTPMutation?.isLoading ? <ButtonLoader /> : t('login.get_otp')
                                            }

                                        </button>

                                    </div>
                                    <ErrorMessage name="phoneNumber" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />
                                </div>

                                <div className="flex flex-col mb-5">
                                    <label className="text-sm" htmlFor='otp'>{t('create_associate.otp_label')}*</label>
                                    <div className="h-12 bg-white flex">
                                        <Field
                                            className="text-sm border-0 rounded outline-none flex-grow px-3"
                                            type="text"
                                            name="otp"
                                            id="otp"
                                            placeholder={t('create_associate.otp_placeholder')}
                                        />
                                    </div>


                                    <ErrorMessage name="otp" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                                </div>

                                <div className="flex flex-col mb-5">
                                    <label className="text-sm" htmlFor='password'>{t('create_associate.password_label')}*</label>
                                    <div className="h-12 bg-white flex">
                                        <Field
                                            className="text-sm border-0 rounded outline-none flex-grow px-3"
                                            type={showPassword?.type}
                                            name="password"
                                            id="password"
                                            value={values?.password}
                                            placeholder={t('create_associate.password_placeholder')}
                                            onChange={(e: any) => {
                                                const val = e.target.value;
                                                setFieldValue('password', val);
                                            }}
                                        />

                                        <button
                                            type="button"
                                            className="flex justify-center items-center h-full p-3 rounded-r cursor-pointer"
                                            onClick={() => setShowPassword((prev) => {
                                                return {
                                                    show: !prev?.show,
                                                    type: prev?.type === 'text' ? 'password' : 'text'
                                                }
                                            })}
                                        >
                                            {showPassword?.show ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                        </button>
                                    </div>


                                    <ErrorMessage name="password" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

                                </div>

                                <button
                                    className="flex items-center justify-center w-full h-12 px-1 py-1 pt-2 font-semibold text-white rounded shadow buttonsColor"
                                    type="submit"
                                >
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        t('navbar.sign_up')
                                    )}
                                </button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}

        </div>
    )
}

export default Associate;
