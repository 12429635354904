import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Modal, Backdrop, Fade, createStyles, makeStyles, Theme } from '@material-ui/core'

import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons'
import fileDownload from 'js-file-download'
import { AppLoader, Error } from '..';

import { getDocumentAction } from '../../../reduxes/uploads'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);

type Props = {
    handleClose: any,
    open: any,
    fieldProps: any,
    showIndex: number,
    setShowIndex: any
}


const ViewMany: React.FC<Props> = ({ handleClose, open, fieldProps, showIndex, setShowIndex }) => {
    const classes = useStyles();

    const { remove, showButton, values } = fieldProps;

    const [data, setData] = useState<any>(null)

    const handleNext = (key: string) => {
        if (key === "back") {
            setShowIndex(showIndex - 1)
        } else {
            setShowIndex(showIndex + 1)
        }
    }

    const handleRemove = () => {
        setShowIndex(0)
        remove(showIndex)
        handleClose()
    }

    const handleDownloadFile = (file: any) => {
        const { url, name } = file;
        fileDownload(url, name);
    }

    const { isLoading, refetch, error, isError } = useQuery(['get documents', showIndex], () => {
        return getDocumentAction(values[showIndex])
    }, {
        retry: false,
        keepPreviousData: false,
        enabled: values?.length > 0 ? true : false,
        onSettled: (data: any, error: any) => {
            if (error) return error;
            let res = data?.data;

            const { name } = values[showIndex];

            const fileType = data?.headers["content-type"]

            const blob = new Blob([res], { type: fileType });

            setData({
                fileType,
                name,
                url: fileType.includes("image") || fileType === 'application/pdf' ? URL.createObjectURL(blob) : blob
            })
        }
    })


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={`bg-white p-3 h-4/6 w-5/6 md:w-3/4 md:h-3/4 rounded outline-none flex flex-col`}>

                    <div className='flex-grow mb-3 h-full'>
                        {
                            (isLoading || !data) ?
                                <div className='flex justify-center h-full items-center'>
                                    <AppLoader />
                                </div>
                                :
                                isError ?
                                    <div className='flex justify-center h-full items-center'>
                                        <Error error={error} action={() => refetch()} />
                                    </div>
                                    :
                                    <div className='flex flex-col h-full'>

                                        <div className='flex justify-between items-center text-lg text-gray-600 mb-3'>
                                            <span>{data?.["name"]}</span>
                                            {
                                                showButton && <button type='button' className='px-8 py-2 buttonsColor rounded text-white' onClick={() => handleRemove()}>
                                                    Remove
                                                </button>
                                            }

                                        </div>

                                        <div className='h-full'>
                                            {
                                                data?.fileType === "application/pdf" ?
                                                    <iframe src={data?.url} title="preview" className="object-contain w-full h-full" />
                                                    :
                                                    data?.fileType.includes('image')
                                                        ?
                                                        <img src={data?.url} alt="avatar" className="object-contain image-height w-full flex justify-center items-center" />
                                                        :
                                                        <div className='flex justify-center items-center h-full'>
                                                            <button
                                                                type='button'
                                                                className='text-white buttonsColor px-5 py-3 cursor-pointer rounded'
                                                                onClick={() => handleDownloadFile(data)}
                                                            >
                                                                Download File
                                                            </button>
                                                        </div>

                                            }
                                        </div>




                                    </div>
                        }
                    </div>
                    {/* <img src={data?.url} alt="buretse" className="w-full h-1/4 object-contain" /> */}


                    {/* <div className='flex-grow mb-3 h-full bg-green-900'>
                        
                    </div> */}

                    <div className='flex justify-between'>
                        <button
                            type='button'
                            disabled={showIndex === 0}
                            className='buttonsColor px-4 py-3 text-white rounded cursor-pointer'
                            onClick={() => handleNext("back")}
                        >
                            <ArrowBackIosOutlined />
                        </button>

                        <button
                            type='button'
                            disabled={showIndex === (values?.length - 1)}
                            className='buttonsColor px-4 py-3 text-white rounded cursor-pointer'
                            onClick={() => handleNext("next")}
                        >
                            <ArrowForwardIosOutlined />
                        </button>
                    </div>


                </div>
            </Fade>
        </Modal>
    )
}

export default ViewMany;
