import { combineReducers } from 'redux';
import { messageReducer } from 'reduxes/message';
import { userReducer } from 'reduxes/user'
import { serviceReducer} from 'reduxes/service'
import { applicationReducer } from 'reduxes/applications';
import { communicationReducer } from 'reduxes/communications'
import { externalsReducer } from 'reduxes/externals'
import { uploadsReducer } from 'reduxes/uploads'
import { eventReducer } from 'reduxes/events'
import { notificationReducer } from 'reduxes/notifications'
import { generalsReducer } from 'reduxes/general'

export const RootReducer = combineReducers({
	messages: messageReducer,
	users: userReducer,
	services: serviceReducer,
	applications: applicationReducer,
	communication: communicationReducer,
	externals: externalsReducer,
	documentsUploads: uploadsReducer,
	events: eventReducer,
	notifications: notificationReducer,
	generals: generalsReducer
});
