import * as Yup from "yup";

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{14,}$/g;

export const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Enter your old password"),
    newPassword: Yup.string()
    .notOneOf([Yup.ref('oldPassword')], 'New password should not match the old password')
    .matches(passwordRegex, 'Password must be at least 14 character long and contain at least one uppercase, lowercase, numeric and a special character')
    .required("Enter your new password"),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match').required("confirm password is required")
});
