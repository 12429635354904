import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import "./Sidebar.scss";
import {
  Menu,
  SettingsOutlined,
  CloseOutlined,
  VisibilityOutlined,
  DraftsOutlined,
  PrintOutlined,
  NotificationsOutlined
} from "@material-ui/icons";

import { useTranslation } from 'react-i18next';

type Props = {
  activeField: string;
};

const Sidebar: FC<Props> = ({ activeField }) => {

  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const reducer = useSelector((state: any) => {
    const { user } = state.users;
    return { user }
  })

  const { user } = reducer

  const handleShow = () => {
    setShow(!show);
  };

  const opacityDiv =
    "fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50";
  const asideDiv =
    "fixed top-0 left-0 z-30 flex flex-col h-full p-6 overflow-auto transition duration-700 ease-in w-72 asideColor delay-1000";
  const activeSideBar = "text-white rounded asideColor";
  const activeSideBarPhone = "bg-white rounded textColor";

  return (
    <>
      {/* phone side bar */}
      <div className="flex items-center justify-between menuColor xl:hidden mb-5">
        <Menu onClick={() => handleShow()} className="cursor-pointer" />
        <p className="text-lg font-bold"> {activeField} </p>
      </div>

      <div
        className={!show ? `${opacityDiv} hidden` : opacityDiv}
        onClick={() => handleShow()}
      />

      <aside className={!show ? `${asideDiv} hidden` : asideDiv}>
        <div className="flex items-center mb-3 space-x-4 text-lg font-light text-white">
          <SettingsOutlined />
          <span>{t('sidebar.settings')}</span>
        </div>

        <div className="mb-3 border-b-2 border-white"></div>

        <div className="flex-grow">
          <ul className="text-lg font-light text-white">
            <Link
              to="/overview"
              className={
                `
                    flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "overview" ? activeSideBarPhone : ""}`
              }
            >

              <span className="mr-4">
                <VisibilityOutlined />
              </span>
              <div className="flex justify-between flex-grow">
                <span>{t('sidebar.overview')}</span>
              </div>

            </Link>

            <Link
              to="/drafts"
              className={
                `
                    flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "draft services" ? activeSideBarPhone : ""}`
              }
            >
              <span className="mr-4">
                <DraftsOutlined />
              </span>

              <div className="flex justify-between flex-grow">
                <span>{t('sidebar.draft_services')}</span>
              </div>
            </Link>

            <Link
              to="/communications"
              className={
                `
                    flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "communications" ? activeSideBarPhone : ""}`
              }
            >
              <span className="mr-4">
                <PrintOutlined />
              </span>

              <div className="flex justify-between flex-grow items-center">
                <span>{t('sidebar.documents')}</span>
                {
                  user && user?.data.communicationsCount > 0 && (
                    <div className="h-6 w-6 flex justify-center items-center text-xs bg-red-500 rounded-full">
                      <span className={`text-white`
                      }>
                        {user?.data.communicationsCount === 100 ? '99+' : user?.data.communicationsCount}
                      </span>
                    </div>
                  )
                }
              </div>

            </Link>

            <Link
              to="/notifications"
              className={
                `
                  flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "notifications" ? activeSideBarPhone : ""}`
              }
            >
              <span className="mr-4">
                <NotificationsOutlined />
              </span>

              <div className="flex justify-between flex-grow items-center">
                <span>{t('sidebar.notifications')}</span>
                {
                  user && user?.data?.notificationsCount > 0 && (
                    <div className="h-6 w-6 flex justify-center items-center text-xs bg-red-500 rounded-full">
                      <span className={` text-white`
                      }>
                        {user?.data?.notificationsCount === 100 ? '99+' : user?.data?.notificationsCount}
                      </span>
                    </div>
                  )

                }
              </div>
            </Link>
          </ul>
        </div>

        <button
          className="flex items-center space-x-4 text-lg font-light text-white"
          onClick={() => handleShow()}
        >
          <CloseOutlined />
          <span>{t('sidebar.close')}</span>
        </button>
      </aside>

      {/* sidebar */}
      <div className="hidden text-sm font-medium text-gray-600 xl:block mr-5 border-r border-gray-300">
        <aside className="flex flex-col mr-5 w-full pr-5">
          <div className="flex-grow">
            <ul className="">
              <Link
                to="/overview"
                className={
                  `
                    flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "overview" ? activeSideBar : ""}`
                }
              >

                <span className="mr-4">
                  <VisibilityOutlined />
                </span>
                <div className="flex justify-between flex-grow">
                  <span>{t('sidebar.overview')}</span>
                </div>

              </Link>

              <Link
                to="/drafts"
                className={
                  `
                    flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "draft services" ? activeSideBar : ""}`
                }
              >
                <span className="mr-4">
                  <DraftsOutlined />
                </span>

                <div className="flex justify-between flex-grow">
                  <span>{t('sidebar.draft_services')}</span>
                  {/* <span className={
                    `rounded-full h-5 w-5 flex justify-center items-center text-xs" ${activeField.toLowerCase() === "communications" ? "bg-white text-gray-600" : "bg-red-500 text-white"}`
                  }>
                    2
                  </span> */}

                </div>
              </Link>

              <Link
                to="/communications"
                className={
                  `
                    flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "communications" ? activeSideBar : ""}`
                }
              >
                <span className="mr-4">
                  <PrintOutlined />
                </span>

                <div className="flex justify-between flex-grow items-center">
                  <span>{t('sidebar.documents')}</span>
                  {
                    user && user?.data?.communicationsCount > 0 && (
                      <div className="h-6 w-6 flex justify-center items-center text-xs bg-red-500 rounded-full">
                        <span className={` text-white`
                        }>
                          {user?.data.communicationsCount === 100 ? '99+' : user?.data.communicationsCount}
                        </span>
                      </div>
                    )
                  }


                </div>

              </Link>

              <Link
                to="/notifications"
                className={
                  `
                  flex items-center py-3 px-1 mb-3 justify-between
                    ${activeField.toLowerCase() === "notifications" ? activeSideBar : ""}`
                }
              >
                <span className="mr-4">
                  <NotificationsOutlined />
                </span>

                <div className="flex justify-between flex-grow items-center">
                  <span>{t('sidebar.notifications')}</span>
                  {
                    user && user?.data?.notificationsCount > 0 && (
                      <div className="h-6 w-6 flex justify-center items-center text-xs bg-red-500 rounded-full">
                        <span className={` text-white`
                        }>
                          {user?.data?.notificationsCount === 100 ? '99+' : user?.data?.notificationsCount}
                        </span>
                      </div>
                    )

                  }
                </div>
              </Link>

            </ul>
          </div>
        </aside>
      </div >
    </>
  );
};

export default Sidebar;
