import React, { useState } from 'react'
import { useQuery } from 'react-query'
import fileDownload from 'js-file-download'
import { Modal, Backdrop, Fade, createStyles, makeStyles, Theme } from '@material-ui/core'
import { AppLoader, Error } from '../../Reusable';
import { getDocumentAction } from 'reduxes/uploads'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);

type Props = {
    handleClose: any,
    open: any
}

const ViewModal: React.FC<Props> = ({ handleClose, open }) => {
    const classes = useStyles();

    const { file } = open;

    const [data, setData] = useState<any>(null)

    const handleDownloadFile = (file: any) => {
        const { link, name } = file;
        fileDownload(link, name);
    }

    const { isLoading, refetch, isError, error } = useQuery(['get document', file], () => {
        return getDocumentAction({ ...file })
    }, {
        retry: false,
        keepPreviousData: true,
        enabled: open?.file ? true : false,
        onSettled: (data, error) => {
            if (error) return error;
            let res = data?.data;

            const { name } = file;

            const fileType = data?.headers["content-type"]

            const blob = new Blob([res], { type: fileType });

            setData({
                fileType,
                name,
                url: fileType.includes("image") || fileType === 'application/pdf' ? URL.createObjectURL(blob) : blob
            })

        }
    })

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={`w-11/12 bg-white rounded outline-none md:p-4 md:w-9/12 h-3/4`}>

                    {
                        (isLoading) ?
                            <div className='flex justify-center h-full items-center'>
                                <AppLoader />
                            </div>
                            :
                            isError ?
                                <div className='flex justify-center h-full items-center'>
                                    <Error error={error} action={() => refetch()} />
                                </div>
                                :
                                <div className='flex justify-center items-center h-full w-full'>
                                    {
                                        data?.fileType === "application/pdf" ?
                                            <iframe src={data?.url} title="preview" className="object-cover w-full h-full" />
                                            :
                                            data?.fileType === "image/jpeg" ?
                                                <img src={data?.url} alt="preview" className="object-contain w-full h-full" />
                                                :
                                                data?.fileType === 'other' ?
                                                    <div className='flex justify-center items-center h-full'>
                                                        <button
                                                            type='button'
                                                            className='text-white buttonsColor px-5 py-3 cursor-pointer rounded'
                                                            onClick={() => handleDownloadFile(data)}
                                                        >
                                                            Download File
                                                        </button>
                                                    </div>
                                                    :
                                                    null

                                    }
                                </div>
                    }

                </div>
            </Fade>
        </Modal>
    )
}

export default ViewModal;
