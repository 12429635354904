import React, { ReactElement, FC } from "react";
import "./Layout.scss";
import { NavBar, Footer } from "../";

interface Props {
  children: ReactElement
}

const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col mainBackground h-screen font-body">
      <NavBar />
      <main className="flex-grow mainBackground md:px-8 px-4">
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default AuthLayout;
