import React, { useEffect } from 'react'
import { useInfiniteQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCookies } from "react-cookie";
import moment from 'moment'

import { AppLoader, Error, NoContent, Sidebar } from '../Reusable'

import { getUserNotificationsAction, updateNofiticationStatusAction } from "../../reduxes/notifications";

import { SocketState } from '../../context'

const emptyNotification = {
    en: 'No notifications yet',
    fr: 'Aucune notification pour le moment',
    rw: 'Nta mamenyesha ahari'
}


const Notifications = () => {

    const navigate = useNavigate()

    const { handleNotificationCount } = SocketState() as any;

    const userState = useSelector((state: any) => state?.users);

    const [cookies] = useCookies(["i18next"]);
    const { i18next: lang } = cookies;

    const updateNotificationMutation = useMutation((values: any) => {
        return updateNofiticationStatusAction(values);
    }, {
        retry: false,
    })

    // when you open an notification, it will update its read status and then open that application
    const handleReadStatus = async (notification: any) => {

        try {
            const obj = {
                id: notification?._id,
                status: 1
            }
            if (notification.readStatus === 0) {
                handleNotificationCount("remove", userState?.user);
                await updateNotificationMutation?.mutateAsync({ ...obj });
            }
            return navigate(`/application/view/${notification.modelId}?status=${notification.type}`)
        } catch (error) {
            return error;
        }

    }

    const { isLoading, isError, data: results, fetchNextPage, error, refetch, isFetching, hasNextPage } = useInfiniteQuery(['filter user notifications'], ({ pageParam = 1 }) => {
        const query = `draw=0&start=${pageParam}&length=${10}&search=`
        return getUserNotificationsAction(query);
    }, {
        select: (data: any) => {
            const combinedPages = data?.pages.reduce((acc: any, page: any) => {
                return [...acc, ...page?.data?.data?.data]
            }, [])

            const meta = data?.pages[0]?.data?.data?.meta;

            return ({
                pages: [...combinedPages],
                pageParams: [meta]
            })
        },
        getNextPageParam: (lastpage, _pages) => {
            const { page, totalPages } = lastpage?.data?.data?.meta;

            if (page < totalPages) {
                return page + 1
            } else {
                return undefined
            }
        }
    })

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const heightScroll = document.documentElement.scrollHeight;
            const topScroll = document.documentElement.scrollTop
            const windowHeight = window.innerHeight;
            if (((windowHeight + topScroll + 1) >= heightScroll) && hasNextPage) {
                fetchNextPage()
            }
        });
        return () => window.removeEventListener("scroll", () => {
            const heightScroll = document.documentElement.scrollHeight;
            const topScroll = document.documentElement.scrollTop
            const windowHeight = window.innerHeight;
            if (((windowHeight + topScroll + 1) >= heightScroll) && hasNextPage) {
                fetchNextPage()
            }
        });
    }, [fetchNextPage, hasNextPage])

    return (
        <div className="flex flex-col xl:flex-row h-full">
            <Sidebar activeField={"notifications"} />
            <div className='flex flex-col flex-grow text-gray-600'>
                {
                    isLoading ?
                        <div className="flex flex-col justify-center items-center h-full">
                            <AppLoader />
                        </div>
                        :
                        isError ?
                            <div className="flex flex-col justify-center items-center h-full">
                                <Error error={error} action={() => refetch()} />
                            </div>
                            :
                            results?.pages?.length === 0 ?
                                <div className="flex flex-col justify-center items-center h-full">
                                    <NoContent />
                                    <span className="text-sm font-body font-medium text-gray-600">{emptyNotification[lang]}</span>
                                </div>
                                :
                                <div className='h-full flex flex-col'>

                                    <ul className="rounded w-full flex-grow">
                                        {
                                            results?.pages?.map((notification: any, index: any) => {
                                                const message = notification.message
                                                const time = moment(notification.updatedAt).fromNow()
                                                return (
                                                    <li
                                                        className={`flex flex-col text-sm text-gray-600 p-3 cursor-pointer rounded mb-2 hover:border-gray-200 ${notification.readStatus === 0 ? 'bg-gray-300' : 'bg-white'}`}
                                                        key={index}
                                                        onClick={() => handleReadStatus(notification)}
                                                    >
                                                        <div className="mb-3 flex justify-between font-bold">
                                                            <span>{notification.type.replace(/[^a-zA-Z0-9]/g, ' ')}</span>
                                                            <span className="text-xs">{time}</span>
                                                        </div>

                                                        {
                                                            message === null ?
                                                                <span>{notification["type"]}</span>
                                                                :
                                                                <span>{message[lang]}</span>
                                                        }

                                                    </li>
                                                )

                                            })
                                        }
                                    </ul>

                                </div>
                }
                {(isFetching && hasNextPage) && (<div className='flex justify-center items-center'>
                    <AppLoader />
                </div>)}
            </div>

        </div>

    )
}

export default Notifications
