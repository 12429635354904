import { FC } from 'react'
import { Tooltip } from '@material-ui/core'
import { Visibility, InfoOutlined } from '@material-ui/icons'

type props = {
    item: any,
    lang: string,
    countryVal: string
}

type FileProps = {
    item: any,
    lang: string,
    index: number,
    handleOpen: any
}

type SelectProps = {
    item: any,
    lang: string
}

export const ViewLocationField: FC<props> = ({ item, lang, countryVal }) => {

    let value = '';
    if (item.name["en"].includes("Country")) {
        value = item.inputValue
    } else {
        const val = item?.inputValue?.length > 0 && JSON.parse(item.inputValue);
        value = val.name;
    }

    return (
        <div className={`${value?.length > 0 ? 'flex flex-col' : 'hidden'}`}>
            <span className='mb-2'>{item.name[lang]}</span>
            <span className={`border rounded p-3 h-12`}>{value}</span>
        </div>
    )


}

export const ViewFileField: FC<FileProps> = ({ item, lang, handleOpen, index }) => {
    return (
        <div className='flex flex-col'>
            <span className=' mb-2'>{item.name[lang]}</span>
            <div className='flex justify-between border rounded-t rounded-b items-center'>
                <Tooltip disableFocusListener disableTouchListener title={
                    <p className='text-sm'>{item.name[lang]}</p>
                }>
                    <span className="text-sm px-5 py-2 truncate cursor-pointer w-3/4">
                        {`${item["attachments"].length} files added`}
                    </span>
                </Tooltip>
                <Tooltip placement='bottom' title={
                    <p className="text-sm">view document</p>
                }>
                    <button
                        type="button"
                        disabled={item["attachments"] && item["attachments"].length > 0 ? false : true}
                        className='px-5 py-3 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg'
                        onClick={() => handleOpen(item["attachments"], {
                            index,
                            showButton: false
                        })}
                    >
                        <Visibility fontSize='small' />
                    </button>
                </Tooltip>
            </div>
        </div>
    )
}

export const ViewSelectField: FC<SelectProps> = ({ item, lang }) => {
    const values = JSON.parse(item.inputValue);
    return (
        <div className='flex flex-col'>
            <span className='mb-2'>{item.name[lang]}</span>
            {
                values.length > 0 ?
                    <div className='flex space-x-2 h-auto p-3 rounded border'>
                        {
                            values.map((el: any, ind: number) => {
                                return (
                                    <span className='rounded  bg-gray-200 px-2' key={ind}>{el.value}</span>
                                )
                            })
                        }
                    </div>
                    :
                    <span className='border rounded p-3 h-12'>{values.value}</span>
            }
        </div>
    )
}

export const ViewFetchField: FC<props> = ({ item, lang, countryVal }) => {
    return (
        <div className='flex flex-col'>
            <span className='font-bold mb-2'>{item.name[lang]}</span>
            <div className='rounded flex justify-between items-center h-12 p-3 border'>
                <span>{`${item["attachments"].length} added report`}</span>
                <Tooltip placement='bottom' title={
                    <p className='text-sm'>{`${item.name[lang]} is added and can only be viewed by the reviewers.`}</p>
                }>
                    <span className='text-gray-600 cursor-pointer'>
                        <InfoOutlined fontSize='small' />
                    </span>
                </Tooltip>


            </div>
        </div>
    )
}

export const ViewNormalField: FC<props> = ({ item, lang, countryVal }) => {
    return (
        <div className='flex flex-col'>
            <span className='font-bold mb-2'>{item.name[lang]}</span>
            <span className='h-12 rounded p-3 border'>
                {item["inputValue"]}
            </span>
        </div>
    )
}
