import React, { useState } from "react";
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from "react-router-dom";
import { EmailOutlined, VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { resetPasschema } from './Schema'
import { resetPasswordAction, ResetPassword as ResetTypes } from "../../../reduxes/user";
import { SnackMessage, ButtonLoader } from '../../Reusable'
import { ErrorHandler } from "utils";

const ResetPassword = () => {

  const location = useLocation();
  const navigate = useNavigate()

  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState<any>(null);

  const [show, setShow] = useState({
    confirm: false,
    new: false
  })

  const token = new URLSearchParams(location.search).get("token");

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handleShow = (type: string) => {
    setShow({ ...show, [type]: !show[type] })
  }

  const { t } = useTranslation();

  const { isLoading, mutateAsync } = useMutation(async (values: ResetTypes) => {
    return await resetPasswordAction(values);
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      const customMessage = <span>
        {message}{" "}
        <button className={`underline ${(message.toLowerCase() === "you have used this password before, please use a new password." || message.toLowerCase() === "your password should not be the same as the old password." ) && 'hidden'}`} onClick={() => navigate("/forgot-password")}> here.</button>
      </span>

      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message: customMessage
      })
    },
    onSuccess: (data) => {
      const response = data?.data;
      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: response?.message
      })
      setTimeout(() => navigate("/login"), 3000)
    }
  })

  return (
    <div className="flex justify-center items-center h-full p-2">
      <div className="flex flex-col text-gray-600 w-full md:w-1/2 lg:w-1/2 xl:w-4/12">
        <div className="mb-8">
          <p className="font-medium text-lg">
            {t('reset.head')}
          </p>
        </div>

        <div className="p-3 bg-white text-gray-500">

          <div className="flex justify-center p-4">
            <EmailOutlined fontSize="large" className="textColor" />
          </div>

          <div className="border-t-2 mb-5"></div>

          <Formik
            initialValues={{
              password: "",
              confirmPass: ""
            }}
            validationSchema={resetPasschema}
            onSubmit={async (values, actions) => {
              const sendValues = {
                password: values["password"],
                token
              }

              try {
                await mutateAsync({ ...sendValues });
              } catch (error) {
                return error;
              }

            }}
          >
            {({ values }) => {
              return (
                <Form autoComplete="off">
                  <div className="flex flex-col mb-5">
                    <label className="font-semibold mb-2">
                      {t('reset.form.label')}
                    </label>
                    <div className="relative flex justify-between h-12">
                      <Field
                        className="w-full h-12 px-3 text-gray-500 bg-white border border-gray-300 rounded outline-none text-xs"
                        name="password"
                        type={show.new ? "text" : "password"}
                        placeholder={t('reset.form.placeholder')}
                      />
                      <span className="absolute cursor-pointer right-2 top-3" onClick={() => handleShow("new")}>
                        {show.new ? <VisibilityOutlined className="text-xl text-gray-400" /> : <VisibilityOffOutlined className="text-xl text-gray-400" />}
                      </span>

                    </div>

                    <ErrorMessage name="password" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
                  </div>

                  <div className="flex flex-col mb-10">
                    <label className="font-semibold mb-2">
                      {t('reset.form.confirmLabel')}
                    </label>
                    <div className="relative flex justify-between h-12">
                      <Field
                        className="w-full px-3 text-gray-500 bg-white border border-gray-300 rounded outline-none text-xs"
                        name="confirmPass"
                        type={show.confirm ? "text" : "password"}
                        placeholder={t('reset.form.confirmPlaceholder')}
                      />
                      <span className="absolute cursor-pointer right-2 top-3" onClick={() => handleShow("confirm")}>
                        {show.confirm ? <VisibilityOutlined className="text-xl text-gray-400" /> : <VisibilityOffOutlined className="text-xl text-gray-400" />}
                      </span>

                    </div>

                    <ErrorMessage name="confirmPass" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
                  </div>


                  <div className="mb-5">
                    <div className="grid md:grid-cols-2 gap-3">
                      <button
                        className="flex justify-center p-3 font-semibold text-white rounded shadow buttonsColor cursor-pointer"
                        type="submit"
                      >
                        {isLoading ? (
                          <ButtonLoader />
                        ) : (
                          `${t('reset.form.reset')}`
                        )}
                      </button>
                      <Link
                        to="/login"
                        className="flex items-center justify-center p-3 text-base font-semibold text-gray-600 border border-gray-400 rounded shadow cursor-pointer"
                      >
                        {t('reset.form.cancel')}
                      </Link>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
    </div>
  );
};

export default ResetPassword;
