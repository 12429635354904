import moment from "moment";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import React, { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

type Props = {
  logistics: any[];
  lang: any;
};

const PaymentDetails: FC<Props> = ({ logistics, lang }) => {
  const { t } = useTranslation();
  const ref = t("table.headers.ref");
  const amount = t("table.headers.amount");
  const applied = t("table.headers.applied");

  const noData = {
    en: "No records found",
    fr: "Aucun enregistrement trouvé",
    rw: "Nta makuru yabonetse",
  };

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const newData = logistics.map((el) => {
      return {
        referenceNumber: el.referenceNumber,
        AmountPaid: `${el.amount} RWF`,
        paidOn: moment(el.createdAt).format("lll"),
      };
    });

    setData(newData);
  }, [logistics]);

  const columns = [
    {
      name: "referenceNumber",
      label: ref,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return <div className="font-body">{value}</div>;
        },
      },
    },
    {
      name: "AmountPaid",
      label: amount,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return <div className="font-body">{value}</div>;
        },
      },
    },
    {
      name: "paidOn",
      label: applied,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return <div className="font-body">{value}</div>;
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filter: false,
    print: false,
    download: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: noData[lang],
      },
    },
    responsive: 'standard',
    enableNestedDataAccess: ".",
    elevation: 0,
    pagination: false,
    serverSide: true,
    count: logistics.length,
    rowsPerPage: logistics.length,
  };

  return (
    <div className="w-full bg-white h-3/4 p-4 lg:w-1/2 z-0 font-body overflow-x-auto">
      <MUIDataTable title="" data={data} columns={columns} options={options} />
    </div>
  );
};

export default PaymentDetails;
