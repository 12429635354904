import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AddCircleOutlineOutlined, InboxOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";

import { Sidebar } from '../Reusable'
import { AllMessages, NewMessage } from './Contents'

import { resetStateAction } from '../../reduxes/communications'

const Communication = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [activeSelection, setActiveSelection] = useState("all")

  const communicationState = useSelector((state: any) => state?.communication);

  const handleSelection = (active: string) => {
    if (active === "all") setActiveSelection("all")
    if (active === "new") setActiveSelection("new")

    const { parent, ...rest } = communicationState;
    resetStateAction({ ...rest })(dispatch);
  }

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  return (
    <div className="flex flex-col xl:flex-row h-full">
      <Sidebar activeField={"communications"} />

      <div className="text-gray-600 flex-grow xl:w-1">

        <div className="flex flex-col h-full">
          <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:justify-between  mb-5">

            <div
              className={`flex items-center cursor-pointer p-3 rounded ${activeSelection === "all" ? "asideColor text-white" : "bg-gray-200"}`}
              onClick={() => handleSelection("all")}
            >
              <span className="text-sm">
                <InboxOutlined fontSize="small" />
              </span>

              <span className="px-3">{t('communication.buttons.all')}</span>
            </div>


            <div
              className={` flex items-center cursor-pointer p-3 rounded ${activeSelection === "new" ? "asideColor text-white" : "bg-gray-200"}`}
              onClick={() => handleSelection("new")}
            >
              <span className="text-sm">
                <AddCircleOutlineOutlined fontSize="small" />
              </span>

              <span className="px-3 items-center">{t('communication.buttons.new')}</span>
            </div>
          </div>
          <div className="flex-grow rounded">
            {
              activeSelection === 'new' ?
                <NewMessage lang={lang} setActiveSelection={setActiveSelection} />
                :
                <AllMessages lang={lang} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Communication;
