import React, { FC } from 'react'
import SideList from './SideList'

import { Messages } from './Messages'

type Props = {
  lang: string
}

const AllMessages: FC<Props> = ({ lang }) => {

  return (
    <div className="flex h-full">
      <SideList />
      <Messages lang={lang} />
    </div>
  )
}

export default AllMessages
