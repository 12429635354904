import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useLocation } from 'react-router-dom'

import { MailOutlined } from "@material-ui/icons";
import queryString from 'query-string';

import { actOnEventAction } from "../../reduxes/events"
import { AppLoader } from 'components/Reusable';
import { ErrorHandler } from 'utils';

const Events = () => {

    const navigate = useNavigate();

    const location = useLocation();

    const [message, setMessage] = useState("");
    const [showButton, setShowButton] = useState(false)


    const { action, token } = queryString.parse(location.search);

    const handleTryAgain = () => window.location.reload();

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return actOnEventAction({ ...values })
    }, {
        retry: false,
        onSettled: (data, error) => {
            if (data) {
                setMessage(
                    action !== 'maybe' 
                        ? 
                        `Event invitation is ${action}ed successfully, to revert your decision please go back to your email and choose otherwise.`
                        :
                        `Event invitation is in tentative mode, to revert your decision please go back to your email and choose otherwise.`
                    )
            }

            if (error) {
                const { message: res } = ErrorHandler(error, 'server');
                setMessage(res);

                if (res.toLowerCase().includes('something')) setShowButton(true);
            }
        }
    })

    const handleEventAction = useCallback(async() => {
        await mutateAsync({
            token,
            action
        })
    }, [token, action, mutateAsync])

    useEffect(() => {
        handleEventAction();
    }, [handleEventAction])

    if (isLoading) {
        return (
            <div className='h-full flex justify-center items-center'>
                <AppLoader />
            </div>
        )
    }

    return (
        <div className='h-full flex justify-center items-center'>

            <div className="flex flex-col w-max">
                <div className='p-2 bg-white'>
                    <div className="flex justify-center items-center p-4">
                        <MailOutlined style={{ fontSize: "3rem", color: "#89724E" }} />
                    </div>

                    <div className='flex justify-center items-center'>
                        <span className="mb-5 text-lg text-gray-600 font-medium">
                            {message}
                        </span>
                    </div>

                    <div className='flex justify-center items-center'>
                        {
                            showButton ?
                                <button className='buttonsColor text-white rounded px-8 py-2' onClick={() => handleTryAgain() }>Try again</button>
                                :
                                <button className='buttonsColor text-white rounded px-8 py-2' onClick={() => navigate('/login')}>Go back</button>
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Events
