import React, { useState,useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from 'react-query'

import { NavBar, Footer, AppLoader, IdleModal } from "../";
import { ServiceRender } from "../../Services";

import { getAllServicesUnderDepartmentTable } from '../../../reduxes/service';
import { getLoggedInUserAction, UserTypes } from 'reduxes/user';

import { FilterListOutlined, RadioButtonCheckedOutlined } from "@material-ui/icons";
import { ClickAwayListener } from '@material-ui/core'
import { useTranslation } from "react-i18next";

import { useCookies } from "react-cookie";
import { useIdleTimeout } from 'context';


const filterOptions = [
    {
        name: {
            en: 'Institution',
            fr: 'Institution',
            rw: 'Ikigo'
        },
        value: 'company'
    },
    {
        name: {
            en: 'Individual',
            fr: 'Individue',
            rw: 'Umuntu ku giti cye'
        },
        value: 'individual'
    },
    {
        name: {
            en: 'Both',
            fr: 'Tous les deux',
            rw: 'Byombi'
        },
        value: 'both'
    },
    {
        name: {
            en: 'All',
            fr: 'Tout',
            rw: 'Zose'
        },
        value: 'all'
    }
]

const ServicesLayout: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const userState = useSelector((state: any) => state?.users);

    const access_token = localStorage.getItem('app_access_token') as string;

    const { t } = useTranslation();

    const [search, setSearch] = useState("")
    const [open, setOpen] = useState(false)
    const [openIdleModal, setOpenIdleModal] = useState(false);
    const [checkUser, setCheckUser] = useState(false)

    const [filter, setFilter] = useState<any>(null);

    const [cookies] = useCookies(["i18next"]);

    const { i18next: lang } = cookies;

    const handleIdle = () => {
        setOpenIdleModal(checkUser);
    } 

    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle })

    const handleClickAwayEvent = () => {
        setOpen(false);
    };

    const handleClickEvent = () => {
        setOpen((prev) => !prev);
    };

    const handleInputText = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e?.currentTarget?.value;
        setSearch(value)
    };

    const handleFilter = (value: string) => {
        setFilter(value);
        if (search?.length > 0) setSearch('')
        setOpen(false)
    }


    const { isLoading } = useQuery(['user', access_token], () => {
        return getLoggedInUserAction();
    }, {
        retry: false,
        enabled: !userState?.user && access_token?.length > 0,
        onError: (error: any) => {
            return error;
        },
        onSuccess: (data: any) => {
            const response = data?.data;
            const dispatchData = {
                ...response,
                data: {
                    ...response?.data,
                    profileURL: {
                        url: '',
                        isLoading: false
                    }
                }
            }
            dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: dispatchData } });
        },
    })

    const fetchServicesQuery = useQuery(['all services', search, lang, filter?.value], () => {
        const accountType = userState?.user?.data?.accountType ? userState?.user?.data?.accountType : '';
        const query = `draw=${0}&&search=${search}&&lang=${lang}&&filter=${!filter?.value ? '' : filter?.value === 'all' ? '' : filter?.value}&&accountType=${accountType}`;
        return getAllServicesUnderDepartmentTable(query);
    }, {
        retry: false,
        keepPreviousData: true,
        enabled: access_token ? userState?.user?.error === false : true
    })

    useEffect(() => {
        if (!access_token && checkUser) {
          dispatch({ type: UserTypes.GetLoggedInUser, payload: { data: null } });
          navigate('/login', {
            state: {
              from: `${location?.pathname}${location?.search}`
            }
          })
        }
      }, [access_token, dispatch, location, navigate, checkUser])

    useEffect(() => {
        const { user } = userState;
        if (user) setCheckUser(true);
    }, [userState])

    if (isLoading) {
        return (
            <div className="flex justify-center items-center mainBackground h-screen">
                <AppLoader />
            </div>
        )
    }

    return (
        <div className="flex flex-col mainBackground h-screen font-body">
            <NavBar />
            <main className="flex-grow mainBackground">
                <div className='h-full p-3 md:px-8 md:py-5 mb-5 flex flex-col'>
                    <div className="flex justify-center mb-3">
                        <div className="bg-white p-2 flex w-full lg:w-1/2 outline-none rounded shadow-md">

                            <input
                                className="w-full rounded p-2 outline-none text-gray-600"
                                type="text"
                                value={search}
                                placeholder={`${t('service.search_text')}...`}
                                onChange={(e) => handleInputText(e)}
                            />

                            <ClickAwayListener onClickAway={() => handleClickAwayEvent()}>
                                <div className='w-1/3 relative flex justify-end items-center text-gray-800'>
                                    <div
                                        className='flex bg-gray-300 w-full justify-between items-center h-full px-3 rounded cursor-pointer'
                                        onClick={() => handleClickEvent()}
                                    >
                                        <span>{!filter ? 'Filter by' : filter?.name[lang]}</span>
                                        <FilterListOutlined />
                                    </div>


                                    {open && <div className='bg-gray-300 absolute top-14 left-0 w-full rounded flex flex-col'>
                                        {
                                            filterOptions?.filter((el) => {
                                                if (userState?.user && !userState?.user?.error) {
                                                    const accountType = userState?.user?.data?.accountType === "company" ? "institution" : "individual";
                                                    return el.name["en"].toLowerCase() === accountType || el.value === "both" || el?.value === 'all'
                                                }
                                                return el;
                                            }).map((opt: any, ind: number) => {
                                                return (
                                                    <button
                                                        className={`flex justify-between items-center p-2 cursor-pointer ${opt?.value === filter?.value ? 'bg-gray-100' : ''}`}
                                                        onClick={() => handleFilter(opt)}
                                                        key={ind}
                                                    >
                                                        <span className='text-lg'>
                                                            {opt.name[lang]}
                                                        </span>

                                                        {opt?.value === filter?.value && <RadioButtonCheckedOutlined />}

                                                    </button>

                                                )
                                            })
                                        }
                                    </div>}

                                </div>
                            </ClickAwayListener>


                        </div>
                    </div>

                    <div className='flex-grow'>
                        <ServiceRender
                            lang={lang}
                            query={fetchServicesQuery}
                        />
                    </div>

                </div>

            </main>

            {openIdleModal && <IdleModal
                open={openIdleModal}
                handleClose={() => setOpenIdleModal(false)}
                idleTimer={idleTimer}
            />}

            <Footer />
        </div>
    )
}

export default ServicesLayout
