import { UploadTypeAction, UploadTypes } from '.';

const initialState: any = {
    downloadGuide: {}
};

export const uploadsReducer = (state = initialState, action: UploadTypeAction) => {
    switch (action.type) {
        case UploadTypes.UploadDocument:
            return { ...state, uploadDocument: action.payload.data, loading: false };

        case UploadTypes.UploadManyDocuments:
            return { ...state, uploadManyDocument: action.payload.data, loading: false };

        case UploadTypes.GetDocument:
            return { ...state, getDocument: action.payload.data, loading: false };

        case UploadTypes.DownloadUserGuide:
            return { ...state, downloadGuide: action.payload.data };

        case UploadTypes.GetManyDocuments:
            return { ...state, documents: action.payload.data, loading: false };

        case UploadTypes.ResetPayload:
            return { ...action.payload.data };

        case UploadTypes.Loading:
            return { ...state, loading: true };

        case UploadTypes.Errors:
            return { ...state, errors: action.payload.errors };
        default:
            return state;
    }
};
