import { Dispatch } from "redux";
import { ApplicationsTypes } from ".";
import { ApiEndpoints, ActionHandler, ErrorHandler, RequestHandler } from "utils";

export const createApplicationAction = (data: any) => {
  return RequestHandler({
    url: ApiEndpoints.createApplication,
    method: 'POST',
    data
  })
};

export const updateApplicationAction = (id: string, values: any) => {
  return RequestHandler({
    url: `${ApiEndpoints.updateApplication}/${id}`,
    method: 'PUT',
    data: values
  })
}

export const getApplicationByID = (id: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.getApplicationById}/${id}`,
    method: 'GET'
  })
}

export const cancelApplication = (id: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.cancelApplication}/${id}`,
    method: 'DELETE'
  })

}

export const getAllUserApplications = () => (dispatch: Dispatch) => {
  dispatch({ type: ApplicationsTypes.Loading });
  return ActionHandler(
    { url: `${ApiEndpoints.getAllUserApplications}`, method: "GET", dispatch },
    (res) => {
      const { data } = res;
      dispatch({ type: ApplicationsTypes.GetAllUserApplications, payload: { data: data?.data?.statistics } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server')
      dispatch({ type: ApplicationsTypes.GetAllUserApplications, payload: { data } });
    }
  );
}


export const filterApplications = (status: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.getFilteredApplication}${status}`,
    method: 'GET'
  })
}

export const fetchExternalReports = (values: any, type: string) => {
  let url;
  if (type === 'nida') url = ApiEndpoints.getNidaDetails;
  if (type === 'rra') url = ApiEndpoints.getRRAdetails;
  if (type === 'rdb') url = ApiEndpoints.getRDBdetails;
  if (type === 'crb') url = ApiEndpoints.getCRBdetails;
  if (type === 'rca') url = ApiEndpoints.getRCAdetails;

  return RequestHandler({
    url,
    method: 'POST',
    data: values
  })
}

export const getApplicationStats = () => (dispatch: Dispatch) => {
  dispatch({ type: ApplicationsTypes.Loading });
  return ActionHandler(
    { url: `${ApiEndpoints.getApplicationStats}`, method: "GET", dispatch },
    (res) => {
      const { data } = res;
      dispatch({ type: ApplicationsTypes.GetApplicationStats, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server')
      dispatch({ type: ApplicationsTypes.GetApplicationStats, payload: { data } });
    }
  );
}

export const resetStateAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: ApplicationsTypes.ResetPayload, payload: { data } })
}
