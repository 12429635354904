export enum UserTypes {
  login = "UserTypes/Login",
  ForgotPassword = "UserTypes/ForgotPassword",
  ResendEmail = "UserTypes/ResendEmail",
  Register = "UserTypes/Register",
  VerifyAccount = "UserTypes/VerifyAccount",
  VerifyPhoneNumber = "UserTypes/VerifyPhoneNumber",
  ResetPassword = "UserTypes/ResetPassword",
  ChangePassword = "UserTypes/ChangePassword",
  GetLoggedInUser = "UserTypes/GetLoggedInUser",
  UploadProfilePicture = "UserTypes/UploadProfilePicture",
  RemoveProfilePicture = "UserTypes/RemoveProfilePicture",
  UploadPassport = "UserTypes/UploadPassport",
  UpdateProfile = "UserTypes/UpdateProfile",
  GetUserTypes = "UserTypes/GetUserTypes",
  GetOTP = "UserTypes/GetOTP",
  SearchUser = "UserTypes/SearchUser",
  CompleteAccount = "UserTypes/CompleteAccount",
  ReadUserGuide = "UserTypes/ReadUserGuide",
  UploadDocument = "UserTypes/UploadDocument",
  GetUserNotifications = "UserTypes/GetUserNotifications",
  UpdateNotificationStatus = "UserTypes/UpdateNotificationStatus",
  UpdateUserDetails = "UserTypes/UpdateNotificationStatusFail",
  UpdateUserProfileCompletion = "UserTypes/UpdateUserProfileCompletion",
  ResetPayload = "UserTypes/ResetPayload",
  Errors = "UserTypes/Errors",
  loading = "UserTypes/Loading",
}

interface Login {
  type: typeof UserTypes.login;
  payload: {
    data: any;
  };
}


interface ForgotPassword {
  type: typeof UserTypes.ForgotPassword;
  payload: {
    data: any;
  };
}

interface ResendEmail {
  type: typeof UserTypes.ResendEmail;
  payload: {
    data: any;
  };
}

interface Register {
  type: typeof UserTypes.Register;
  payload: {
    data: any;
  };
}

interface VerifyAccount {
  type: typeof UserTypes.VerifyAccount,
  payload: {
    data: any
  }
}

interface VerifyPhoneNumber {
  type: typeof UserTypes.VerifyPhoneNumber,
  payload: {
    data: any
  }
}

interface ResetPassword {
  type: typeof UserTypes.ResetPassword,
  payload: {
    data: any
  }
}

interface ChangePassword {
  type: typeof UserTypes.ChangePassword,
  payload: {
    data: any
  }
}

interface GetLoggedInUser {
  type: typeof UserTypes.GetLoggedInUser,
  payload: {
    data: any,
    reload: boolean
  }
}

interface GetUserTypes {
  type: typeof UserTypes.GetUserTypes,
  payload: { data: any }
}

interface ReadUserGuide {
  type: typeof UserTypes.ReadUserGuide,
  payload: { data: any }
}

interface UploadProfilePicture {
  type: typeof UserTypes.UploadProfilePicture,
  payload: {
    data: any
  }
}

interface RemoveProfilePicture {
  type: typeof UserTypes.RemoveProfilePicture,
  payload: {
    data: any
  }
}

interface UploadPassport {
  type: typeof UserTypes.UploadPassport,
  payload: {
    data: any
  }
}

interface UploadDocument {
  type: typeof UserTypes.UploadDocument,
  payload: {
    data: any
  }
}
interface GetOTP {
  type: typeof UserTypes.GetOTP,
  payload: {
    data: any
  }
}

interface SearchUser {
  type: typeof UserTypes.SearchUser,
  payload: {
    data: any
  }
}

interface CompleteAccount {
  type: typeof UserTypes.CompleteAccount,
  payload: {
    data: any
  }
}
interface GetUserNotifications {
  type: typeof UserTypes.GetUserNotifications,
  payload: {
    data: any
  }
}
interface UpdateNotificationStatus {
  type: typeof UserTypes.UpdateNotificationStatus,
  payload: {
    data: any
  }
}

interface UpdateProfile {
  type: typeof UserTypes.UpdateProfile,
  payload: {
    data: any,
    reload: boolean
  }
}
interface Errors {
  type: typeof UserTypes.Errors;
  payload: {
    errors: any;
  };
}

interface Loading {
  type: typeof UserTypes.loading;
  payload: {
    data: any;
  };
}

interface ResetPayload {
  type: typeof UserTypes.ResetPayload;
  payload: { data: any };
}
interface UpdateUserProfileCompletion {
  type: typeof UserTypes.UpdateUserProfileCompletion;
  payload: {
    data: any;
  };
}

export type UserTypeAction =
  | Errors
  | Loading
  | Login
  | ForgotPassword
  | ResendEmail
  | Register
  | VerifyAccount
  | VerifyPhoneNumber
  | ResetPassword
  | ChangePassword
  | ReadUserGuide
  | GetLoggedInUser
  | UploadProfilePicture
  | RemoveProfilePicture
  | UploadPassport
  | GetOTP
  | SearchUser
  | CompleteAccount
  | GetUserNotifications
  | UpdateNotificationStatus
  | UploadDocument
  | GetUserTypes
  | UpdateProfile
  | UpdateUserProfileCompletion
  | ResetPayload
