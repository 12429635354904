import React, { FC, useState } from 'react'

import { Tooltip } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import { ViewModal } from 'components/Reusable'

type Props = {
    documents: any[]
}

const OtherDocs: FC<Props> = ({ documents }) => {

    const [openFile, setOpenFile] = useState({
        open: false,
        file: null
    });

    const handleOpen = async (attach: any) => {
        setOpenFile({
            open: true,
            file: { ...attach }
        })
    }

    const handleClose = () => {
        setOpenFile({
            open: false,
            file: null
        });
    };

    return (
        <>
            {
                ((documents && documents.length === 0) || !documents)?
                    <div className='flex justify-center items-center h-full text-lg'>
                        No other documents added
                    </div>
                    :
                    <div className='grid md:grid-cols-3 gap-3'>
                        {
                            documents && documents.map((item: any, index: number) => {
                                return (
                                    <div className='flex flex-col' key={index}>
                                        <span className="text-base mb-3 font-bold text-gray-600">
                                            {item.name}
                                        </span>
                                        <div className='flex justify-between border rounded-t rounded-b items-center' key={index}>
                                            <Tooltip disableFocusListener disableTouchListener title={
                                                <p className='text-sm'>{item.name}</p>
                                            }>
                                                <span className="text-sm px-5 py-2 truncate cursor-pointer w-3/4">
                                                    {item["name"]}
                                                </span>
                                            </Tooltip>
                                            <Tooltip placement='bottom' title={
                                                <p className="text-sm">view document</p>
                                            }>
                                                <span
                                                    className='px-5 py-3 text-white rounded-tr rounded-br cursor-pointer custom-yellow-bg'
                                                    onClick={() => handleOpen(item)}
                                                >
                                                    <Visibility fontSize='small' />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>

                                )
                            })
                        }
                    </div>
            }
            
            {openFile?.open && <ViewModal open={openFile} handleClose={handleClose} />}

        </>

    )
}

export default OtherDocs;
