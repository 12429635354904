import React, { ChangeEvent, FC, useState, useRef, UIEvent } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner'

import { AppLoader, Error } from '../../Reusable'

import { getAllParentsAction, openParentAction } from '../../../reduxes/communications'

import { SocketState } from '../../../context'


const SideList: FC = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { socket } = SocketState() as any;

    const divRef = useRef<any>(null);

    const [search, setSearch] = useState("");

    const communicationState = useSelector((state: any) => state.communication);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>, items: any) => {
        const val = e.target.value;
        setSearch(val)
    }

    const handleScroll = (e: UIEvent<HTMLUListElement>, props: any) => {
        let scrollTop = e.currentTarget.scrollTop
        let scrollHeight = e.currentTarget.scrollHeight
        let clientHeight = e.currentTarget.clientHeight;

        const { fetchNextPage, hasNextPage } = props;

        if ((scrollTop + clientHeight >= scrollHeight) && hasNextPage) {
            fetchNextPage()
        }
    };

    const handleOpenMessage = (message: any) => {
        socket?.emit('join chat', message)
        openParentAction(message?.id)(dispatch)
    }

    const { isLoading, isError, data: results, fetchNextPage, error, refetch, isFetching, hasNextPage } = useInfiniteQuery(['all parents', search], ({ pageParam = 1 }) => {
        const query = `search=${search}&start=${pageParam}&length=${10}`
        return getAllParentsAction(query);
    }, {
        select: (data: any) => {
            const combinedPages = data?.pages.reduce((acc: any, page: any) => {
                return [...acc, ...page?.data?.data?.data]
            }, [])

            const meta = data?.pages[0]?.data?.data?.meta;

            return {
                pages: [...combinedPages],
                pageParams: [meta],
            };
        },
        getNextPageParam: (lastpage, _pages) => {
            const { page, totalPages } = lastpage?.data?.data?.meta;

            if (page < totalPages) {
                return page + 1;
            } else {
                return undefined;
            }
        },

    })

    return (
        <div className={`w-full h-full flex flex-col xl:w-1/4 xl:border-r bg-gray-200 border-gray-300 ${communicationState?.parent ? 'hidden xl:block' : ''}`}>

            <div className="m-3 h-12">
                <input
                    className="rounded bg-gray-50 focus:outline-none h-full px-3 w-full"
                    placeholder="Search by subject"
                    name="search"
                    onChange={(e) => handleSearch(e, results?.pages)}
                />
            </div>

            <ul className='overflow-x-auto flex-grow communication-side' ref={divRef} onScroll={(e) => handleScroll(e, {
                fetchNextPage,
                hasNextPage
            })}>
                {
                    (isLoading) ?
                        <span className='flex justify-center items-center h-full'>
                            <AppLoader />
                        </span>
                        :
                        (isError) ?
                            <span className='flex justify-center items-center h-full'>
                                <Error error={error} action={() => refetch()} />
                            </span>
                            :
                            results?.pages?.length === 0 ?
                                <span className="flex justify-center items-center h-full">
                                    {t('communication.buttons.empty')}
                                </span>
                                :
                                results?.pages?.map((message: any, index: number) => {
                                    let recipientName;

                                    if ((message?.initiator?.role === "super-admin" || message?.initiator?.role === 'other' ) || !message?.initiator?.role) {
                                        recipientName = message?.initiator?.name || message?.initiator?.email;
                                    } 
                                    else {
                                        recipientName = message?.initiator?.division ? JSON.parse(message?.initiator?.division?.name)["en"] : JSON.parse(message?.initiator?.department?.name)["en"]
                                    }
                                    const time = moment(message.updatedAt).fromNow()

                                    return (
                                        <li
                                            className={`flex flex-col text-sm text-gray-600 p-3 cursor-pointer border-b border-gray-300 ${communicationState?.parent?.id === message?.id ? 'bg-gray-300' : 'bg-white'}`}
                                            onClick={() => handleOpenMessage(message)}
                                            key={index}
                                        >
                                            <div className="mb-3 flex justify-between items-center font-bold">
                                                <span>{recipientName}</span>
                                                <span className="text-xs">{time}</span>
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-medium">{message.subject}</span>

                                                {
                                                    (message.unreadCount > 0) && (<span className="stepsColor text-white rounded-full text-sm w-6 h-6 flex justify-center items-center">{message.unreadCount}</span>)
                                                }
                                            </div>

                                        </li>
                                    )
                                })

                }
            </ul>

            {(isFetching && hasNextPage) && (<div className='flex justify-center items-center'>
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                />
            </div>)}

        </div>
    )
}

export default SideList
