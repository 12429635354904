import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { districtsJson, sectorsJson, cellsJson, provincesJson, villagesJson } from '../../../../../data'
import countryList from 'react-select-country-list';

type Props = {
    props: any
}

const LocationField: FC<Props> = ({ props }) => {
    let { name, validation, id } = props["schema"]
    const { schemaName, index, formValues, inModal, formik, lang } = props

    const { setFieldValue } = formik;

    const getValueInModal = (name: string, values: any) => {
        for (const item in values) {
            if (values[item]["name"]["en"].toLowerCase().includes(name)) return values[item]["inputValue"]
        }
    }

    const country = !inModal ? formValues?.find((el: any) => el?.name["en"].toLowerCase().includes("country"))?.inputValue : getValueInModal('country', formValues);
    const province = !inModal ? formValues?.find((el: any) => el?.name["en"].toLowerCase().includes("province"))?.inputValue : getValueInModal('province', formValues);
    const district = !inModal ? formValues?.find((el: any) => el?.name["en"].toLowerCase().includes("district"))?.inputValue : getValueInModal('district', formValues);
    const sector = !inModal ? formValues?.find((el: any) => el?.name["en"].toLowerCase().includes("sector"))?.inputValue : getValueInModal('sector', formValues);
    const cell = !inModal ? formValues?.find((el: any) => el?.name["en"].toLowerCase().includes("cell"))?.inputValue : getValueInModal('cell', formValues);
    const village = !inModal ? formValues?.find((el: any) => el?.name["en"].toLowerCase().includes("village"))?.inputValue : getValueInModal('village', formValues);

    const provinces = [...provincesJson];

    const [districts, setDistricts] = useState<any>([])
    const [sectors, setSectors] = useState<any>([])
    const [cells, setCells] = useState<any>([])
    const [villages, setVillages] = useState<any>([])

    const handleChange = (e: ChangeEvent<HTMLInputElement>, labelVal: string) => {
        const val = e.target.value;
        const fieldNam = inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`

        setFieldValue(fieldNam, val)

        if (labelVal.toLowerCase() === "country") {
            if (!inModal) {
                const addresses = formValues.filter((item: any) => item.inputType === "address" && item.name["en"] !== "Country")
                const isRwanda = val === "Rwanda";
                for (const address of addresses) {
                    const ind = formValues.indexOf(address);
                    const fieldName = `${schemaName}[${ind}]["validation"]["required"]["value"]`
                    const showField = `${schemaName}[${ind}]["show"]`

                    setFieldValue(fieldName, isRwanda)
                    setFieldValue(showField, isRwanda)
                }
            } else {
                const isRwanda = val === "Rwanda";
                for (const key in formValues) {
                    const address = formValues[key];
                    if (address.inputType === 'address') {
                        const fieldName = `${address.id}["validation"]["required"]["value"]`
                        const showField = `${address.id}["show"]`
                        const name = address.name["en"];
                        setFieldValue(fieldName, isRwanda);
                        name.toLowerCase() !== "country" && setFieldValue(showField, isRwanda)
                    }
                }

            }
        }

    }

    useEffect(() => {
        const provinceID = (province && province?.length > 0) && provincesJson.find((item) => item.id === JSON.parse(province!)["id"])?.id
        const districtss = districtsJson.filter((item) => item.province_id === provinceID)
        setDistricts(districtss)

        const districtID = district && districtsJson.find((item) => item.id === JSON.parse(district!)["id"])?.id
        const sect = sectorsJson.filter((item) => item.district_id === districtID)
        setSectors(sect)

        const sectorID = sector && sectorsJson.find((item) => item.id === JSON.parse(sector!)["id"])?.id
        const cel = cellsJson.filter((item) => item.sector_id === sectorID)
        setCells(cel)

        const cellID = cell && cellsJson.find((item) => item.id === JSON.parse(cell!)["id"])?.id
        const vil = villagesJson.filter((item) => item.cell_id === cellID)
        setVillages(vil)

    }, [province, district, sector, cell, village, country, name])


    const getOptions = (name: string) => {
        let options: any = [];
        if (name.toLowerCase().includes('country')) options = countryList().getData().map((item: any) => {
            const modifyObj = {
                value: item.label,
                label: item.label
            }

            return modifyObj
        })
        if (name.toLowerCase().includes('province')) options = provinces
        if (name.toLowerCase().includes('district')) options = districts
        if (name.toLowerCase().includes('sector')) options = sectors
        if (name.toLowerCase().includes('cell')) options = cells
        if (name.toLowerCase().includes('village')) options = villages

        const checkCountry = name.toLowerCase().includes("country");

        return options.map((item: any, index: number) => {
            return (
                <option value={checkCountry ? item.label : JSON.stringify(item)} key={index}>{checkCountry ? item.label : item.name}</option>
            )
        });

    }

    return (
        <div className={`flex flex-col text-gray-600 text-sm`} key={index}>
            {name[lang] && <label htmlFor={name[lang]}>{name[lang]}
                {
                    validation.required.value && <span className="text-red-500">*</span>
                }
            </label>}

            <Field
                className="w-full h-10 px-3 mt-2 text-xs text-gray-500 border rounded outline-none bg-white"
                name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                as="select"
                onChange={(e: any) => handleChange(e, name["en"])}
            >
                <option value="">{name[lang]}</option>
                {getOptions(name["en"])}
            </Field>

            <ErrorMessage name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`} render={msg => <div style={{ color: 'red' }} > {msg}</div >} />
        </div>

    )
}

export default LocationField
