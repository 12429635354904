export enum ApplicationsTypes {
  ApplyService = 'ApplicationsTypes/ApplyService',
  UploadDoc="ApplicationsTypes/UploadDoc",
  GetUploadedFile="ApplicationsTypes/UploadedFile",
  GetAllUserApplications = "ApplicationsTypes/GetAllUserApplications",
  GetApplication="ApplicationsTypes/GetApplication",
  UpdateApplication="ApplicationsTypes/UpdateApplication",
  FilteredApplication="ApplicationsTypes/FilteredApplication",
  GetApplicationStats = "ApplicationsTypes/GetApplicationStats",
  CancelApplication="ApplicationsTypes/CancelApplication",
  ResetPayload="ApplicationsTypes/ResetPayload",
  Loading = 'ApplicationsTypes/Loading',
  Errors = "ApplicationsTypes/Errors"
}

interface ApplyService {
  type: typeof ApplicationsTypes.ApplyService;
  payload: {
    application: any;
  };
}

interface UploadDoc {
  type: typeof ApplicationsTypes.UploadDoc;
  payload: {
    document: any;
  };
}

interface GetUploadedFile {
  type: typeof ApplicationsTypes.GetUploadedFile;
  payload: {
    url: string;
    type: string
  };
}

interface GetAllUserApplications {
  type: typeof ApplicationsTypes.GetAllUserApplications,
  payload: {
    applications: any;
  }
}

interface GetApplication {
  type: typeof ApplicationsTypes.GetApplication,
  payload: {
    applications: any;
  }
}

interface UpdateApplication {
  type: typeof ApplicationsTypes.UpdateApplication,
  payload: {
    applications: any;
  }
}

interface FilteredApplication {
  type: typeof ApplicationsTypes.FilteredApplication,
  payload: {
    applications: any;
  }
}

interface GetApplicationlStats {
  type: typeof ApplicationsTypes.GetApplicationStats,
  payload: {
    applications: any;
  }
}

interface ResetPayload {
  type: typeof ApplicationsTypes.ResetPayload;
  payload: { data: any };
}

interface CancelApplication {
  type: typeof ApplicationsTypes.CancelApplication,
  payload: {
    applications: any;
  }
}
interface Loading {
  type: typeof ApplicationsTypes.Loading;
  payload: {
    loading: boolean;
  };
}

interface Errors {
  type: typeof ApplicationsTypes.Errors;
  payload: {
    errors: any;
  };
}

export type UserTypeAction =
  | ApplyService
  | UploadDoc
  | GetUploadedFile
  | GetAllUserApplications
  | GetApplication
  | UpdateApplication
  | ResetPayload
  | FilteredApplication
  | GetApplicationlStats
  | CancelApplication
  | Loading
  | Errors
