import * as Yup from 'yup';

export const initialValues = {
    name: "",
    email: "",
    type: "",
    message: "",
    attachments: []
}

export const schema = Yup.object().shape({
    name: Yup.string().required('Enter your name'),
    email: Yup.string().email('Invalid email').required('Enter your email'),
    type: Yup.string().required('Select your feedback type'),
    message: Yup.string().required('Enter your message'),
    attachments: Yup.array().nullable()
})

export const messageTypeOptions = [
    // {
    //     id: 1,
    //     name: {
    //         en: "Claim",
    //         fr: "Plainte",
    //         rw: "Ibirego"
    //     }
    // },
    {
        id: 1,
        name: {
            en: "Request",
            fr: "Demande",
            rw: "Ubusabe"
        }
    },
    // {
    //     id: 3,
    //     name: {
    //         en: "Invoice",
    //         fr: "Facture",
    //         rw: "Kwishyuza"
    //     }
    // },
    // {
    //     id: 4,
    //     name: {
    //         en: "Reports",
    //         fr: "Rapports",
    //         rw: "Raporo"
    //     }
    // },
    {
        id: 2,
        name: {
            en: "System support",
            fr: "Prise en charge du système",
            rw: "Gufashwa ibijyanye na sisitemu"
        }
    },
    {
        id: 3,
        name: {
            en: "Others",
            fr: "Autres",
            rw: "Izindi"
        }
    }
]

// used to display validation error in different languages
export const errorMessages = {
    to: {
        en: 'Enter your name',
        fr: 'Entrez votre nom',
        rw: 'Injiza izina ryawe'
    },
    email: {
        en: 'Enter your email',
        fr: 'Entrez votre email',
        rw: 'Injiza imeyili ryawe'
    },
    messageType: {
        en: 'Provide the communication type',
        fr: 'Indiquez le type de communication',
        rw: 'Tanga ubwoko bwitumanaho'
    },
    message: {
        en: 'Provide the communication message',
        fr: 'Fournir le message de communication',
        rw: 'Tanga ubutumwa bwitumanaho'
    }
}

// used to display the send button in different languages
export const sendButton = {
    en: 'Send',
    fr: 'Envoyer',
    rw: 'Ohereza'
}
