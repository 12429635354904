import React from 'react'
import { Puff } from 'react-loader-spinner';

const ButtonLoader = () => {
  return (
    <Puff
      color="#ffff"
      height={25}
      width={25}
    />
  )
}

export default ButtonLoader