export enum EventTypes {
    ActOnEvent = 'EventTypes/ActOnEvent',
    Loading = 'EventTypes/Loading',
    Errors = 'EventTypes/Errors'
}

interface ActOnEvent {
    type: typeof EventTypes.ActOnEvent;
    payload: {
        departments: any;
    };
}

interface Loading {
    type: typeof EventTypes.Loading;
    payload: {
        loading: boolean;
    };
}

interface Errors {
    type: typeof EventTypes.Errors;
    payload: {
        errors: any;
    };
}

export type EventTypeAction =
    | ActOnEvent
    | Loading
    | Errors
