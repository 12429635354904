import React, { FC, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useMutation } from 'react-query';
import * as Yup from 'yup'
import { fetchExternalReports } from 'reduxes/applications'
import { useTranslation } from 'react-i18next';
import { ButtonLoader } from 'components/Reusable'

type Props = {
    setFieldValue: any,
    fieldName: any,
    openAlert: boolean,
    setOpenAlert: any,
    fieldValue: any,
    setOpenModal: any,
}

const CRBReport: FC<Props> = ({ setFieldValue, fieldValue, setOpenModal }) => {

    const [openAlert, setOpenAlert] = useState(false)
    const [snackMessage, setSnackMessage] = useState<any>(null);

    const { t } = useTranslation();

    const handleFetchReport = (val: any) => {
        setOpenAlert(false)
        return fetchExternalReports(val, 'crb');
    }

    const handleCloseModal = () => {
        setOpenAlert(false)
        setOpenModal(false)
    }

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return handleFetchReport(values);
    }, {
        retry: false,
        onSettled: (data: any, error: any) => {
            setOpenAlert(true)
            setSnackMessage({
                error: error ? true : false,
                message: error ? error?.response?.data?.message : 'CRB Report is attached successfully.'
            })

            if (data) {
                const response = data?.data;
                (fieldValue.length > 0) ? setFieldValue.replace(0, response.data) : setFieldValue.push(response.data)
            }

            setTimeout(() => {
                setOpenAlert(false)
                setSnackMessage(null);
                setOpenModal(false);
            }, 4000)
        }
    })

    return (
        <Formik
            initialValues={{
                firstname: "",
                lastname: "",
                nationalID: ""
            }}
            validationSchema={Yup.object().shape({
                firstname: Yup.string().required('Enter the surname'),
                lastname: Yup.string().required('Enter the othername'),
                nationalID: Yup.number().typeError('must only be numbers').test('len', 'The national id has to be 16 digits', (val: any) => {
                    const checkLength = val && val.toString().length <= 16;
                    return checkLength;
                }).required('Enter the national ID number')
            })}
            onSubmit={async (values, actions) => {

                const sendVal = {
                    "name1": values.firstname,
                    "name2": values.lastname,
                    "nationalId": values.nationalID
                }

                try {
                    await mutateAsync(sendVal);
                } catch (error) {
                    return error;
                }
            }}
        >
            {({ values, errors }) => {

                return (
                    <Form className='bg-white w-3/4 xl:w-1/4 h-auto rounded flex flex-col p-2 overflow-x-auto'>
                        {openAlert && <span className={`w-full mb-3 flex justify-center items-center p-3 rounded text-white ${snackMessage?.error ? 'bg-red-500' : 'bg-green-700'}`}>{snackMessage?.message}</span>}

                        <div className='flex-grow mb-3'>

                            <div className='flex flex-col mb-3'>
                                <label>{t('service.report.crb.fields.firstlabel')}<span className="text-red-500">*</span></label>
                                <Field
                                    className="w-full h-12 px-3 text-xs text-gray-500 bg-white border rounded outline-none"
                                    name="firstname"
                                    placeholder={t('service.report.crb.fields.firstplace')}
                                />

                                <ErrorMessage name="firstname" render={msg => < div style={{ color: 'red' }} > {msg}</div >} />
                            </div>

                            <div className='flex flex-col mb-3'>
                                <label>{t('service.report.crb.fields.lastlabel')}<span className="text-red-500">*</span></label>
                                < Field
                                    className="w-full h-12 px-3 text-xs text-gray-500 bg-white border rounded outline-none"
                                    name="lastname"
                                    placeholder={t('service.report.crb.fields.lastlabel')}
                                />

                                <ErrorMessage name="lastname" render={msg => < div style={{ color: 'red' }} > {msg}</div >} />
                            </div>

                            <div className='flex flex-col mb-3'>
                                <label>{t('service.report.crb.fields.idlabel')}<span className="text-red-500">*</span></label>
                                < Field
                                    className="w-full h-12 px-3 text-xs text-gray-500 bg-white border rounded outline-none"
                                    name="nationalID"
                                    placeholder={t('service.report.crb.fields.idplace')}
                                />

                                <ErrorMessage name="nationalID" render={msg => < div style={{ color: 'red' }} > {msg}</div >} />
                            </div>

                        </div>

                        <div className='grid md:grid-cols-2 gap-2'>
                            <button
                                className='w-full buttonsColor flex justify-center p-3 text-white rounded'
                                type='submit'
                            >
                                {
                                    isLoading ? <ButtonLoader /> : t('service.application.buttons.fetch')
                                }
                            </button>

                            <button
                                className='w-full border text-gray-600 border-gray-300 flex justify-center p-3 rounded'
                                type='button'
                                onClick={() => handleCloseModal()}
                            >
                                {t('service.application.buttons.close')}
                            </button>
                        </div>


                    </Form>
                )
            }}

        </Formik>
    )
}

export default CRBReport
