import React, { FC, useState } from 'react'
import { getFormElement } from '../../Utils/FormElements';

type Props = {
    schema: any,
    lang: string,
    values: any,
    formik: any,
    user: any
}

const Fetches: FC<Props> = ({ schema, lang, values, formik, user }) => {

    const [submitted, setSubmitted] = useState(false)


    return (
        <div className="grid md:grid-cols-3 gap-3">
            {
                schema.map((key: any, ind: any) => {

                    return (
                        <div key={ind} className="mb-2 text-gray-600">
                            {getFormElement({
                                schema: key,
                                index: ind,
                                inModal: false,
                                formik,
                                submitted,
                                setSubmitted
                            }, lang, "fetches", values["fetches"], user)}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Fetches
