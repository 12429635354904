import React, { FC } from "react";
import { MoodOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface Props {
  user: any;
  lang: string
}
const ProfileMessage: FC<Props> = ({ lang, user}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleVerify = () => navigate("/profile")

  const messages = {
    first: {
      en: `You're now at ${user?.data.profileCompletion}% profile completion,`,
      fr: `Vous êtes maintenant à ${user?.data.profileCompletion}%  d'achèvement du profil,`,
      rw: `Ubu uri kuri ${user?.data.profileCompletion}% by umwirondoro wuzuye,`
    },
    second: {
      en: `to start applying for any service,`,
      fr: `pour commencer à demander pour n'importe quel service,`,
      rw: `kugirango utangire usabe serivisi zitandukanye,`
    },
    third: {
      en: `you need to complete your profile and make it a 100%.`,
      fr: `vous devez compléter votre profil à 100%.`,
      rw: `ugomba kuzuza umwirondoro wawe kugeza 100%.`
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="w-full md:w-1/2">
        <div className="flex justify-center items-center mb-5">
          <MoodOutlined
            style={{ fontSize: "100px" }}
          />
        </div>

        <div className="md:text-lg text-gray-500 font-medium mb-5">
          <p className="text-center mb-3 text-xl">
            {t('overview.welcome_to_EC')}
          </p>

          <span className="flex flex-col">
            <p className="self-center tracking-wide">{messages["first"][lang]}</p>
            <p className="self-center tracking-wide">{messages["second"][lang]}</p>
            <p className="self-center tracking-wider">{messages["third"][lang]}</p>
          </span>
        </div>

        <div className="flex justify-center items-center mb-5">

          <button
            type="button"
            className="buttonsColor px-8 py-2 rounded text-white md:text-lg"
            onClick={() => handleVerify()}
          >
            {t('overview.start_here')}
          </button>

        </div>

      </div>
    </div>
  );
};

export default ProfileMessage;
