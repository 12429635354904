import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DesktopImage from "assets/images/BNR-Desktop.png";
import PhoneImage from "assets/images/BNR-Mobile.png";
import "./Home.scss";

const Home: FC = () => {
  const { t } = useTranslation();

  const access_token = localStorage.getItem('app_access_token');

  const userState = useSelector((state: any) => state?.users);

  if (access_token && (userState?.user && !userState?.user?.error)) {
    return (
      <Navigate to="/overview" replace />
    )
  }

  return (
    <div className="font-body h-full">
      <div className="flex flex-col lg:flex-row-reverse h-full lg:items-center">

        <div className="mb-10 lg:mb-0 ">
          <img
            src={`${PhoneImage}?v=1.0.0`}
            srcSet={
              `${PhoneImage}?v=1.0.0 768w, ${DesktopImage}?v=1.0.0 1200w`
            }
            sizes="(max-width: 480px) 100vw,
            (max-width: 768px) 100vw,
            1200px"
            alt="home page"
          className="w-full h-full"
          />
        </div>

        <div className="flex-grow text-gray-600 mb-8">
          <p className="uppercase font-medium text-xl mb-5">{t('home.welcome')}</p>

          <div className="lg:w-3/4">
            <div className="mb-5 font-medium" style={{ fontSize: '28px' }}>
              <p className="textColor mb-3">NBR E-Correspondence</p>
              <p className="">{t('home.portal')}</p>

            </div>
            <p className="text-justify text-lg mb-10 leading-8 text-gray-500">
              {t('home.description')}
            </p>
          </div>

          <Link to="/services" className="flex justify-center items-center text-white rounded buttonsColor text-lg px-8 py-4 cursor-pointer lg:w-1/2"> {t('home.browse')} </Link>
        </div>

      </div>
    </div>
  );
};

export default Home;
