import * as Yup from 'yup';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'

export const initForm = (schema: any, lang: string) => {
    let _validationSchema = {};
    let _formData = {}

    for (let field of schema) {
        if (field.inputType === "identification") field.validationType = 'number'

        _formData[field.id] = { ...field }

        _validationSchema[field.id] = Yup.object().shape({
            validation: Yup.object(),
            validationType: Yup.string(),
            inputType: Yup.string(),
            fetchFrom: Yup.string(),
            inputValue: Yup["string"]()
                .when('validationType', ([type], schema: any) => {
                    if (type !== "string") schema = Yup[type]().typeError('must be an number')
                    return schema
                })
                .when('validation', ([validation], schema: any) => {
                    const { required, max, min } = validation;

                    if (validation.defaultValue === 'id') {
                        const nationalID = max.length > 0 && max[0];
                        if (nationalID.value && nationalID.value.length > 0) {
                            schema = schema.test('len', nationalID.message[lang], (val: any) => {
                                const checkLength = val && val.toString().length === Number(nationalID.value);
                                return (required.value && checkLength) || (!required.value && (!val || checkLength))
                            })
                        }
                    }

                    if (validation.defaultValue === 'passport') {
                        const passMax = max.length > 0 && max[1];
                        const passMin = min.length > 0 && min[1];

                        if (passMax.value && passMax.value.length > 0) {
                            schema = Yup.string().max(Number(passMax.value), passMax.message[lang])
                        }

                        if (passMin.value && passMin.value.length > 0) {
                            schema = Yup.string().min(Number(passMin.value), passMin.message[lang])
                        }
                    }

                    return schema
                })
                .when('validation', ([validation], schema: any) => {
                    const { required, max, min } = validation;

                    if (required.value) schema = schema.required(required.message[lang])
                    if (min.value && min.value.length > 0) schema = schema.test('len', min.message[lang], (val: any) => {

                        // check the length of the input value to be less than the defined minimum value
                        const checkLength = val && val.toString().length >= Number(min.value);

                        // check if the input is required and the length value
                        // or not required and if the value is not defined or the length of the input
                        return (required.value && checkLength) || (!required.value && (!val || checkLength))
                    });
                    if (max.value && max.value.length > 0) schema = schema.test('len', max.message[lang], (val: any) => {
                        const checkLength = val && val.toString().length <= Number(max.value);

                        return (required.value && checkLength) || (!required.value && (!val || checkLength))
                    });

                    return schema;
                })
                .when('inputType', ([inputType], schema: any) => {
                    const invalidEmail = {
                        en: 'Invalid Email',
                        fr: 'Email Invalide',
                        rw: 'Imeri ntiyemewe'
                    }

                    const invalidPhone = {
                        en: 'Invalid phone number',
                        fr: 'Numero de telephone invalide',
                        rw: 'Numero ya telefoni ntago yemewe'
                    }
                    if (inputType === "email") schema = schema.email(invalidEmail[lang])
                    if (inputType === 'phone') schema = schema.test('phone', invalidPhone[lang], (val: any) => {
                        const parseNumber = (val && val.length > 0) && parsePhoneNumber(val);

                        const num = parseNumber?.number as string
                        const CD = parseNumber?.country

                        return (((val && val.length > 0) && (CD && num && isValidPhoneNumber(num, CD))) || !val);
                    })

                    if (inputType === "file") schema = schema.notRequired();
                    if (inputType === "fetch") schema = schema.notRequired();
                    return schema;
                }),
            attachments: Yup.array()
                .when('validation', ([validation], schema: any) => {
                    const { required } = validation;
                    if (required.value) schema = schema.min(1, required.message[lang]);
                    return schema;
                })
                .when('inputType', ([inputType], schema: any) => {
                    if (inputType === "file" || inputType === "fetch") return schema;
                    return Yup.array();
                })
        });
    }

    return { _validationSchema, _formData }
}
