import React, { FC, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import logo from "assets/images/bnr_logo_new.png";
import IsLoggedIn from "./Logged";
import "./Navbar.scss";

const NavBar: FC = () => {
  const [open, setOpen] = useState(false);

  const userState = useSelector((state: any) => state?.users);

  const navigate = useNavigate();

  const handleOpen = () => {
    return setOpen(!open);
  }

  const phoneNav = "flex flex-col p-4 space-y-4";

  const handleHomeButton = () => {
    const { user } = userState;
    if (user && user?.data?.isRegistered) {
      navigate("/services");
    } else {
      navigate("/")
    }
  }

  return (
    <div className={`md:px-8 px-4`}>
      <div className="border-b-2 border-yellow-600 py-2">
        <div className={"flex flex-row justify-between items-center"}>
            <img
              src={`${logo}?v=1.0.0`}
              alt="logo"
              className={`cursor-pointer w-20`}
              onClick={() => handleHomeButton()}
            />
          <button
            type="button"
            aria-label="menu button"
            className="rounded-lg lg:hidden focus:outline-none focus:shadow-outline"
            onClick={() => handleOpen()}
          >
            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
              {open ? (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              ) : (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              )}
            </svg>
          </button>
          <div className="hidden lg:block">
            <IsLoggedIn />
          </div>
        </div>
        <div className={open ? `${phoneNav}` : `${phoneNav} hidden`}>
          <IsLoggedIn />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
