import React from 'react'
import { Modal, Backdrop, Fade, createStyles, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }),
);

type Props = {
    handleClose: any,
    open: any,
    idleTimer:any
}

const IdleModal: React.FC<Props> = ({ handleClose, open, idleTimer }) => {
    const classes = useStyles();

    const idleTime = Number(process.env.REACT_APP_IDLE_TIME_IN_SECONDS);
    const idleTimeConverted = idleTime >= 60 ? idleTime / 60 : idleTime;
    const idleTimeHalf = idleTime >= 60 ? idleTime / 4 : idleTime / 2;

    const keepMeIn = () => {
        handleClose()
        idleTimer.reset()
    }

    const logOut = () => {
        handleClose()
        localStorage.removeItem("app_access_token");
        localStorage.removeItem("ttlnt.refresh");
        localStorage.removeItem("app_refresh_token");    
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={`w-11/12 h-max md:w-1/2 lg:w-1/4 bg-white rounded outline-none font-body p-2 text-lg`}>
                    <span className='flex justify-center items-center border-b font-bold mb-2 p-2'>Idle user detector</span>
                    <span className=''>{`We have detected that you have been idle on the system for ${String(idleTimeConverted)} minutes, Please choose from the options below if you want to proceed, otherwise the system will automatically log you out after ${String(idleTimeHalf)} seconds.`}</span>

                    <div className='flex justify-between items-center mt-5'>
                        <button 
                            className='textColor border border-gray-600 px-8 py-2 rounded'
                            onClick={() => keepMeIn()}
                        >Keep me in.</button>

                        <button 
                            className='buttonsColor text-white px-8 py-2 rounded'
                            onClick={() => logOut()}
                        >Sign me out.</button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default IdleModal;
