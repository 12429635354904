import React, { FC, useState, ChangeEvent } from 'react'
import { useMutation } from 'react-query'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Field, ErrorMessage } from 'formik'
import { useSelector } from 'react-redux'
import { Tooltip } from "@mui/material";

import { VisibilityOutlined, CloudUploadOutlined, CheckCircleRounded, CancelOutlined, ReplayOutlined } from '@material-ui/icons'

import { useTranslation } from 'react-i18next';

import { fetchExternalDetailsAction } from '../../../../reduxes/externals'

import { addDocumentsAction } from '../../../../reduxes/uploads'

import { ButtonLoader, ViewModal } from '../../../Reusable'
import { VerifyPhone } from '.'

import { nationalities } from '../../../Authentication/Signup/schema'
import { ErrorHandler } from 'utils';

type Props = {
  values: any;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
  lang: string;
  setOpenSnack: any;
  setSnackMessage: any;
}

const divColor = "h-12 px-3 mt-2 text-sm border-0 rounded outline-none w-full";

const companyOptions = [
  {
    name: "TIN Number",
    value: "tin"
  },
  {
    name: "RCA Number",
    value: "rca"
  },
]

const ProfileFields: FC<Props> = ({ values, setFieldValue, lang, setOpenSnack, setSnackMessage, setFieldError, setFieldTouched }) => {

  const { t } = useTranslation()

  const userState: any = useSelector((state: any) => state.users);

  const { user } = userState;

  const [openModal, setOpenModal] = useState<any>({
    open: false,
    file: null
  })
  const [openPhoneModal, setOpenPhoneModal] = useState(false)
  const [validationError, setValidationError] = useState<any>({
    show: false,
    error: ""
  })


  const addPassportMutation = useMutation((file: any) => {
    return addDocumentsAction(file);
  }, {
    retry: false,
    onSettled: (data, error) => {

      if (error) {
        const { message } = ErrorHandler(error, 'File server')
        setFieldError('profile.passport.number', message)
      }

      const response = data?.data;
      const file = response?.data[0];

      setFieldValue('profile.passport.file.name', file.name);
      setFieldValue('profile.passport.file.url', file.url);
      setFieldValue('profile.profileCompletion', values["profileCompletion"] < 100 ? values["profileCompletion"] + 25 : values["profileCompletion"]);
      setFieldValue("profile.isRegistered", values["isRegistered"] === 0 ? 1 : values["isRegistered"])
      setFieldValue('profile.nationalId', '')
    }
  })

  const { isLoading, mutateAsync } = useMutation((sendObj: any) => {
    const urlType = sendObj["type"];
    const { type, ...rest } = sendObj;
    return fetchExternalDetailsAction(rest, urlType)
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: (data: any) => {
      const response = data?.data;
      const { data: res } = response;
      if (res?.type === 'rca') {
        const name = res?.data?.cooperativeName;
        setFieldValue('profile.name', name);
        setFieldValue("profile.verifyWith", 'rca');
      }

      if (res?.type === 'rdb') {
        const name = res?.body["getbusinessregistrationdetailsbytinresponse"]["getbusinessregistrationdetailsbytinresult"]["registrationdetaillist"]["registrationinfo"]["reg_company_name"]
        setFieldValue('profile.name', name);
        setFieldValue("profile.verifyWith", 'tin');
      }

      if (res?.type === 'id' || res?.type === 'nida') {
        const name = `${res?.foreName} ${res?.surnames}`;
        setFieldValue('profile.name', name);
        setFieldValue("profile.verifyWith", 'id');
      }

      setFieldValue("profile.isRegistered", 1)
      setFieldValue("profile.reverifyAccount", false)
      setFieldValue("profile.profileCompletion", values["profileCompletion"] < 100 ? values["profileCompletion"] + 25 : values["profileCompletion"])

      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message: 'User details updated with fetched data.'
      })

    }
  })

  const handleCloseModal = () => setOpenModal({
    open: false,
    file: null
  })

  const handleClosePhoneModal = () => {
    return setOpenPhoneModal(false);
  }

  const inputValidation = (type: string, value: string) => {
    let regex;
    let error;
    if (type === "id") {
      regex = /^\d{16}$/;
      error = "National ID number should be consisted of 16 digits."
    }

    if (type === "tin") {
      regex = /^\d{9}$/;
      error = "TIN number should be consisted of 9 digits."
    }

    if (type === "rca") {
      regex = /^[a-zA-Z0-9/-]{10,20}$/;
      error = "RCA Registration ID or TIN number is not well formatted.";
    }

    return {
      test: regex?.test(value),
      error
    }
  }

  const handleVerify = async (value: any, type: string) => {
    if (value?.length === 0) {
      return setValidationError({
        show: true,
        error: `Enter the ${type} number`
      })
    }

    const { test, error } = inputValidation(type, value);

    setValidationError(() => {
      if (!test) return {
        show: true,
        error: error
      }
      return {
        show: false,
        error: ""
      }
    })

    try {
      let shouldVerify = true;
      let sendObj = {};
      
      if (values["reverifyAccount"]) shouldVerify = false;
      if (user?.data?.nationalId === value) shouldVerify = false;
      if (test) {
        const obj = type === 'id' ? { id: value } : { tin: value };
        sendObj = { verify: shouldVerify, type: type === 'tin' ? 'rdb' : type === 'id' ? 'nida' : type , ...obj };
      }

      return await mutateAsync(sendObj);
    } catch (error) {
      return error;
    }
  }

  const handleViewPassport = async (values: any) => {
    setOpenModal({
      open: true,
      file: {
        name: 'Passport copy',
        link: values["url"]
      }
    })
  }

  const handlePassport = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files!;
    const passportNumber = values["passport"]["number"];

    const maxSize = 10;
    const accept = ['.png', '.jpeg', '.jpg', '.pdf']

    const sizeMessage = {
      en: `Selected file is larger than allowed file size, i.e: ${maxSize}`,
      fr: `L'un des fichiers sélectionnés dépasse la taille de fichier autorisée, c'est-à-dire : ${maxSize}`,
      rw: `Imwe muma dosiye yatoranijwe ninini kuruta ubunini bwa dosiye bwemewe, ni ukuvuga: ${maxSize}`
    }

    const typeMessage = {
      en: `Selected file type is not allowed, supported file types are ${accept.join(", ")}`,
      fr: `L'un des fichiers sélectionnés n'est pas autorisée, c'est-à-dire : ${accept.join(", ")}`,
      rw: `Imwe muma dosiye yatoranijwe ntabwo yemewe, ni ukuvuga: ${accept.join(", ")}`
    }

    const noPassportNumber = {
      en: `Enter your passport number first`,
      fr: `Entrez d'abord votre numéro de passeport`,
      rw: `Banza winjize nomero yawe ya pasiporo`
    }

    const checkSize = Array.from(files).filter((file: any) => file.size > (maxSize * 1000000));
    const checkType = Array.from(files).filter((file: any) => {
      return !accept.filter((v: any) => v === file.type || v.split(".").pop() === file.name.split(".").pop()).length
    });

    setFieldTouched('profile.passport.number', true, false)

    try {
      if (!passportNumber) {
        setFieldError('profile.passport.number', noPassportNumber[lang])
      }
  
      if (checkSize?.length > 0) {
        setFieldError('profile.passport.number', sizeMessage[lang])
      }
  
      if (checkType?.length > 0) {
        setFieldError('profile.passport.number', typeMessage[lang])
      }

      if (passportNumber && checkType.length === 0 && checkSize.length === 0) {
        await addPassportMutation?.mutateAsync(files);
      }

    } catch (error) {
      return error;
    }

  };

  const handleReverifyAccount = (props: any) => {
    const { setFieldValue, type } = props;

    setFieldValue('profile.reverifyAccount', true);
    setFieldValue('profile.isRegistered', 0);
    setFieldValue('profile.profileCompletion', values["profileCompletion"] - 25)

    if (type === "passport") {
      setFieldValue('profile.passport.number', '')
      setFieldValue('profile.passport.file.url', '')
    } else {
      setFieldValue('profile.nationalId', "");
      setFieldValue('profile.name', "");
    }
  }

  const handleNationality = (e: ChangeEvent<HTMLInputElement>, props: any) => {
    const { setFieldValue } = props;
    const val = e.target.value;

    const currentValues = {
      accountType: user?.data?.accountType,
      type: user?.data?.userType,
      name: user?.data?.name,
      nationalId: user?.data?.nationalId,
      nationality: user?.data?.nationality,
      passportNo: user?.data?.passportNo,
      passportFile: user?.data?.passportFilePath,
      isRegistered: user?.data?.isRegistered,
      verifyWith: user?.data?.verifyWith?.value,
      profileCompletion: user?.data?.profileCompletion,
      verifyOptions: user?.data?.verifyWith?.options,
    };

    setFieldValue('profile.nationality.value', val);
    setFieldValue('profile.nationality.isChanged', true);
    setFieldValue("profile.name", val === currentValues?.nationality ? currentValues?.name : '');
    setFieldValue("profile.nationalId", val === currentValues?.nationality ? currentValues?.nationalId : '');
    setFieldValue("profile.passport.number", val === currentValues?.nationality ? currentValues?.passportNo : '');
    setFieldValue("profile.passport.file.url", val === currentValues?.nationality ? currentValues?.passportFile : '');

    if (currentValues?.accountType.toLowerCase() === 'company') {
      setFieldValue('profile.verifyWith', (val === "national" && currentValues?.verifyWith?.length > 0) ? currentValues?.verifyWith : (val === "national" && currentValues?.verifyWith?.length === 0) ? 'tin' : '')
      setFieldValue('profile.verifyOptions', (val === "national" && currentValues?.verifyOptions?.length > 0) ? currentValues?.verifyOptions : (val === "national" && currentValues?.verifyOptions?.length === 0) ? companyOptions : []);
      setFieldValue('profile.isRegistered', val === currentValues?.nationality ? currentValues?.isRegistered : val === 'international' ? 1 : 0);
      setFieldValue('profile.profileCompletion', val === currentValues?.nationality ? currentValues?.profileCompletion : val === 'international' ? 100 : values["profileCompletion"] - 25);
    }

    if (currentValues?.accountType.toLowerCase() === 'individual') {
      setFieldValue('profile.verifyWith', val === "foreigner" ? currentValues?.verifyWith : 'id')
      setFieldValue('profile.isRegistered', val === currentValues?.nationality ? currentValues?.isRegistered : 0);

      if (!values["userType"]["isChanged"]) {
        setFieldValue('profile.profileCompletion', val !== currentValues?.nationality ? values["profileCompletion"] - 25 : currentValues?.profileCompletion);
      }
    }

  }

  const handleuserType = (e: ChangeEvent<HTMLInputElement>, props: any) => {
    const { setFieldValue } = props;
    const val = e.target.value;

    const options = values["userType"]["options"];
    const currentValues = {
      type: user?.data?.userType,
      name: user?.data?.name,
      nationalId: user?.data?.nationalId,
      passportNo: user?.data?.passportNo,
      passportFile: user?.data?.passportFilePath,
      isRegistered: user?.data?.isRegistered,
      verifyWith: user?.data?.verifyWith?.value,
      profileCompletion: user?.data?.profileCompletion,
      verifyOptions: user?.data?.verifyWith?.options,
    };

    const findOption = options?.find((el: any) => el?.value === val);

    setFieldValue('profile.userType.type', val);
    setFieldValue('profile.userType.isChanged', !values["userType"]["isChanged"]);
    setFieldValue("profile.name", findOption?.value === currentValues?.type ? currentValues?.name : '');
    setFieldValue("profile.nationalId", findOption?.value === currentValues?.type ? currentValues?.nationalId : '');
    setFieldValue("profile.passport.number", findOption?.value === currentValues?.type ? currentValues?.passportNo : '');
    setFieldValue("profile.passport.file.url", findOption?.value === currentValues?.type ? currentValues?.passportFile : '');

    setFieldValue('profile.verifyWith', (values["nationality"]["value"] === 'international' || !findOption?.shouldVerify) ? '' : currentValues?.verifyWith);
    setFieldValue('profile.verifyOptions', (values["nationality"]["value"] === 'international' || !findOption?.shouldVerify) ? [] : currentValues?.verifyOptions);
    setFieldValue("profile.isRegistered", findOption?.value === currentValues?.type ? currentValues?.isRegistered : (values["nationality"]["value"] === 'international' || !findOption?.shouldVerify) ? 1 : 0);

    // if (!values["nationality"]["isChanged"]) {
    //   setFieldValue('profile.profileCompletion', val !== currentValues?.type ? values["profileCompletion"] - 25 : currentValues?.profileCompletion);
    // }

  }

  return (
    <div className="flex flex-col">
      <span className="text-lg font-bold mb-5">{t('profile.title.profile')}</span>

      {!values["isRegistered"] && <div className="flex flex-col mb-3">
        <label className="text-sm">
          Verify account using
          <span className="text-red-500">*</span>
        </label>
        <Field
          as="select"
          className={`${divColor} bg-gray-200`}
          name="profile.verifyWith"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value;
            setFieldValue('profile.verifyWith', val);
            setFieldValue('profile.nationalId', '')
            setValidationError({
              show: false,
              error: ''
            })
          }}

        >
          <option value="" disabled>Choose the verification option</option>
          {
            values["verifyOptions"].filter((el: any) => {
              const nationality = values["nationality"]["value"];
              if (nationality.toLowerCase() === 'rwandan') return el?.value === 'id';
              return el;
            }).map((option: any, index: number) => {
              return (
                <option value={option.value} key={index}>{option.name}</option>
              )
            })
          }
        </Field>

      </div>}

      {values["isSubscribed"] !== 1 && <div className={`flex flex-col ${values["verifyWith"]?.length === 0 && 'hidden'}`}>
        {
          values["verifyWith"] === "tin" || values["verifyWith"] === "rca" || values["verifyWith"] === "id" ?
            <div className="flex flex-col mb-3">
              <div className='flex justify-between items-center'>
                <label className="text-sm mb-2">
                  {
                    values["verifyWith"] === "tin" ?
                      `RDB TIN Number`
                      :
                      values["verifyWith"] === "rca" ?
                        `RCA Number`
                        :
                        `ID Number`
                  }
                  <span className="text-red-500">*</span>
                </label>

                {values["isRegistered"] === 1 && <Tooltip placement="bottom" title={
                  <p className="text-sm">Reverify your account</p>
                } className="cursor-pointer" onClick={() => handleReverifyAccount({
                  setFieldValue,
                  user,
                  type: "other"
                })}>
                  <ReplayOutlined />
                </Tooltip>}

              </div>


              <div className={`flex h-12 relative`}>
                <Field
                  type="text"
                  className={` bg-gray-200 flex-grow px-3 text-sm rounded outline-none`}
                  name="profile.nationalId"
                  placeholder={`${values["verifyWith"].toUpperCase()} number`}
                />

                {
                  !values["isRegistered"] ?
                    <button
                      type="button"
                      className={`buttonsColor text-white px-4 rounded-r`}
                      onClick={() => handleVerify(values["nationalId"], values["verifyWith"].toLowerCase())}
                    >
                      {
                        isLoading ? <ButtonLoader /> : 'verify'
                      }

                    </button>
                    :
                    <Tooltip placement="bottom" title={
                      <p className="text-sm">
                        {
                          values["accountType"] === "company" ? 'Tin number is verified' : 'ID number is verified'
                        }
                      </p>
                    } className="cursor-pointer">
                      <CheckCircleRounded className={`text-green-300 absolute right-2 top-3 `} />
                    </Tooltip>
                }

              </div>

              {validationError?.show && <span className="text-red-500">{validationError.error}</span>}

            </div>
            :
            values["verifyWith"] === "passport" ?
              <div className="flex flex-col mb-3">
                <div className='mb-2 flex justify-between items-center'>
                  <label className="text-sm">
                    {t('profile.fields.passport_url')}
                    <span className="text-red-500">*</span>
                  </label>

                  {values["isRegistered"] === 1 && <Tooltip placement="bottom" title={
                    <p className="text-sm">Reverify your account</p>
                  } className="cursor-pointer" onClick={() => handleReverifyAccount({
                    setFieldValue,
                    user,
                    type: "passport"
                  })}>
                    <ReplayOutlined />
                  </Tooltip>}
                </div>

                <div className={`flex h-12 relative w-full`}>

                  <Field
                    className={`px-3 rounded-l bg-gray-200 flex-grow w-1 text-sm outline-none`}
                    name="profile.passport.number"
                    placeholder="Passport Number"
                  />

                  <div className="flex justify-between">
                    <label htmlFor="passport" className="custom-yellow-bg p-3 text-white cursor-pointer">

                      {
                        addPassportMutation?.isLoading ? <ButtonLoader /> : <CloudUploadOutlined />
                      }

                      <input
                        className="z-0 w-full h-10 px-3 mt-2 text-xs text-gray-500 bg-black border rounded outline-none hidden"
                        type="file"
                        id="passport"
                        accept=".pdf, image/*"
                        name='passport'
                        onChange={(e: any) => handlePassport(e)}
                      />

                    </label>

                    <button
                      type='button'
                      disabled={values?.passport?.file?.url?.length === 0}
                      className="p-3 buttonsColor text-white rounded-r cursor-pointer"
                      onClick={() => handleViewPassport(values?.passport?.file)}
                    >
                      <VisibilityOutlined />
                    </button>
                  </div>

                </div>

                <ErrorMessage name="profile.passport.number" render={(msg) => <span className="text-red-500">{msg}</span>} />

              </div>
              :
              null
        }

      </div>}


      <div className="flex flex-col mb-3">
        <label className="text-sm">
          {t('profile.fields.names')}
          <span className="text-red-500">*</span>
        </label>
        <Field
          className={`${divColor} bg-gray-200`}
          name="profile.name"
          placeholder="Enter your name"
          disabled={values["verifyWith"]?.length > 0 && values["verifyWith"] !== 'passport' ? true : false}
        />
        <ErrorMessage name="profile.name" render={(msg) => <span className="text-red-500">{msg}</span>} />
      </div>

      {values["isSubscribed"] !== 1 && <div className={`grid md:grid-cols-3 gap-2 mb-3`}>
        <div className="flex flex-col">
          <label className="text-sm">
            {t('profile.fields.account_type')}
            <span className="text-red-500">*</span>
          </label>

          <span className={`h-12 px-3 mt-2 text-sm border-0 rounded outline-none bg-gray-200 flex items-center`}>
            {t(`profile.fields.account_type_value.${user?.data?.accountType}`)}
          </span>

        </div>

        <div className="flex flex-col">
          <label className="text-sm">
            {`${values["accountType"] === "individual" ? t('signup.individual_fields.nationality') : t('signup.company_fields.nationality')}`}
            <span className="text-red-500">*</span>
          </label>

          <Field
            className={`${divColor} bg-gray-200`}
            as="select"
            name="profile.nationality.value"
            onChange={(e: any) => handleNationality(e, { setFieldValue })}
          >

            {
              nationalities.filter(el => el.for === values["accountType"]).map((opt, ind) => {
                return (
                  <option value={opt.en.toLowerCase()} key={ind}> {opt[lang]} </option>
                )
              })
            }
          </Field>

          <ErrorMessage name="profile.nationality" render={(msg) => <span className="text-red-500">{msg}</span>} />

        </div>

        <div className="flex flex-col">
          <label className="text-sm">{
            user?.data.accountType === "company" ?
              t('signup.company_fields.company_type')
              :
              t('signup.individual_fields.individual_type')
          }
            <span className="text-red-500">*</span>
          </label>

          <Field
            className={`${divColor} bg-gray-200`}
            as="select"
            name="profile.userType.type"
            onChange={(e: any) => handleuserType(e, {
              setFieldValue,
              user: user?.data,
              type: values["accountType"],
              nationality: values["nationality"]
            })}
          >

            <option value="" disabled> Choose your user type </option>

            {
              values["userType"]["options"].map((opt: any, ind: number) => {
                return (
                  <option value={opt.value} key={ind}>{opt.label}</option>
                )
              })
            }

          </Field>

          <ErrorMessage name="profile.userType.type" render={(msg) => <span className="text-red-500">{msg}</span>} />

        </div>
      </div>}

      <div className="flex flex-col mb-3">
        <label className="text-sm">
          {t('profile.fields.email')}
          <span className="text-red-500">*</span>
        </label>

        <div className={`flex h-12 relative mt-2`}>
          <Field
            className={`bg-gray-200 flex-grow px-3 text-sm rounded outline-none`}
            name="profile.email"
            disabled
          />


          <Tooltip placement="bottom" title={
            <p className="text-sm">
              {values["isEmailVerified"] ? "Email is verified" : "Email is not verified"}
            </p>
          } className="cursor-pointer">
            {values["isEmailVerified"] ? <CheckCircleRounded className="text-green-300 absolute right-2 top-3" /> : <CancelOutlined className="text-red-500 absolute right-2 top-3" />}

          </Tooltip>


        </div>

        <ErrorMessage name="profile.email" render={(msg) => <span className="text-red-500">{msg}</span>} />

      </div>

      <div className="flex flex-col mb-3">
        <label className="text-sm">
          {t('profile.fields.phone_number')}
          <span className="text-red-500">*</span>
        </label>

        <div className={`flex h-12 relative mt-2`}>
          <PhoneInput
            inputStyle={{
              backgroundColor: 'rgba(229, 231, 235, 1)'
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            country="rw"
            value={user?.data.phoneNumber}
            searchPlaceholder="search"
            enableSearch
            disabled
            placeholder={t('profile.fields.phone_number') as string}
            onChange={(val) => setFieldValue("profile.phoneNumber", `+${val}`, false)}
          />


          {
            values["isPhoneVerified"] === 0 ?
              <button
                type="button"
                disabled={values["phoneNumber"] && values["phoneNumber"].length > 0 ? false : true}
                className={`buttonsColor text-white px-4 rounded-r`}
                onClick={() => setOpenPhoneModal(true)}
              >
                Verify
              </button>
              :
              <Tooltip placement="bottom" title={
                <p className="text-sm">
                  Phone number is verified
                </p>
              } className="cursor-pointer">
                <CheckCircleRounded className="text-green-300 absolute right-2 top-3" />
              </Tooltip>
          }

        </div>

        <ErrorMessage name="profile.phoneNumber" render={(msg) => <span className="text-red-500">{msg}</span>} />

      </div>

      {openModal?.open && <ViewModal
        handleClose={handleCloseModal}
        open={openModal}
      />}

      <VerifyPhone
        open={openPhoneModal}
        handleClose={handleClosePhoneModal}
        existingValues={values}
        setFieldValue={setFieldValue}
      />

    </div>
  )
}

export default ProfileFields
