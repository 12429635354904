import React, { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Backdrop, Fade } from "@material-ui/core";
import { TimerOutlined, LocalOfferOutlined, CheckOutlined, KeyboardArrowDownOutlined } from "@material-ui/icons";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { getServiceById, resetStateAction } from '../../reduxes/service'

import { useTranslation } from "react-i18next";
import { AppLoader, Error } from '../Reusable';

type Props = {
  open: boolean,
  handleClose: any,
  openedService: any,
  lang: string
}

type CollapseProps = {
  item: any,
  lang: string
}

type OthCollapse = {
  name: string,
  items: any[],
  lang: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

const required = {
  en: 'Required',
  fr: 'Requis',
  rw: 'Irakenewe'
}

const optional = {
  en: 'Optional',
  fr: 'facultatif',
  rw: 'Ntikenewe'
}


const Collapse: FC<CollapseProps> = ({ item, lang }) => {

  const [isChecked, setIsChecked] = useState(false)

  return (
    <div className='relative overflow-hidden'>
      <input
        type="checkbox"
        className='peer absolute top-0 inset-x-0 w-full h-12 z-10 cursor-pointer opacity-0'
        onChange={() => setIsChecked(!isChecked)}
      />

      <div className='bg-gray-200 h-12 w-full pl-5 flex items-center rounded-t'>
        <h1 className='font-semibold text-gray-600'>{item.collection_name[lang]}</h1>
      </div>

      <div className={`absolute top-3 right-3 text-gray-600 transform duration-500 rotate-0 ${isChecked && 'rotate-180'}`}>
        <KeyboardArrowDownOutlined />
      </div>

      <div className={`bg-gray-100 overflow-hidden transition-all duration-500 rounded-b max-h-0 ${isChecked && 'max-h-full'}`}>
        <ul className='p-4'>
          {
            item && item.collection_fields.map((el: any, ind: number) => {
              const name = el.name[lang]
              return (
                <li className='border-b p-2 mb-3 flex flex-col' key={ind}>
                  <div className='flex justify-between items-center'>
                    <Tooltip placement="bottom" title={
                      <p className='text-sm'>{el.description[lang]}</p>
                    }>
                      <span className='font-semibold cursor-pointer'>{name}</span>
                    </Tooltip>


                    {el.validation.required.value ? <span className='text-red-500'>{required[lang]}</span> : <span className='text-gray-600'>{optional[lang]}</span>}
                  </div>

                  {el?.inputType === "fetch" && (
                    <div className='flex flex-col'>
                      <div className='flex justify-between items-center'>
                        <Tooltip placement="bottom" title={
                          <p className='text-sm'>{`Data will be fetched from ${el?.fetchFrom}`}</p>
                        }>
                          <span className='text-sm cursor-pointer'>Fetch From: {el?.fetchFrom?.length > 0 ? el?.fetchFrom : "Not Specified"}</span>
                        </Tooltip>
                        {(el?.fetchFrom?.length > 0 && el?.status?.length > 0) && <span className={`text-white text-xs rounded-full ${el?.status?.toLowerCase() === "down" ? 'bg-red-600' : 'bg-green-600'} px-4 py-1`}>{el?.status}</span>}
                      </div>

                      <span className='text-xs font-bold'>{`NB: Data should be ${el?.resultType?.toLowerCase() === 'upload' ? 'uploaded' : 'fetched'}.`}</span>
                    </div>
                  )
                  }


                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

const OtherCollapse: FC<OthCollapse> = ({ name, items, lang }) => {

  const [isChecked, setIsChecked] = useState(false)

  return (
    <div className='relative overflow-hidden'>
      <input
        type="checkbox"
        className='peer absolute top-0 inset-x-0 w-full h-12 z-10 cursor-pointer opacity-0'
        onChange={() => setIsChecked(!isChecked)}
      />

      <div className='bg-gray-200 h-12 w-full pl-5 flex items-center rounded-t'>
        <h1 className='font-semibold text-gray-600'>{name}</h1>
      </div>

      <div className={`absolute top-3 right-3 text-gray-600 transform duration-500 rotate-0 ${isChecked && 'rotate-180'}`}>
        <KeyboardArrowDownOutlined />
      </div>

      <div className={`bg-gray-100 overflow-hidden transition-all duration-500 rounded-b max-h-0 ${isChecked && 'max-h-full'}`}>
        <ul className='p-4'>
          {
            items && items.map((el: any, ind: number) => {
              const name = el.name[lang]
              return (
                <li className='border-b p-2 mb-3 flex flex-col' key={ind}>
                  <div className='flex justify-between items-center'>
                    <Tooltip placement="bottom" title={
                      <p className='text-sm'>{el.description[lang]}</p>
                    }>
                      <span className='font-semibold cursor-pointer'>{name}</span>
                    </Tooltip>

                    {el.validation.required.value ? <span className='text-red-500'>{required[lang]}</span> : <span className='text-gray-600'>{optional[lang]}</span>}
                  </div>

                  {el?.inputType === "fetch" && (
                    <div className='flex flex-col'>
                      <div className='flex justify-between items-center'>
                        <Tooltip placement="bottom" title={
                          <p className='text-sm'>{`Data will be fetched from ${el?.fetchFrom}`}</p>
                        }>
                          <span className='text-sm cursor-pointer'>Fetch From: {el?.fetchFrom?.length > 0 ? el?.fetchFrom : "Not Specified"}</span>
                        </Tooltip>
                        {(el?.fetchFrom?.length > 0 && el?.status?.length > 0) && <span className={`text-white text-xs rounded-full ${el?.status?.toLowerCase() === "down" ? 'bg-red-600' : 'bg-green-600'} px-4 py-1`}>{el?.status}</span>}
                      </div>

                      <span className='text-xs font-bold'>{`NB: Data should be ${el?.resultType?.toLowerCase() === 'upload' ? 'uploaded' : 'fetched'}`}</span>
                    </div>
                  )
                  }
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

const OpenServiceModal: FC<Props> = ({ open, handleClose, lang, openedService }) => {

  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = location

  const service_step = new URLSearchParams(search).get('service_step');

  const [checkedService, setCheckedService] = useState<any>(null)
  const [subIndex, setSubIndex] = useState(0)

  const handleChecked = (service: any, index: number) => {
    setSubIndex(index)
    setCheckedService(service)
  }

  const serviceState = useSelector((state: any) => state.services);

  const handleApply = () => {
    const { service, ...rest } = serviceState;
    resetStateAction({ ...rest })(dispatch);
    navigate(`/apply?mode=new&&id=${checkedService.id}`)
  }

  const notice = {
    en: 'Note: Before applying, it is advised that you expand every item on this page to check the required/optional field, this will help you know if you have all the necessities.',
    fr: 'Remarque : Avant de postuler, il est conseillé de développer chaque élément de cette page pour vérifier le champ obligatoire/facultatif, cela vous aidera à savoir si vous avez tout ce dont vous avez besoin.',
    rw: 'Icyitonderwa: Mbere yo gusaba, birasabwa ko wagura ibintu byose kururu rupapuro kugirango ugenzure ibisabwa, ibi bizagufasha kumenya niba ufite ibikenewe byose.'
  }


  let { isLoading, isError, data, refetch, error } = useQuery<any>(['service', openedService?.id], () => {
    return getServiceById(openedService?.id)
  }, {
    retry: false,
    keepPreviousData: true,
    enabled: openedService ? true : false,
    onSuccess: (data: any) => {
      const service = data?.data;
      setCheckedService(() => {
        if (service?.data?.hasSteps === 1) {
          return service?.data?.subServices[service_step ? Number(service_step) : subIndex];
        } else {
          return service?.data
        }
      })
    }
  })

  const response = data?.data;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>

        <div className={`p-4 bg-white rounded outline-none md:p-8 h-5/6 w-11/12 overflow-x-auto flex flex-col`}>
          {
            isLoading ?
              <div className="flex items-center justify-center h-full">
                <AppLoader />
              </div>
              :
              isError ?
                <div className="flex items-center justify-center h-full">
                  <Error error={error} action={() => refetch()} />
                </div>
                :
                <div className='flex flex-col h-full text-gray-600 text-sm'>

                  <div className="mb-3">
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        <p className="text-sm">
                          {response.data?.jsonDescription[lang]}
                        </p>
                      }
                    >
                      <p className="font-semibold uppercase cursor-pointer">
                        {response?.data?.jsonName[lang]}
                      </p>
                    </Tooltip>
                  </div>

                  <div className='flex-grow flex flex-col'>
                    {
                      response.data?.hasSteps === 1 && response?.data?.subServices && (
                        <div className='flex flex-col mb-3'>
                          <span className="font-bold mb-3">
                            {t('service.sub')}
                          </span>
                          <div className={`${response?.data?.subServices?.length > 9 ? 'h-72' : 'h-auto'}`}>

                            <div className='grid md:grid-cols-3 gap-3'>
                              {
                                response?.data?.subServices.map((subService: any, index: number) => {
                                  const name = subService.jsonName[lang]
                                  const description = subService.jsonDescription[lang];

                                  return (
                                    <span
                                      className='flex items-center space-x-2 cursor-pointer'
                                      key={index}
                                      onClick={() => handleChecked(subService, index)}
                                    >
                                      <input
                                        type="checkbox"
                                        name={name}
                                        className="h-6 w-6 cursor-pointer -mr-8 opacity-0"
                                      />
                                      <div
                                        className="bg-white border-2 rounded-full border-gray-400 w-6 h-6 flex justify-center items-center cursor-pointer"

                                      >
                                        {
                                          checkedService?.id === subService?.id && <CheckOutlined fontSize="small" />
                                        }

                                      </div>

                                      <Tooltip
                                        disableFocusListener
                                        disableTouchListener
                                        title={
                                          <p className="">
                                            {description}
                                          </p>
                                        }
                                      >
                                        <span className='flex-grow font-semibold'>{name}</span>
                                      </Tooltip>


                                    </span>
                                  )

                                })
                              }
                            </div>

                          </div>

                        </div>

                      )
                    }

                    <span className="font-semibold mt-5">
                      {notice[lang]}
                    </span>

                    <div className='flex-grow mb-5'>
                      <div className='grid md:grid-cols-3 gap-3'>

                        {checkedService?.requirements.formInputs.length > 0 && (
                          <div className="mb-3 mt-3">
                            <OtherCollapse name={t('service.modal.form')} items={checkedService.requirements.formInputs} lang={lang} />
                          </div>
                        )}

                        {checkedService?.requirements.uploads.length > 0 && (
                          <div className="mb-3 mt-3">
                            <OtherCollapse name={t('service.modal.uploads')} items={checkedService.requirements.uploads} lang={lang} />
                          </div>
                        )}

                        {checkedService?.requirements.fetches.length > 0 && (
                          <div className="mb-3 mt-3">
                            <OtherCollapse name={t('service.modal.fetches')} items={checkedService.requirements.fetches} lang={lang} />
                          </div>
                        )}
                      </div>

                      {checkedService?.requirements.collections.length > 0 && (
                        <div className="mb-3 mt-3">
                          <span className="font-bold">{t('service.modal.collections')}</span>
                          <div className="grid gap-2 md:grid-cols-2 xl:grid-cols-3 mt-3">

                            {checkedService?.requirements.collections.map((req: any, idx: number) => {
                              return (
                                <Collapse item={req} lang={lang} key={idx} />
                              )
                            })}
                          </div>
                        </div>
                      )}

                    </div>


                    <div className="flex flex-col justify-between text-gray-600 md:flex-row">
                      <div className="flex items-center space-x-3 font-bold">
                        <TimerOutlined fontSize="medium" />
                        <span>
                          {t("service.processing")}: {checkedService?.processTime}
                        </span>
                      </div>

                      <div className="flex items-center space-x-3 font-bold">
                        <LocalOfferOutlined fontSize="medium" />
                        <span>
                          {t("service.price")}: {checkedService?.price}{" "}{checkedService?.currency}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-center items-center my-5">
                      <button
                        type="button"
                        onClick={() => handleApply()}
                        className="flex justify-center w-full py-4 md:w-1/4 font-bold text-white rounded cursor-pointer buttonsColor"
                      >
                        {t("service.apply")}
                      </button>
                    </div>
                  </div>

                </div>

          }

        </div>

      </Fade>
    </Modal>
  )
}

export default OpenServiceModal
