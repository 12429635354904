import { Dispatch } from "redux";
import axios from "utils/AxiosConfig";
import { AxiosRequestConfig, Method } from "axios";
import { setError } from "reduxes";

interface AxiosHandler {
  method: Method;
  url: string;
  data?: any;
  dispatch: Dispatch;
  contentType?: string;
}
export const ActionHandler = async (values: AxiosHandler, response: (values: any) => void, reject: (values: any) => void): Promise<void> => {
  const { method, url, dispatch, data } = values;

  try {

    let headers: any = {
      "x-auth": "367e8e44-1b4d-4f6f-83bc-dd5b8d773407",
      "Content-Type": "application/json",
    }

    if (localStorage.token) {
      headers.Authorization = `Bearer ${localStorage.token}`
    }

    const res = await axios({
      method,
      headers,
      data,
      url
    });

    response(res);
  } catch (error: any) {
    setError({
      isError: true,
      message: 'Something went wrong, please try again later.'
    })(dispatch)
    reject(error)
  }
};

// react query request handler
export const RequestHandler = (props: AxiosRequestConfig) => {
  return axios({ ...props });
}
