import React, { useState, FC } from 'react'
import { CancelOutlined, InfoOutlined, CloudDownloadOutlined } from '@material-ui/icons'
import { Modal } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { CRBReport, OtherReport } from '../../../ServiceApply/Contents/Fetches'

import { ErrorMessage, FieldArray } from 'formik';

type Props = {
    props: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            minHeight: "50vh"
        },
    }),
);

const FetchField: FC<Props> = ({ props }) => {

    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)

    const { name, description, fetchFrom, validation, id } = props["schema"]
    const { schemaName, lang, index, inModal, formik, formValues } = props

    const { setFieldValue } = formik;

    const checkValue = inModal ? formValues[id]["attachments"] : formValues[index]["attachments"]
    const fieldName = inModal ? `${id}["attachments"]` : `${schemaName}[${index}]["attachments"]`

    // const handleCloseModal = () => {
    //     setOpenAlert(false)
    //     setOpenModal(false)
    // }

    const handleRemoveDoc = () => {
        setFieldValue(fieldName, [])
    }

    const message = {
        en: 'added reports',
        fr: 'rapports ajoutés',
        rw: 'raporo zongewemo'
    }

    return (
        <FieldArray
            name={inModal ? `${id}["attachments"]` : `${schemaName}[${index}]["attachments"]`}
            render={({ push, remove, replace }) => {
                return (
                    <>
                        <div className='flex flex-col text-gray-600 text-sm' key={index}>

                            {name[lang] && <label htmlFor={name[lang]}>{name[lang]}
                                {
                                    validation.required.value && <span className="text-red-500">*</span>
                                }
                            </label>}

                            <div className='flex justify-between items-center mt-2 h-10 mb-2'>
                                <div className="flex items-center justify-start h-full w-full rounded-l border px-3 text-gray-600">
                                    <span
                                        className="mr-4 cursor-pointer hidden md:block"
                                    >
                                        <Tooltip disableFocusListener disableTouchListener title={
                                            <p className='text-sm'>{description[lang]}</p>
                                        }>
                                            <InfoOutlined
                                                fontSize="small"
                                            />
                                        </Tooltip>
                                    </span>

                                    <span className="cursor-pointer">
                                        {
                                            `${formValues[inModal ? `${id}` : `${index}`] && formValues[inModal ? `${id}` : `${index}`]["attachments"].length} ${message[lang]}`
                                        }
                                    </span>
                                </div>

                                {
                                    checkValue && checkValue.length === 0
                                        ?
                                        <Tooltip disableFocusListener disableTouchListener title={
                                            <p className="text-sm">{`Fetch the Report`}</p>
                                        }>

                                            <button
                                                type="button"
                                                // disabled={(inModal && countryValue && countryValue.length > 0) ? false : !inModal ? false : true}
                                                className='custom-yellow-bg h-full p-2 rounded-r text-white cursor-pointer border-r'
                                                onClick={() => setOpenModal(true)}
                                            >
                                                <CloudDownloadOutlined />
                                            </button>
                                        </Tooltip>
                                        :
                                        <Tooltip disableFocusListener disableTouchListener title={
                                            <p className="text-sm">{`Remove the Report`}</p>
                                        }>
                                            <span
                                                className="px-5 py-2 text-white cursor-pointer custom-yellow-bg mr-2 rounded-r"
                                                onClick={() => handleRemoveDoc()}
                                            >
                                                <CancelOutlined />
                                            </span>
                                        </Tooltip>
                                }

                            </div>

                            <ErrorMessage name={inModal ? `${id}["attachments"]` : `${schemaName}[${index}]["attachments"]`} render={msg => <div style={{ color: 'red' }}>{msg}</div >} />

                        </div>

                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openModal}
                        >
                            {
                                fetchFrom === "CRB" ?
                                    <CRBReport
                                        fieldName={fieldName}
                                        setFieldValue={{
                                            push,
                                            remove,
                                            replace
                                        }}
                                        fieldValue={checkValue}
                                        openAlert={openAlert}
                                        setOpenAlert={setOpenAlert}
                                        setOpenModal={setOpenModal}
                                    />
                                    :
                                    <OtherReport
                                        lang={lang}
                                        setFieldValue={{
                                            push,
                                            remove,
                                            replace
                                        }}
                                        fieldValue={checkValue}
                                        fieldName={fieldName}
                                        fetchFrom={fetchFrom}
                                        setOpenModal={setOpenModal}
                                    />
                            }

                        </Modal>
                    </>

                )
            }}
        />

    )
}

export default FetchField