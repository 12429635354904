export enum ServiceTypes {
	GetAllDepartmentServices = 'ServiceTypes/GetDepartmentServices',
	GetAllDepartmentServicesTable = 'ServiceTypes/GetDepartmentServicesTable',
	GetAllDepartmentServicesError = 'ServiceTypes/GetDepartmentServicesError',
	Loading = "ServiceTypes/Loading",
	Errors = "ServiceTypes/Errors",
	GetAllService = "ServiceTypes/GetAllService",
	GetAllServiceTable = "ServiceTypes/GetAllServiceTable",
	GetAllServiceError = "ServiceTypes/GetAllServiceError",
	GetService = "ServiceTypes/GetService",
	CheckServiceApply = "ServiceTypes/CheckServiceApply",
	ResetPayload = "ServiceTypes/ResetPayload",
	GetServiceError = "ServiceTypes/GetServiceError",
	SearchService = "ServiceTypes/SearchService",
	SearchServiceError= "ServiceTypes/SearchServiceError",
}

interface GetAllDepartmentServices {
	type: typeof ServiceTypes.GetAllDepartmentServices;
	payload: {
		data: any;
	};
}

interface GetAllDepartmentServicesTable {
	type: typeof ServiceTypes.GetAllDepartmentServicesTable;
	payload: {
		data: any;
	};
}

interface GetAllDepartmentServicesError {
	type: typeof ServiceTypes.GetAllDepartmentServicesError;
	payload: {
		error: any;
	};
}

interface GetAllService {
	type: typeof ServiceTypes.GetAllService,
	payload: {
		data: any
	}
}

interface CheckServiceApply {
	type: typeof ServiceTypes.CheckServiceApply,
	payload: {
		data: any
	}
}

interface GetAllServiceTable {
	type: typeof ServiceTypes.GetAllServiceTable,
	payload: {
		data: any
	}
}

interface GetAllServiceError {
	type: typeof ServiceTypes.GetAllServiceError,
	payload: {
		error: any
	}
}

interface GetService {
	type: typeof ServiceTypes.GetService,
	payload: {
		data: any
	}
}

interface ResetPayload {
	type: typeof ServiceTypes.ResetPayload,
	payload: {
		data: any
	}
}

interface GetServiceError {
	type: typeof ServiceTypes.GetServiceError,
	payload: {
		error: any
	}
}

interface SearchService {
	type: typeof ServiceTypes.SearchService,
	payload: {
		data: any
	}
}

interface SearchServiceError {
	type: typeof ServiceTypes.SearchServiceError,
	payload: {
		error: any
	}
}

interface Loading {
	type: typeof ServiceTypes.Loading;
	payload: {
		loading: boolean;
	};
}

interface Errors {
	type: typeof ServiceTypes.Errors;
	payload: {
	  errors: any;
	};
}

export type ServicesTypeAction =
	| GetAllDepartmentServices
	| GetAllDepartmentServicesError
	| GetAllDepartmentServicesTable
	| GetAllService
	| GetAllServiceError
	| GetAllServiceTable
	| GetService
	| CheckServiceApply
	| ResetPayload
	| GetServiceError
	| Loading
	| Errors
	| SearchService
	| SearchServiceError;
