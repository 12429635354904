import React, { FC, useState, useEffect, ChangeEvent } from 'react'
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select';

type Props = {
    inputType: string,
    props: any
}


const GetField: FC<Props> = ({ inputType, props }) => {
    const { name, description, validation, tags, fetchFrom, id, isMultipleSelection } = props["schema"]
    const { schemaName, lang, index, inModal, formik, formValues } = props

    const { setFieldValue } = formik;

    const [selectedValue, setSelectedValue] = useState(null)

    const handleChange = (obj: any, details: any) => {
        // set the selected value
        setSelectedValue(obj)
        // set the field value from here
        const { name } = details;
        setFieldValue(name, JSON.stringify(obj))
    };

    const handleDecimal = (e: ChangeEvent<HTMLInputElement>, props: any) => {
        const value = e.target.value;
        const newVal = value.replace(/(?!\.)\D/g, "").replace(/(?<=\..*)\./g, "").replace(/(?<=\.\d\d).*/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        e.target.value = newVal
        const { name, setFieldValue } = props;

        setFieldValue(name, `${newVal}`);
    }

    const handleCurrency = (e: ChangeEvent<HTMLSelectElement>, props: any) => {
        const value = e.target.value;
        const { name, setFieldValue } = props;
        setFieldValue(name, `${value}`);
    }

    useEffect(() => {
        const item = inModal ? formValues[id] : formValues[index];

        if (item && item.inputType === "select") {
            const selected = item.inputValue.length > 0 && JSON.parse(item.inputValue);
            setSelectedValue(selected)
        }
    }, [formValues, id, inModal, index])

    return (
        <div className='flex flex-col text-gray-600 text-sm' key={id}>
            {name[lang] && <label htmlFor={name[lang]}>{name[lang]}
                {
                    validation.required.value && <span className="text-red-500">*</span>
                }
            </label>}
            {
                inputType === "select" ?
                    <Select
                        value={selectedValue}
                        isMulti={isMultipleSelection ? true : false}
                        name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                        onChange={(option) => handleChange(
                            option,
                            {
                                name: inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`,
                                isMultiple: fetchFrom === "true" ? true : false
                            }
                        )}
                        options={
                            tags && tags.map((tag: any) => {
                                return {
                                    value: tag.name[lang].toLowerCase(),
                                    label: tag.name[lang]
                                }
                            })
                        }
                        className="w-full h-10 mt-2 text-xs text-gray-500 rounded outline-none cursor-pointer"
                        isSearchable
                    />
                    :
                    inputType === "textarea" ?
                        <Field
                            className="w-full h-10 px-3 mt-2 text-xs text-gray-500 border rounded outline-none"
                            as={"textarea"}
                            name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                            placeholder={description[lang]}
                        />
                        :
                        inputType === "date" ?
                            <Field
                                className="w-full h-10 px-3 mt-2 text-xs text-gray-500 border rounded outline-none"
                                type="date"
                                name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                                placeholder={description[lang]}
                                max={new Date().toISOString().split('T')[0]}
                            />
                            :
                            inputType === "decimal" ?
                                <div className='h-10 mt-2 flex'>
                                    <Field
                                        className="w-full h-10 px-3 text-xs text-gray-500 border rounded-l outline-none"
                                        type="text"
                                        name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleDecimal(e, {
                                            name: inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`,
                                            setFieldValue
                                        })}
                                        placeholder={description[lang]}
                                    />

                                    <select
                                        className='w-20 rounded-r outline-none'
                                        onChange={(e: ChangeEvent<HTMLSelectElement>) => handleCurrency(e, {
                                            name: inModal ? `${id}["fetchFrom"]` : `${schemaName}[${index}]["fetchFrom"]`,
                                            setFieldValue
                                        })}
                                    >
                                        <option value="RWF">RWF</option>
                                        <option value="USD">USD</option>
                                        <option value="EUR">EURO</option>
                                    </select>
                                </div>

                                :
                                <Field
                                    className="w-full h-10 px-3 mt-2 text-xs text-gray-500 border rounded outline-none"
                                    type={inputType}
                                    name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`}
                                    placeholder={description[lang]}
                                />
            }

            <ErrorMessage name={inModal ? `${id}["inputValue"]` : `${schemaName}[${index}]["inputValue"]`} render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
        </div >
    )
}

export default GetField
