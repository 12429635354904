import { GeneralTypeAction, GeneralTypes } from './Types';

const initialState: any = {
    files: []
};

export const generalsReducer = (state = initialState, action: GeneralTypeAction) => {
    switch (action.type) {
        case GeneralTypes.GetGeneralFiles:
            return { ...state, files: action.payload.data, loading: false };

        case GeneralTypes.ResetPayload:
            return { ...action.payload.data };

        case GeneralTypes.Loading:
            return { ...state, loading: true };

        case GeneralTypes.Errors:
            return { ...state, errors: action.payload.errors };
        default:
            return state;
    }
};
