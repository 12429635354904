import './App.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Routings } from 'components/Router';
import { store } from 'reduxes/store';
import { CookiesProvider } from 'react-cookie';
import "./changeLanguage";

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	},
})

const App = () => {
	return (
		<Provider store={store}>
			<CookiesProvider>
				<div className="App">
					<QueryClientProvider client={queryClient}>
						<BrowserRouter basename='/'>
							<Routings />
						</BrowserRouter>
						{process.env.REACT_APP_NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
					</QueryClientProvider>
				</div>
			</CookiesProvider>
		</Provider>
	);
}

export default App;
