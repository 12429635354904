import React, { useState } from "react";
import { useMutation } from 'react-query'
import { useNavigate } from "react-router-dom";
import { EmailOutlined } from "@material-ui/icons";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { forgotPasschema } from './Schema'
import { forgotPasswordAction } from "../../../reduxes/user";
import { SnackMessage, ButtonLoader } from '../../Reusable'
import { ErrorHandler } from "utils";

const ForgotPassword = () => {
  const navigate = useNavigate()

  const [snackMessage, setSnackMessage] = useState<any>(null);

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const { t } = useTranslation();

  const { isLoading,  mutateAsync } = useMutation(async (values: any) => {
    return await forgotPasswordAction(values);
  }, {
    retry: false,
    onError: (error: any) => {
      const { message } = ErrorHandler(error, 'server');
      setOpenSnack(true);
      setSnackMessage({
        error: true,
        message
      })
    },
    onSuccess: (data) => {
      const response = data?.data;
      const message = response?.message;

      setOpenSnack(true);
      setSnackMessage({
        error: false,
        message
      })

      setTimeout(() => navigate("/login"), 2000)
    }
  })


  return (
    <div className="flex justify-center items-center h-full p-2">
      <div className="flex flex-col text-gray-600 w-full md:w-1/2 lg:w-1/2 xl:w-4/12">
        <div className="mb-8">
          <p className="font-medium text-lg">
            {t('forgot.head')}
          </p>
        </div>

        <div className="p-3 bg-white text-gray-500">

          <div className="flex justify-center p-4">
            <EmailOutlined fontSize="large" className="textColor" />
          </div>

          <div className="border-t-2 mb-5"></div>

          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={forgotPasschema}
            onSubmit={async (values) => {
              try {
                await mutateAsync(values);
              } catch (error) {
                return error;
              }
            }}
          >
            {({ values, errors }) => {
              return (
                <Form autoComplete="off">
                  <div className="flex flex-col mb-10">
                    <label className="font-semibold mb-2">
                      {t('forgot.label')}
                    </label>
                    <Field
                      className="w-full h-12 px-3 text-gray-500 bg-white border border-gray-300 rounded outline-none text-base"
                      name="email"
                      placeholder={t('forgot.placeholder')}
                    />
                    <ErrorMessage name="email" render={msg => <div style={{ color: 'red' }}>{msg}</div >} />
                  </div>
                  <div className="mb-5">
                    <div className="grid md:grid-cols-2 gap-3">
                      <button
                        className="flex justify-center p-3 font-semibold text-white rounded shadow buttonsColor cursor-pointer"
                        type="submit"
                      >
                        {isLoading ? (
                          <ButtonLoader />
                        ) : (
                          `${t('forgot.send')}`
                        )}
                      </button>
                      <Link
                        to="/login"
                        className="flex items-center justify-center p-3 text-base font-semibold text-gray-600 border border-gray-400 rounded shadow cursor-pointer"
                      >
                        {t('forgot.cancel')}
                      </Link>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {openSnack && <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />}
    </div>
  );
};

export default ForgotPassword;
