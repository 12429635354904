import React from 'react'
import { Oval } from 'react-loader-spinner';

const AppLoader = () => {
  return (
    <Oval color="#b48523" height={25} width={25} />
  )
}

export default AppLoader;
