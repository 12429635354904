import { Dispatch } from "redux";
import { ServiceTypes } from ".";
import { ApiEndpoints, ActionHandler, ErrorHandler, RequestHandler } from "utils";

export const getAllServicesUnderDepartment = () => (dispatch: Dispatch) => {

  dispatch({ type: ServiceTypes.Loading });

  return ActionHandler(
    { url: ApiEndpoints.getAllDepartmentServices, method: "GET", dispatch },

    (res) => {
      const { data } = res;
      dispatch({ type: ServiceTypes.GetAllDepartmentServices, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server')
      dispatch({ type: ServiceTypes.GetAllDepartmentServicesError, payload: { data } });
    }
  )
};

export const getAllServicesUnderDepartmentTable = (query: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.getAllDepartmentServicesTable}?${query}`,
    method: 'GET'
  })
};

export const getAllService = () => (dispatch: Dispatch) => {
  dispatch({ type: ServiceTypes.Loading });

  return ActionHandler(
    { url: `${ApiEndpoints.getAllServices}`, method: "GET", dispatch },
    (res) => {
      const { data } = res;
      dispatch({ type: ServiceTypes.GetAllService, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server');
      dispatch({ type: ServiceTypes.GetAllServiceError, payload: { data} });
    }
  );
}

export const getAllServiceTable = (query: string) => (dispatch: Dispatch) => {
  dispatch({ type: ServiceTypes.Loading });

  return ActionHandler(
    { url: `${ApiEndpoints.getAllServicesTable}?${query}`, method: "GET", dispatch },
    (res) => {
      const { data } = res;
      dispatch({ type: ServiceTypes.GetAllServiceTable, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server');
      dispatch({ type: ServiceTypes.GetAllServiceTable, payload: { data } });
    }
  );
}

export const getServiceById = (id: string) => {
  return RequestHandler({
    url: `${ApiEndpoints.getService}/${id}`,
    method: 'GET'
  })
}

export const searchServices = (query: string) => (dispatch: Dispatch) => {
  dispatch({ type: ServiceTypes.Loading });

  return ActionHandler(
    { url: `${ApiEndpoints.searchServices}?search=${query}`, method: "GET", dispatch },
    (res) => {
      const { data } = res;
      dispatch({ type: ServiceTypes.SearchService, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server');
      dispatch({ type: ServiceTypes.SearchServiceError, payload: { data } });
    }
  );

}

export const checkServiceApplyAction = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: ServiceTypes.Loading });

  return ActionHandler(
    { url: `${ApiEndpoints.checkServiceApply}/${id}`, method: "GET", dispatch },
    (res) => {
      const { data } = res;
      dispatch({ type: ServiceTypes.CheckServiceApply, payload: { data } });
    },
    (error) => {
      const data = ErrorHandler(error, 'Server');
      dispatch({ type: ServiceTypes.CheckServiceApply, payload: { data } });
    }
  );

}

export const resetStateAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: ServiceTypes.ResetPayload, payload: { data } })
}
