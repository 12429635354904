import { ApiEndpoints, RequestHandler } from "utils";

export const readGeneralFilesAction = () => {
    return RequestHandler({
        url: ApiEndpoints.readGeneralFiles,
        method: 'GET'
    })
};

export const readSingleFileAction = (name: string) => {
    return RequestHandler({
        url: `${ApiEndpoints.readUserGuide}?name=${name}`,
        method: 'GET'
    })
}
