import React, { useState } from "react";
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useCookies } from "react-cookie";
import { initialValues, schema, sendButton, errorMessages, messageTypeOptions } from './schema'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import { SnackMessage, ViewModal, ButtonLoader, UploadFile } from '../../Reusable'

import { Modal, Backdrop, createStyles, makeStyles, Theme } from '@material-ui/core'

import { contactUsAction } from '../../../reduxes/communications'
import { readSingleFileAction } from '../../../reduxes/general'

import { useTranslation } from "react-i18next";
import { ErrorHandler } from "utils/ErrorHandler";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
  }),
);

const Ticket = (props: any) => {

  const { open, handleClose } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const [cookies] = useCookies(["i18next"]);
  const { i18next: lang } = cookies;

  const [openSnack, setOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState<any>(null)

  const [openGuide, setOpenGuide] = useState<{ open: boolean, file: any }>({
    open: false,
    file: null
  });

  const reducer = useSelector((state: any) => {
    return {
      userState: state.users
    }
  });

  const { userState } = reducer

  const handleCloseSnack = () => setOpenSnack(false)
  const handleCloseGuide = () => setOpenGuide({
    open: false,
    file: null
  })

  const contactUsMutation = useMutation((values: any) => {
    return contactUsAction(values);
  }, {
    retry: false,
    onSettled: (_, error) => {
      let message = "Message sent successfully"
      if (error) {
        const { message: res } = ErrorHandler(error, 'server');
        message = res;
      }

      setOpenSnack(true);
      setSnackMessage({
        error: error ? true : false,
        message
      })

      setTimeout(() => {
        handleClose()
      }, 2000)
    }
  })


  useQuery('read user guide', async () => {
    return await readSingleFileAction(openGuide?.file?.name);
  }, {
    retry: false,
    keepPreviousData: false,
    enabled: openGuide?.file?.name ? true : false,
    onSettled: (data, error) => {
      if (error) {
        const { message } = ErrorHandler(error, 'server');
        setOpenSnack(true);
        setSnackMessage({
          error: true,
          message
        })
      }

      const { link } = data?.data?.data;

      setOpenGuide((prev) => {
        return {
          open: true,
          file: {
            name: prev?.file?.name,
            link
          }
        }
      })
    }
  })

  const handleDownloadUserGuide = () => {
    setOpenGuide((prev: any) => {
      return {
        ...prev,
        file: {
          name: 'user guide',
          link: null
        }
      }
    })
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <>
        <Formik
          initialValues={!userState?.user ? initialValues : { ...initialValues, name: userState?.user?.data?.name, email: userState?.user?.data?.email }}
          validationSchema={schema}
          onSubmit={async (values, actions) => {

            try {
              await contactUsMutation?.mutateAsync(values);
            } catch (error) {
              return error;
            }
          }}
        >
          {({ values, setFieldValue, setFieldError, setFieldTouched }) => {
            return (
              <Form className="bg-white w-11/12 xl:w-1/2 font-body text-gray-600 h-4/5 overflow-x-auto flex flex-col p-2">

                <div className="flex justify-between items-center mb-3">
                  <span className="text-lg">{t('ticket.title')}</span>

                  <button
                    type="button"
                    className="flex justify-center items-center buttonsColor rounded text-white  cursor-pointer md:w-1/4 p-2"
                    onClick={() => handleDownloadUserGuide()}
                  >
                    {
                      t('ticket.download')
                    }

                  </button>
                </div>

                <div className="grid md:grid-cols-2 gap-2">
                  <div className="flex flex-col mb-3">
                    <label htmlFor="">{t('ticket.fields.name.label')}</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder={t('ticket.fields.name.placeholder')}
                      className="w-full h-10 px-3 mt-2 text-sm text-gray-500 border rounded outline-none"
                    />
                    <ErrorMessage name="name" render={msg => <div style={{ color: 'red' }} > {errorMessages["to"]["en"]}</div >} />
                  </div>

                  <div className="flex flex-col mb-3">
                    <label htmlFor="">{t('ticket.fields.email.label')}</label>
                    <Field
                      name="email"
                      type="text"
                      placeholder={t('ticket.fields.email.placeholder')}
                      className="w-full h-10 px-3 mt-2 text-sm text-gray-500 border rounded outline-none"
                    />
                    <ErrorMessage name="email" render={msg => <div style={{ color: 'red' }} > {errorMessages["email"]["en"]}</div >} />
                  </div>
                </div>


                <div className="flex flex-col mb-3">
                  <label htmlFor="">{t('ticket.fields.feedback.label')}</label>
                  <Field
                    name="type"
                    as="select"
                    className="w-full h-10 px-3 mt-2 text-sm text-gray-500 border rounded outline-none"
                  >
                    <option value="" disabled>Choose the feedback type</option>
                    {
                      messageTypeOptions.map((type, ind) => {
                        return (
                          <option value={type["name"]["en"].toLowerCase()} key={ind}>{type["name"]["en"]}</option>
                        )
                      })
                    }
                  </Field>
                  <ErrorMessage name="type" render={msg => <div style={{ color: 'red' }} > {errorMessages["messageType"]["en"]}</div >} />
                </div>

                <div className="mb-3 flex xl:justify-end">
                  <UploadFile
                    props={{
                      setFieldError,
                      setFieldValue,
                      setFieldTouched,
                      values: values["attachments"],
                      lang,
                      fieldName: "attachments",
                    }}
                  />
                </div>

                <div className="flex flex-col mb-3 flex-grow">
                  <label htmlFor="">{t('ticket.fields.message.label')}</label>
                  <Field
                    name="message"
                    as="textarea"
                    rows="10"
                    placeholder={t('ticket.fields.message.placeholder')}
                    className="w-full px-3 py-2 mt-2 text-sm text-gray-500 border rounded outline-none h-full"
                  />
                  <ErrorMessage name="message" render={msg => <div style={{ color: 'red' }} >{errorMessages["message"]["en"]}</div >} />
                </div>

                <div className="flex justify-between items-center">

                  <button
                    type="submit"
                    className="border border-gray-600 text-gray-600 px-8 py-2 rounded flex justify-center items-center"
                    onClick={handleClose}
                  >
                    {t('ticket.buttons.cancel')}
                  </button>

                  <button type="submit" className="buttonsColor px-8 py-2 text-white rounded flex justify-center items-center">
                    {
                      contactUsMutation?.isLoading ?
                        <ButtonLoader />
                        :
                        sendButton["en"]
                    }
                  </button>


                </div>

                <SnackMessage open={openSnack} handleCloseSnack={handleCloseSnack} successMessage={snackMessage} />

                {openGuide?.open && <ViewModal handleClose={handleCloseGuide} open={openGuide} />}

              </Form>
            )
          }}
        </Formik>
      </>

    </Modal>

  )
}

export default Ticket
